import { Box } from "@atoms/Flex";
import { useTheme } from "styled-components/native";
import { Text, View } from "react-native";

type ProgressBarProps = {
    percentage: number;
    height?: number;
    color?: string;
    backgroundColor?: string;
    borderRadius?: number;
    labelLeft?: string;
    labelRight?: string;
};

export const ProgressBar = ({
    percentage,
    height = 8,
    color,
    backgroundColor,
    borderRadius = 4,
    labelLeft,
    labelRight,
}: ProgressBarProps) => {
    const theme = useTheme();
    const fillColor = color || theme.color.orange[500];
    const bgColor = backgroundColor || theme.color.grey[700];

    // Limit percentage to 0-100 range
    const safePercentage = Math.min(100, Math.max(0, percentage));

    return (
        <Box>
            <Box
                style={{
                    height: height,
                    width: "100%",
                    backgroundColor: bgColor,
                    borderRadius: borderRadius,
                    overflow: "hidden",
                    position: "relative",
                }}
            >
                <Box
                    style={{
                        height: height,
                        width: `${safePercentage}%`,
                        backgroundColor: fillColor,
                        borderRadius: borderRadius,
                        position: "absolute",
                        left: 0,
                        top: 0,
                    }}
                />
            </Box>

            {(labelLeft || labelRight) && (
                <Box
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 4,
                    }}
                >
                    <View style={{ flex: 1 }}>
                        {labelLeft && (
                            <Text
                                style={{
                                    color: theme.color.grey[400],
                                    fontSize: 12,
                                }}
                            >
                                {labelLeft}
                            </Text>
                        )}
                    </View>
                    <View style={{ flex: 1, alignItems: "flex-end" }}>
                        {labelRight && (
                            <Text
                                style={{
                                    color: theme.color.grey[400],
                                    fontSize: 12,
                                }}
                            >
                                {labelRight}
                            </Text>
                        )}
                    </View>
                </Box>
            )}
        </Box>
    );
};

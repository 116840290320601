import { Box, Flex } from "@atoms/Flex";
import { Image } from "@atoms/Image";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { OstiumFaucet__factory } from "@contexts/faucet/OstiumFaucet__factory";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useNotificationContext } from "@contexts/notification";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { useWeb3Context } from "@contexts/web3";
import { Button } from "@molecules/Button";

import { formatDate, getPairLogo } from "@utils";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Linking } from "react-native";
import { useTheme } from "styled-components/native";
import { parseWeb3Error } from "utils";
import { encodeFunctionData } from "viem";

const Modal = dynamic(() => import("@organisms/Modal"));

export const FaucetButton = ({ isSmall = true }) => {
    const theme = useTheme();
    const { notify } = useNotificationContext();

    const { faucetContract, provider, currentChain } = useWeb3Context();
    const { sendSmartWalletOrder } = usePrivySmartWalletContext();
    const { isOneClickTradingEnabled } = useAppContext();
    const { address } = useOstiumAccount();
    const [isLoading, setIsLoading] = useState(false);

    const [nextRequestTime, setNextRequestTime] = useState(
        new Date().getTime()
    );

    const [isFaucetOpen, setIsFaucetOpen] = useState(false);

    const toggleFaucet = () => {
        setIsFaucetOpen((prev) => !prev);
    };

    const openEthereumFaucet = () => {
        Linking.openURL("https://www.alchemy.com/faucets/arbitrum-sepolia");
    };

    const getRequestTime = useCallback(async () => {
        try {
            const result = await faucetContract.nextRequestTime(
                address as string
            );
            setNextRequestTime(Number(result));
        } catch (err) {
            console.warn(err);
        }
    }, [address, faucetContract]);

    useEffect(() => {
        if (faucetContract && address) {
            getRequestTime();
        }
    }, [address, faucetContract, getRequestTime]);

    useEffect(() => {
        if (isFaucetOpen) {
            getRequestTime();
        }
    }, [isFaucetOpen, getRequestTime]);

    const onSend = async () => {
        try {
            setIsLoading(true);
            const tx = isOneClickTradingEnabled
                ? await sendSmartWalletOrder([
                      {
                          to: currentChain.contracts.faucet,
                          data: encodeFunctionData({
                              abi: OstiumFaucet__factory.abi,
                              functionName: "requestTokens",
                              args: [],
                          }),
                      },
                  ])
                : await faucetContract.requestTokens();
            // @ts-ignore
            await provider.waitForTransaction(tx.hash, 1);

            //@ts-ignore
            if (tx && tx?.isError) {
                notify({
                    //@ts-ignore
                    title: tx?.title,
                    //@ts-ignore
                    description: tx?.description,
                });
                getRequestTime();
                setIsLoading(false);
                return;
            }

            notify({
                title: "Tokens sent",
                description: "You should receive your tokens soon",
            });
            await getRequestTime();
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            setIsLoading(false);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error?.code}\n${error?.message}`,
            });
        }
    };

    const canRequest = useMemo(() => {
        return (
            nextRequestTime < Math.trunc(new Date().getTime() / 1000) ||
            nextRequestTime === 0
        );
    }, [nextRequestTime]);

    return (
        <>
            <Button
                primary
                small={isSmall}
                tiny={!isSmall}
                stroke
                noBorder
                onPress={toggleFaucet}
                text={"Faucet"}
                style={{ paddingHorizontal: 10 }}
            />

            <Modal
                title="Faucets"
                isVisible={isFaucetOpen}
                onClose={toggleFaucet}
                style={{ minWidth: 400 }}
            >
                <Box
                    style={{
                        padding: theme.spacing.bigger,
                        paddingTop: 0,
                    }}
                    gap={theme.spacing.bigger}
                >
                    <Flex
                        align="center"
                        gap={theme.spacing.big}
                        style={{
                            borderColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                            paddingBottom: theme.spacing.bigger,
                            borderBottomWidth: 1,
                        }}
                    >
                        <Flex
                            flex={1}
                            align="center"
                            gap={theme.spacing.bigger}
                        >
                            <Image
                                lazy
                                source={getPairLogo("USDC")}
                                alt="USDC logo"
                                width={32}
                                height={32}
                            />
                            <Box gap={theme.spacing.smaller} flex={1}>
                                <Text semiBold>Ostium USDC Faucet</Text>
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                    lineHeight={theme.text.big}
                                >
                                    Get more free Ostium USDC to use on the
                                    testnet.
                                </Text>
                            </Box>
                        </Flex>

                        <Button
                            primary={canRequest}
                            stroke={!canRequest}
                            noBorder={!canRequest}
                            textProps={{
                                textAlign: "center",
                            }}
                            text={
                                canRequest
                                    ? "Send me USDC"
                                    : `Try again on\n${formatDate(
                                          new Date(nextRequestTime * 1000),
                                          {
                                              month: "numeric",
                                              day: "numeric",
                                          }
                                      )} at ${formatDate(
                                          new Date(nextRequestTime * 1000),
                                          {
                                              hour: "numeric",
                                              minute: "numeric",
                                          }
                                      )}`
                            }
                            style={{
                                width: 110,
                                padding: 0,
                            }}
                            loading={isLoading}
                            disabled={!canRequest}
                            onPress={onSend}
                        />
                    </Flex>
                    <Flex align="center" gap={theme.spacing.big}>
                        <Flex
                            flex={1}
                            align="center"
                            gap={theme.spacing.bigger}
                        >
                            <Image
                                lazy
                                source={getPairLogo("ETH", "USD")}
                                alt="ETH logo"
                                width={32}
                                height={32}
                            />
                            <Box gap={theme.spacing.smaller} flex={1}>
                                <Text semiBold>Ethereum Faucet</Text>
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                    lineHeight={theme.text.big}
                                >
                                    Need more ETH? Get Arbitrum Sepolia ETH to
                                    pay for gas fees.
                                </Text>
                            </Box>
                        </Flex>

                        <Button
                            text="Take me there"
                            style={{
                                width: 110,
                                padding: 0,
                            }}
                            primary
                            onPress={openEthereumFaucet}
                        />
                    </Flex>
                </Box>
            </Modal>
        </>
    );
};

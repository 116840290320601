export const clearIndexedDB = () => {
    if (!window.indexedDB) {
        console.log("IndexedDB is not supported in this browser.");
        return;
    }

    indexedDB.databases().then((dbs) => {
        dbs.forEach((db) => {
            indexedDB.deleteDatabase(db.name || '');
            console.log(`Deleted IndexedDB: ${db.name}`);
        });
    });
};

export const clearCacheStorage = async () => {
    if ('caches' in window) {
        const keys = await caches.keys();
        keys.forEach(async (key) => {
            await caches.delete(key);
            console.log(`Deleted cache: ${key}`);
        });
    }
};

export const clearLocalStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    console.log("Local and session storage cleared.");
};

export const unregisterServiceWorkers = async () => {
    if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        registrations.forEach((registration) => {
            registration.unregister();
            console.log("Service worker unregistered.");
        });
    }
};

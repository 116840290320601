import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { useMedia } from "@hooks/useMedia";
import { ReactNode, memo, useCallback, useEffect, useState } from "react";
import { Pressable, ViewStyle } from "react-native";
import Animated, {
    interpolateColor,
    useAnimatedStyle,
    useDerivedValue,
    withTiming,
} from "react-native-reanimated";
import { Portal } from "@atoms/Portal";
import { useTheme } from "styled-components/native";
import { Box, Flex } from "../../atoms/Flex";
import { LinearGradient } from "expo-linear-gradient";
import { decrementModalCount, incrementModalCount } from "./modalStack";

type Props = {
    isVisible: boolean;
    title?: string;
    description?: string;
    contentStyle?: ViewStyle;
    style?: ViewStyle;
    children?: ReactNode | null;
    renderTitle?: ReactNode;
    renderTop?: ReactNode;
    renderSidebar?: ReactNode;
    onClose?: (isVisible: boolean) => void;
    strict?: boolean; // If true, the modal will not close on overlay click
    useGradient?: boolean; // New prop to control the gradient
    hasBorder?: boolean;
    hasCustomHeader?: boolean;
    renderCustomHeader?: ReactNode;
    maxWidth?: number;
    subTitle?: string;
    maxHeight?: number;
};

const ModalContent = ({
    renderSidebar,
    renderTitle,
    title,
    onClose,
    onClosePress,
    renderTop,
    contentStyle,
    children,
    theme,
    hasBorder,
    hasCustomHeader = false,
    renderCustomHeader,
    subTitle = undefined,
    maxHeight,
}: {
    renderSidebar?: ReactNode;
    renderTitle?: ReactNode;
    title?: string;
    onClose?: (isVisible: boolean) => void;
    onClosePress: () => void;
    renderTop?: ReactNode;
    contentStyle?: ViewStyle;
    children?: ReactNode;
    theme: any; // Adjust the type as per your theme definition
    hasBorder?: boolean;
    hasCustomHeader?: boolean;
    renderCustomHeader?: ReactNode;
    subTitle?: string;
    maxHeight?: number;
}) => (
    <Flex
        style={{
            maxHeight: maxHeight || "auto",
            overflowY: maxHeight ? "scroll" : "auto",
        }}
    >
        <Box>{renderSidebar}</Box>
        <Box flex={1}>
            {hasCustomHeader ? (
                renderCustomHeader
            ) : (
                <Flex
                    justify="space-between"
                    style={{
                        borderBottomWidth: hasBorder ? 1 : 0,
                        borderBottomColor: theme.color.rgba(
                            theme.color.white,
                            0.1
                        ),
                    }}
                >
                    <Box
                        justify="center"
                        gap={theme.spacing.smallest}
                        flex={1}
                        style={{
                            paddingLeft: theme.spacing.bigger,
                            minHeight: 48,
                        }}
                    >
                        {renderTitle || (
                            <Text white bold bigger>
                                {title}
                            </Text>
                        )}
                        {subTitle ? (
                            <Text color={"rgba(208, 219, 218, 0.70)"} small>
                                {subTitle}
                            </Text>
                        ) : null}
                    </Box>

                    {onClose ? (
                        <Pressable
                            onPress={onClosePress}
                            style={{
                                width: 48,
                                height: 48,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Icon name="close" size={12} />
                            </Box>
                        </Pressable>
                    ) : null}
                </Flex>
            )}
            {renderTop}

            <Box justify="space-between" style={[contentStyle]}>
                {children}
            </Box>
        </Box>
    </Flex>
);

const Modal = memo(
    ({
        isVisible,
        title,
        renderTitle,
        contentStyle,
        children,
        renderTop,
        renderSidebar,
        style,
        strict, // If true, the modal will not close on overlay click
        onClose,
        useGradient,
        hasBorder,
        hasCustomHeader = false,
        renderCustomHeader,
        maxWidth = 440,
        subTitle = undefined,
        maxHeight,
    }: Props) => {
        const theme = useTheme();
        const { isMobile } = useMedia();
        const [modalLevel, setModalLevel] = useState(0);

        useEffect(() => {
            const level = incrementModalCount(); // Get the new modal level
            setModalLevel(level);

            return () => {
                decrementModalCount(); // Cleanup when modal closes
            };
        }, []);

        const baseZIndex = 10000; // Base z-index for the lowest modal
        const computedZIndex = baseZIndex + modalLevel * 100;

        const backgroundAnimation = useDerivedValue(() => {
            return withTiming(isVisible ? 1 : 0, {
                duration: 200,
            });
        }, [isVisible]);

        const overlayStyle = useAnimatedStyle(() => {
            return {
                backgroundColor: interpolateColor(
                    backgroundAnimation.value,
                    [0, 1],
                    [
                        theme.color.rgba(theme.color.black, 0),
                        theme.color.rgba(theme.color.black, strict ? 0.8 : 0.6),
                    ]
                ),
                zIndex: strict ? 9999 : undefined,
                pointerEvents: strict ? "none" : undefined, // Prevent interaction with the overlay
            };
        }, [backgroundAnimation, theme.color.black]);

        const wrapperStyle = useAnimatedStyle(() => {
            const config = {
                duration: 200,
            };

            return {
                transform: [
                    {
                        scale: withTiming(isVisible ? 1 : 0.95, config),
                    },
                ],
                zIndex: strict ? computedZIndex : undefined,
            };
        }, [isVisible, computedZIndex]);

        const onClosePress = useCallback(() => {
            onClose?.(!isVisible);
        }, [isVisible, onClose]);

        const onKeyboardEvent = useCallback(
            (e: KeyboardEvent) => {
                if (e.key === "Escape") {
                    //Do whatever when esc is pressed
                    onClosePress();
                }
            },
            [onClosePress]
        );

        useEffect(() => {
            if (isVisible) {
                document.addEventListener("keydown", onKeyboardEvent, false);
            }
            return () => {
                document.removeEventListener("keydown", onKeyboardEvent, false);
            };
        }, [isVisible, onKeyboardEvent]);

        if (!isVisible) return null;

        return (
            <Portal>
                <Box
                    align="center"
                    justify="center"
                    // @ts-ignore
                    style={{
                        position: "fixed",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        pointerEvents: strict ? "auto" : undefined,
                        zIndex: strict ? computedZIndex : undefined,
                    }}
                >
                    <Pressable
                        onPress={onClosePress}
                        disabled={!onClose}
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            // @ts-ignore
                            backdropFilter: strict ? "blur(4px)" : undefined,
                            zIndex: strict ? 10001 : undefined,
                        }}
                    >
                        <Animated.View
                            style={[
                                {
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                },
                                overlayStyle,
                            ]}
                        />
                    </Pressable>

                    <Box
                        justify="center"
                        style={{
                            width: "100%",
                            maxWidth: maxWidth,
                            height: isMobile ? "100%" : "auto",
                            opacity: 1,
                            zIndex: strict ? 10002 : undefined,
                            ...style,
                        }}
                    >
                        <Animated.View
                            style={[
                                {
                                    overflow: "hidden",
                                    transform: [
                                        {
                                            scale: 1,
                                        },
                                    ],
                                    borderWidth: 2,
                                    borderRadius: theme.radius.medium,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                    backgroundColor: theme.color.background,
                                    zIndex: strict ? 10003 : undefined,
                                },
                                wrapperStyle,
                            ]}
                        >
                            {useGradient ? (
                                <LinearGradient
                                    colors={[
                                        "rgba(208, 219, 218, 0.10)",
                                        "rgba(208, 219, 218, 0.00)",
                                    ]}
                                    style={{ flex: 1 }}
                                    start={{ x: 0, y: 0 }}
                                    end={{ x: 1, y: 1 }}
                                >
                                    <ModalContent
                                        renderSidebar={renderSidebar}
                                        renderTitle={renderTitle}
                                        title={title}
                                        onClose={onClose}
                                        onClosePress={onClosePress}
                                        renderTop={renderTop}
                                        contentStyle={contentStyle}
                                        theme={theme}
                                        hasBorder={hasBorder}
                                        hasCustomHeader={hasCustomHeader}
                                        renderCustomHeader={renderCustomHeader}
                                        subTitle={subTitle}
                                        maxHeight={maxHeight}
                                    >
                                        {children}
                                    </ModalContent>
                                </LinearGradient>
                            ) : (
                                <ModalContent
                                    renderSidebar={renderSidebar}
                                    renderTitle={renderTitle}
                                    title={title}
                                    onClose={onClose}
                                    onClosePress={onClosePress}
                                    renderTop={renderTop}
                                    contentStyle={contentStyle}
                                    theme={theme}
                                    hasBorder={hasBorder}
                                    hasCustomHeader={hasCustomHeader}
                                    renderCustomHeader={renderCustomHeader}
                                    subTitle={subTitle}
                                    maxHeight={maxHeight}
                                >
                                    {children}
                                </ModalContent>
                            )}
                        </Animated.View>
                    </Box>
                </Box>
            </Portal>
        );
    }
);

export default Modal;

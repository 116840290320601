import type { SeasonResponse } from '@screens/Points/types/seasons';

export const OSTIUM_POINTS_API_BASE_URL = "/api/points-proxy";

// Helper function to handle fetch requests with proper error handling
async function fetchFromPointsAPI<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    try {
        // Remove the leading "/api" from the endpoint
        const adjustedEndpoint = endpoint.startsWith('/api/') ? endpoint.substring(5) : endpoint;
        const url = `${OSTIUM_POINTS_API_BASE_URL}/${adjustedEndpoint}`;

        const defaultOptions: RequestInit = {
            headers: {
                "Content-Type": "application/json",
            },
            ...options
        };

        const response = await fetch(url, defaultOptions);

        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Error fetching from Points API: ${endpoint}`, error);
        throw error;
    }
}

// Season-related services
export const SeasonsService = {
    getCurrentSeason: async <T>(): Promise<T> => {
        return fetchFromPointsAPI<T>('/seasons/current');
    },

    getSeasonById: async <T>(id: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/seasons/${id}`);
    },

    getSeasonMetrics: async () => {
        return fetchFromPointsAPI('/seasons/metrics');
    },

    getAllSeasons: async <T>(): Promise<T> => {
        return fetchFromPointsAPI<T>('/seasons');
    }
};

// Standings services
export const StandingsService = {
    getCurrentTradingStandings: async <T>(): Promise<T> => {
        return fetchFromPointsAPI<T>('/standings/trading/current');
    },

    getUserTradingStandings: async <T>(address: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/standings/trading/user/${address}`);
    },

    getTradingStandingsBySeasonId: async <T>(seasonId: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/standings/trading/season/${seasonId}`);
    },

    getFilteredTradingStandings: async <T>(
        startTime: number,
        endTime: number,
        limit = 50
    ): Promise<T> => {
        return fetchFromPointsAPI<T>(
            `/standings/trading/filter?startTime=${startTime}&endTime=${endTime}&limit=${limit}`
        );
    },

    getCurrentLPStandings: async <T>(): Promise<T> => {
        return fetchFromPointsAPI<T>('/standings/lp/current');
    },

    getUserLPStandings: async <T>(address: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/standings/lp/user/${address}`);
    },

    getLPStandingsBySeasonId: async <T>(seasonId: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/standings/lp/season/${seasonId}`);
    }
};

// Points and leaderboard services
export const PointsService = {
    getPointsBySeasonId: async <T>(seasonId: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/points/season/${seasonId}`);
    },

    getPointsByUserId: async <T>(userId: string): Promise<T> => {
        return fetchFromPointsAPI<T>(`/points/user/${userId}`);
    },

    getLeaderboard: async <T>(
        seasonId: string,
        page = 0,
        limit = 50
    ): Promise<T> => {
        return fetchFromPointsAPI<T>(
            `/points/leaderboard/${seasonId}?page=${page}&limit=${limit}`
        );
    }
};
import { isWeb } from "@constants";
import "react-native-match-media-polyfill";
import { useMediaQuery } from "react-responsive";

export const useMedia = () => {
    const isDesktopOrLaptop = useMediaQuery({
        maxWidth: 1224,
    });
    const isDesktopSmall = useMediaQuery({
        maxWidth: 1120,
    });
    const isBigScreen = useMediaQuery({
        minWidth: 1400,
    });
    const isBiggestScreen = useMediaQuery({
        minWidth: 1680,
    });
    const isMobile = useMediaQuery({
        maxWidth: 767,
    });
    const isMobileWeb = isWeb && isMobile;
    const isMobileTiny = useMediaQuery({
        maxWidth: 320,
    });
    const isMobileLarge = useMediaQuery({
        maxWidth: 576,
    });
    const isMobileSmallest = useMediaQuery({
        maxWidth: 348,
    });
    const isTablet = useMediaQuery({
        minWidth: 768,
        maxWidth: 991,
    });
    const isTabletOrMobile = useMediaQuery({
        maxWidth: 991,
    });
    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
    // Approximate 13-inch MacBook
    const isMacBook13 = useMediaQuery({
        minWidth: 1280,
        maxWidth: 1440,
    });

    const isIPadPro = useMediaQuery({
        minDeviceWidth: 1024, // iPad Pro models have at least 1024px width in portrait mode
        maxDeviceWidth: 1366, // iPad Pro 12.9" has a max width of 1366px in portrait mode
        minDeviceHeight: 1366, // iPad Pro 12.9" has a height of 1366px in landscape mode
        maxDeviceHeight: 2048, // iPad Pro 12.9" has a max height of 2048px in portrait mode
        query: "(min-resolution: 2dppx)", // Ensure it's a Retina display
    });

    // Approximate 16-inch MacBook
    const isMacBook16 = useMediaQuery({
        minWidth: 1680,
        maxWidth: 1920,
    });

    return {
        isDesktopSmall,
        isDesktopOrLaptop,
        isBigScreen,
        isBiggestScreen,
        isMobile,
        isMobileTiny,
        isMobileSmallest,
        isMobileLarge,
        isTablet,
        isTabletOrMobile,
        isTabletOrMobileDevice,
        isPortrait,
        isRetina,
        isMobileWeb,
        isMacBook13,
        isMacBook16,
        isIPadPro,
    };
};

import { getFundingRateFormatted, getOI } from '@screens/Trade/utils';
import { Pair } from "gql/graphql";
import { useMemo } from "react";
import { formatUnits } from "viem";

export function getFundingRateLatest(pair: Pair, blockNumber: bigint) {
    if (pair && blockNumber) {
        const { latest } = getFundingRateFormatted(pair, blockNumber);

        if (latest > 0) {
            const shortOI = Number(formatUnits(pair.shortOI, 18));
            return {
                long: -latest,
                short:
                    shortOI > 0
                        ? (latest * Number(formatUnits(pair.longOI, 18))) /
                        shortOI
                        : 0,
            };
        } else {
            const longOI = Number(formatUnits(pair.longOI, 18));
            return {
                short: latest,
                long:
                    longOI > 0
                        ? (-latest * Number(formatUnits(pair.shortOI, 18))) /
                        longOI
                        : 0,
            };
        }
    }
    return { long: 0, short: 0 };
}


export function usePairStatistics({
    pair,
    price,
    blockNumber,
}: {
    pair?: Pair;
    price: number | null;
    blockNumber?: bigint;
}) {
    const oi = useMemo(() => {
        if (pair && price) return getOI(pair, price);
        return {
            long: {
                current: 0,
                max: 0,
            },
            short: {
                current: 0,
                max: 0,
            },
        };
    }, [pair, price]);


    const rollover = useMemo(() => {
        if (pair?.rolloverFeePerBlock)
            return Number(formatUnits(pair?.rolloverFeePerBlock, 18));
        return 0;
    }, [pair?.rolloverFeePerBlock]);

    const fundingRate = useMemo(() => {
        if (pair && blockNumber) {
            return getFundingRateLatest(pair, blockNumber);
        }
        return { long: 0, short: 0 };
    }, [pair, blockNumber]);

    return {
        oi,
        rollover,
        fundingRate,
    };
}
import { Box } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components/native";
import { type ITab } from "@screens/Trade/components/Form/TabBar";
import { useRouter } from "next/router";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { ReferralCode } from "./components/ReferralCode";
import { ReferralStats } from "./components/ReferralStats";
import { ReferralTabs } from "./components/ReferralTabs";

export const Referrals = ({ onClose }: any) => {
    const theme = useTheme();
    const { userFE, refetchUser } = useAppContext();
    const { address } = useOstiumAccount();
    const { query } = useRouter();
    const { ref } = query;

    useEffect(() => {
        if (address) {
            refetchUser();
        }
    }, [address, refetchUser]);

    const accessCode = useMemo(() => {
        return userFE?.accessCode || "";
    }, [userFE]);

    const [referralTab, setReferralTab] = useState<ITab>({
        text: "Referral Code",
        id: "referral-code",
    });

    const referralTabs = [
        {
            text: "Referral Code",
            id: "referral-code",
        },
        {
            text: "Referral Stats",
            id: "referral-stats",
        },
    ];

    const [code, setCode] = useState(
        accessCode.split("") || ["", "", "", "", ""]
    );

    useEffect(() => {
        if (ref && ref.length) {
            //@ts-ignore
            const firstFourChars = ref.slice(0, 5).toUpperCase().split(""); /// Split the first 4 characters

            // Set the first 4 characters in the state, and keep the rest as empty strings
            setCode((prevCode) => [
                ...firstFourChars,
                ...prevCode.slice(firstFourChars.length),
            ]);
        }
    }, [ref]);


    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.bigger}
        >
            {/* MAIN TABS - New Design */}
            <ReferralTabs
                tabs={referralTabs}
                activeTab={referralTab}
                setActiveTab={setReferralTab}
            />

            {/* REFERRAL CODE TAB */}
            {referralTab.id === "referral-code" && (
                <ReferralCode onClose={onClose} />
            )}

            {/* REFERRAL STATS TAB */}
            {referralTab.id === "referral-stats" && (
                <ReferralStats onClose={onClose} />
            )}
        </Box>
    );
};

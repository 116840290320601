import { Flex } from "@atoms/Flex";
import { Box } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Button } from "@molecules/Button";
import { useTheme } from "styled-components/native";
import { useState } from "react";
import { ProgressDisplay } from "./ProgressDisplay";
import { AccessCodeInput } from "./AccessCodeInput";
import { useUserTradingReferralData } from "@screens/Points/hooks/useUserData";
import { useWeekProgress } from "hooks/useWeekProgress";
import { useCurrentSeason } from "@screens/Points/hooks/usePoints";
import { useReferralHelpers } from "../hooks/useReferralHelpers";
import { openTwitter, openTelegram } from "../utils/helpers";
import { Pressable } from "react-native";
import { ITab } from "./ReferralTabs";
import { TabBarItem } from "@screens/Trade/components/Form/TabBar";
import { Icon } from "@atoms/Icon";
import { useNavigation } from "@react-navigation/native";
import { useRouting } from "expo-next-react-navigation";

export const ReferralCode = ({ onClose }: { onClose: () => void }) => {
    const theme = useTheme();
    const { navigate } = useRouting();
    const { userTradingData } = useUserTradingReferralData();
    const { season } = useCurrentSeason();
    const { weekProgressPercentage, startDate, endDate } = useWeekProgress(
        season?.startTime,
        season?.endTime
    );
    const weeklyScore = userTradingData?.details?.referralBonus || 0;

    const { refCode, referralUrl, onCopy, inputRef } = useReferralHelpers();

    const [tab, setTab] = useState<ITab>({
        text: "Your code",
        id: "your-code",
    });

    const tabs = [
        {
            text: "Your code",
            id: "your-code",
        },
        {
            text: "Enter code",
            id: "enter-code",
        },
    ];

    const handleNavigateToPoints = () => {
        navigate({
            routeName: "/points",
        });
    };

    return (
        <Flex gap={theme.spacing.big} direction="column">
            <Text
                small
                color={theme.color.white}
                lineHeight={theme.spacing.biggest}
            >
                Your trading score will increase the more you refer friends who
                trade. For more information, visit the{" "}
                <Text
                    small
                    onPress={handleNavigateToPoints}
                    style={{
                        textDecorationLine: "underline",
                        lineHeight: theme.spacing.biggest,
                    }}
                >
                    Points page
                </Text>
                .
            </Text>

            <ProgressDisplay
                weeklyScore={Number(weeklyScore)}
                weekProgressPercentage={weekProgressPercentage}
                startDate={startDate}
                endDate={endDate}
                weekNumber={season?.name || ""}
            />

            <Flex
                gap={theme.spacing.medium}
                direction="column"
                style={{
                    backgroundColor: theme.color.rgba(theme.color.white, 0.05),
                    borderRadius: theme.spacing.smallest,
                    padding: theme.spacing.bigger,
                }}
            >
                {/* SECONDARY TABS - Your code/Enter code */}
                <Flex gap={theme.spacing.smallest} align="center">
                    {tabs.map((item, index) => {
                        const isFirst = index === 0;
                        const isLast = index === tabs.length - 1;

                        return (
                            <TabBarItem
                                key={`referrals-tab-bar-item-${item.id}-${index}`}
                                item={item}
                                isFirst={isFirst}
                                isLast={isLast}
                                onPress={() => setTab(item)}
                                borderRadius={theme.spacing.tiny}
                                style={{
                                    borderRadius: theme.spacing.tiny,
                                    padding: theme.spacing.small,
                                    backgroundColor:
                                        tab.id === item.id
                                            ? theme.color.rgba(
                                                  theme.color.white,
                                                  0.2
                                              )
                                            : "transparent",
                                }}
                                textStyle={{
                                    color:
                                        tab.id === item.id
                                            ? theme.color.white
                                            : theme.color.rgba(
                                                  theme.color.white,
                                                  0.4
                                              ),
                                    fontSize: 11,
                                    fontWeight: "normal",
                                }}
                            />
                        );
                    })}
                </Flex>

                {/* YOUR CODE - REFERRAL URL */}
                {tab?.id === "your-code" && (
                    <Flex
                        align="center"
                        gap={theme.spacing.small}
                        justify="space-between"
                        style={{
                            borderRadius: theme.spacing.tiny,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                            padding: theme.spacing.medium,
                        }}
                    >
                        <Text
                            color={theme.color.rgba(theme.color.white, 0.8)}
                            smaller
                        >
                            {"https://"}
                            <Text
                                ref={inputRef}
                                color={theme.color.rgba(theme.color.white, 0.8)}
                                smaller
                            >
                                {refCode
                                    ? `ostium.app/trade?ref=${refCode}`
                                    : `ostium.app/trade`}
                            </Text>
                        </Text>

                        <Flex align="center" gap={theme.spacing.smaller}>
                            <Pressable
                                onPress={() =>
                                    openTwitter(
                                        referralUrl ||
                                            "https://ostium.app/trade"
                                    )
                                }
                            >
                                <Icon
                                    name="twitter_x"
                                    size={16}
                                    color={theme.color.white}
                                />
                            </Pressable>
                            <Pressable
                                onPress={() =>
                                    openTelegram(
                                        referralUrl ||
                                            "https://ostium.app/trade"
                                    )
                                }
                            >
                                <Icon
                                    name="telegram"
                                    size={20}
                                    color={theme.color.white}
                                />
                            </Pressable>
                            <Pressable onPress={onCopy}>
                                <Icon
                                    name="copy"
                                    size={16}
                                    color={theme.color.white}
                                />
                            </Pressable>
                        </Flex>
                    </Flex>
                )}

                {/* ENTER CODE - ACCESS CODE */}
                {tab?.id === "enter-code" && <AccessCodeInput />}
            </Flex>

            <Flex gap={theme.spacing.medium} justify="end">
                <Button text="Close" primary={false} onPress={onClose} />
                <Button text="Copy Link" primary onPress={onCopy} />
            </Flex>
        </Flex>
    );
};

import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Icon } from "@atoms/Icon";
import { ProgressBar } from "@screens/Points/components/ProgressBar";
import { useTheme } from "styled-components/native";
import { Tooltip } from "@molecules/Tooltip";

interface ProgressDisplayProps {
    weeklyScore: number;
    weekProgressPercentage: number;
    startDate: string;
    endDate: string;
    weekNumber: string;
}

export const ProgressDisplay = ({
    weeklyScore,
    weekProgressPercentage,
    startDate,
    endDate,
    weekNumber,
}: ProgressDisplayProps) => {
    const theme = useTheme();

    return (
        <Flex
            direction="column"
            gap={0}
            style={{
                backgroundColor: theme.color.rgba(theme.color.primary, 0.3),
                borderRadius: theme.spacing.smaller,
            }}
        >
            {/* Weekly Referral Score Banner */}
            <Box
                style={{
                    borderRadius: theme.spacing.smaller,
                    padding: theme.spacing.small,
                    marginBottom: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <Text color={theme.color.white} smaller>
                    Your past referral score has been converted to points!
                </Text>
            </Box>

            {/* Referral Score with Week */}
            <Box
                style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: theme.spacing.smaller,
                    borderColor: theme.color.rgba(theme.color.white, 0.05),
                    borderWidth: 1,
                    padding: theme.spacing.medium,
                }}
            >
                <Flex direction="row" justify="space-between" align="center">
                    <Tooltip
                        title="Ostium's Points and Referral Program"
                        content="You can earn scores in real time as you trade, refer friends, and maintain a balance of deposited funds in Ostium's vault!"
                        position="top-center"
                    >
                        <Flex
                            direction="row"
                            align="center"
                            gap={theme.spacing.tiny}
                        >
                            <Text
                                color={theme.color.rgba(theme.color.white, 0.7)}
                                smaller
                            >
                                Referral Score ({weekNumber})
                            </Text>
                            <Icon
                                name="info"
                                color={theme.color.rgba(theme.color.white, 0.5)}
                                size={14}
                            />
                        </Flex>
                    </Tooltip>
                </Flex>

                <Text
                    color={theme.color.white}
                    style={{
                        fontSize: 28,
                        fontWeight: "bold",
                        marginVertical: theme.spacing.small,
                    }}
                >
                    {weeklyScore.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </Text>

                {/* Progress Bar for week */}
                <ProgressBar
                    percentage={weekProgressPercentage}
                    height={6}
                    color={theme.color.primary}
                    backgroundColor={theme.color.rgba(theme.color.white, 0.1)}
                    labelLeft={startDate}
                    labelRight={endDate}
                />
            </Box>
        </Flex>
    );
};

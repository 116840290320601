import { useWeb3Context } from "@contexts/web3";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { Pair, Trade } from "gql/graphql";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useAppContext } from "@contexts/app";

import { gql, useQuery } from "@hooks/useApollo";
import { useSearchParams } from "next/navigation";
import { useFavorites } from "./hooks/useFavorites";
import { IPerformance, usePerformance } from "./usePerformance";
import useAnalytics from "@hooks/useAnalytics";
import { TRADE_EVENT_NAMES } from "constants/events";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";

export const TradeContext = createContext(
    {} as {
        pair?: Pair;
        tradesPerPair: Map<string, number>;
        performances: Map<string, IPerformance> | null;

        isPairSelectOpen: boolean;
        isTradingDisabled: boolean;
        favorites: string[];
        togglePairSelect: (bool?: boolean) => void;
        toggleFavorite: (pair: Pair) => void;
        changePair: (fromto: `${string}-${string}`) => void;
    }
);

function useTrade() {
    const { currentChain } = useWeb3Context();
    const { trackEvent } = useAnalytics();
    const { pairs, initial } = useAppContext();
    const { getKey, saveKey } = useLocalStorage();
    const { address } = useOstiumAccount();
    const { favorites, toggleFavorite } = useFavorites(pairs);
    const { performances } = usePerformance(
        [...pairs.values()],
        currentChain.id,
        []
    );

    const searchParams = useSearchParams();
    const from = searchParams.get("from");
    const to = searchParams.get("to");

    const [pair, setPair] = useState<Pair | undefined>(
        initial.pair ||
            (from &&
                initial.pairs.find(
                    (pair) => pair.from === from && pair.to === to
                )) ||
            initial?.pairs?.[0]
    );
    const [isPairSelectOpen, setIsPairSelectOpen] = useState(false);

    useQuery(GET_PAIR, {
        variables: {
            id: Number(pair?.id),
        },
        skip: !pair,
        pollInterval: 10000,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setPair(data.pair);
        },
    });

    useEffect(() => {
        // Cleanup
        return () => {
            setPair(initial.pair || initial.pairs?.[0]);
        };
    }, [initial.pair, initial.pairs]);

    const { data: tradesData } = useQuery(GET_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
        fetchPolicy: "cache-and-network",
    });

    const tradesPerPair = useMemo(() => {
        const counts = new Map<string, number>(
            [...pairs.values()]?.map((pair) => [pair.id, 0])
        );

        [
            ...(tradesData?.trades ?? []),
            ...(tradesData?.orders ?? []),
            ...(tradesData?.limits ?? []),
        ].forEach((trade: Trade) => {
            if (trade.pair) {
                const count = counts.get(trade.pair.id) || 0;
                counts.set(trade.pair.id, count + 1);
            }
        });

        return counts;
    }, [pairs, tradesData?.limits, tradesData?.orders, tradesData?.trades]);

    useEffect(() => {
        const getStoredPair = async () => {
            try {
                const storedPair = await getKey("TRADE_SELECTED_PAIR");
                if (storedPair) {
                    const foundStoredPair = initial.pairs.find(
                        (pair) =>
                            pair.from === storedPair.from &&
                            pair.to === storedPair.to
                    );
                    if (foundStoredPair) {
                        const currentParams = new URLSearchParams(
                            window.location.search
                        );

                        // Add or update the 'from' and 'to' parameters
                        currentParams.set("from", foundStoredPair.from);
                        currentParams.set("to", foundStoredPair.to);

                        const newurl = `${
                            window.location.pathname
                        }?${currentParams.toString()}`;

                        window.history.replaceState(
                            { path: newurl },
                            "",
                            newurl
                        );
                        setPair(foundStoredPair);
                        return;
                    }
                }

                const defaultPair = initial.pairs.find(
                    (pair) => pair.from === "SPX" && pair.to === "USD"
                );

                if (defaultPair) {
                    const currentParams = new URLSearchParams(
                        window.location.search
                    );

                    // Add or update the 'from' and 'to' parameters
                    currentParams.set("from", defaultPair.from);
                    currentParams.set("to", defaultPair.to);

                    const newurl = `${
                        window.location.pathname
                    }?${currentParams.toString()}`;

                    window.history.replaceState({ path: newurl }, "", newurl);
                    setPair(defaultPair);
                    saveKey("TRADE_SELECTED_PAIR", defaultPair);
                }
            } catch (err) {
                console.warn(err);
            }
        };

        if (initial.pair || from || to) return;
        getStoredPair();
    }, [from, getKey, initial.pair, initial.pairs, pairs, saveKey, to]);

    const togglePairSelect = useCallback((bool?: boolean) => {
        if (bool != null) setIsPairSelectOpen(bool);
        else setIsPairSelectOpen((prev) => !prev);
    }, []);

    const changePair = useCallback(
        async (fromto: `${string}-${string}`) => {
            if (!!!fromto) return;

            const foundPair = pairs.get(fromto);
            if (foundPair) {
                setPair(foundPair);
                saveKey("TRADE_SELECTED_PAIR", foundPair);

                const currentParams = new URLSearchParams(
                    window.location.search
                );

                // Add or update the 'from' and 'to' parameters
                currentParams.set("from", foundPair.from);
                currentParams.set("to", foundPair.to);

                trackEvent(TRADE_EVENT_NAMES.CHANGE_PAIR, {
                    from: foundPair.from,
                    to: foundPair.to,
                    address,
                });

                const newurl = `${
                    window.location.pathname
                }?${currentParams.toString()}`;

                window.history.replaceState({ path: newurl }, "", newurl);
            }
        },
        [pairs, saveKey, address, trackEvent]
    );

    const isTradingDisabled = useMemo(() => {
        return false;
    }, []);

    return {
        pair,
        pairs,
        tradesPerPair,
        isTradingDisabled,
        performances,
        favorites,
        isPairSelectOpen,
        togglePairSelect,
        toggleFavorite,
        changePair,
    };
}

export const TradeProvider = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const value = useTrade();

    return (
        <TradeContext.Provider value={value}>{children}</TradeContext.Provider>
    );
};

export const useTradeContext = () => useContext(TradeContext);

const GET_TRADES = gql`
    query UsersTrades($trader: Bytes, $orderDirection: String)
    @api(name: subgraph) {
        trades(where: { trader: $trader, isOpen: true }) {
            id
            pair {
                id
            }
        }
        orders(
            where: { trader: $trader, isPending: true, isCancelled: false }
        ) {
            id
            pair {
                id
            }
        }
        limits(where: { trader: $trader, isActive: true }) {
            id
            pair {
                id
            }
        }
    }
`;

const GET_PAIR = gql`
    query FormPair($id: ID!) @api(name: subgraph) {
        pair(id: $id) {
            id
            from
            to
            feed
            volume
            accRollover
            accFundingLong
            accFundingShort
            curRollover
            curFundingLong
            curFundingShort
            makerMaxLeverage
            lastFundingRate
            maxFundingFeePerBlock
            lastFundingBlock
            lastFundingTime
            rolloverFeePerBlock
            longOI
            shortOI
            maxOI
            maxLeverage
            group {
                id
                name
                maxLeverage
                minLeverage
                maxCollateralP
                longCollateral
                shortCollateral
            }
            makerFeeP
            takerFeeP
            fee {
                id
                minLevPos
                liqFeeP
                oracleFee
            }
            hillInflectionPoint
            hillPosScale
            hillNegScale
            lastOiDelta
            springFactor
            sFactorUpScaleP
            sFactorDownScaleP
            lastTradePrice
        }
    }
`;

let modalCount = 0;

export const incrementModalCount = () => {
    modalCount += 1;
    return modalCount;
};

export const decrementModalCount = () => {
    modalCount = Math.max(0, modalCount - 1);
    return modalCount;
};

export const getModalCount = () => modalCount;

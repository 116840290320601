import { useMemo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { Image } from "@atoms/Image";
import { encodeFunctionData, erc20Abi, erc721Abi, parseUnits } from "viem";
import { useNotificationContext } from "@contexts/notification";
import { useWeb3Context } from "@contexts/web3";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useBalance } from "@hooks/useBalance";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Skeleton } from "@molecules/Skeleton";
import { Button } from "@molecules/Button";
import { Input } from "@molecules/Input";
import { formatPrice, getPairLogo } from "@utils";
import Modal from "@organisms/Modal";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { useTokenBalance } from "@hooks/useTokenBalance";

export const TransferModal = ({
    data,
    onClose,
    isVisible,
    address,
}: {
    data: {
        address?: string;
        symbol?: string;
        isNFT?: boolean;
        nftId?: number;
        nftName?: string;
        assets?: number;
        shares?: number;
        isOLP?: boolean;
    };
    address: string;
    onClose: Function;
    isVisible: boolean;
}) => {
    const theme = useTheme();
    const { currentChain } = useWeb3Context();
    const [amount, setAmount] = useState<number>(0.0);
    // we can put the wagmi address here as default value: useState<`0x${string}`>(wagmiAddress)
    const [addressToWithdraw, setAddressToWithdraw] = useState<`0x${string}`>(
        "" as `0x${string}`
    );
    const [loading, setLoading] = useState(false);
    const { notify } = useNotificationContext();
    const { sendSmartWalletOrderV1 } = usePrivySmartWalletContext();
    const { wagmiAddress } = useOstiumAccount();

    const handleWithdraw = async () => {
        try {
            if (data?.isNFT && data?.nftId) {
                if (!data?.nftId) {
                    notify({
                        title: "NFT ID is required",
                        description: "Please enter the NFT ID",
                        type: "Error",
                    });
                    return;
                }
            } else {
                if (!balance.formatted) {
                    notify({
                        title: "Insufficient balance",
                        description: "Please enter the amount to transfer",
                        type: "Error",
                    });
                    return;
                }
            }

            setLoading(true);
            const result = await sendSmartWalletOrderV1(
                [
                    {
                        to: data.address,
                        data:
                            data?.isNFT && data?.nftId
                                ? encodeFunctionData({
                                      abi: erc721Abi,
                                      functionName: "transferFrom",
                                      args: [
                                          address as `0x${string}`,
                                          addressToWithdraw as `0x${string}`,
                                          BigInt(data?.nftId?.toString()),
                                      ],
                                  })
                                : encodeFunctionData({
                                      abi: erc20Abi,
                                      functionName: "transfer",
                                      args: [
                                          addressToWithdraw as `0x${string}`,
                                          balance.value,
                                      ],
                                  }),
                    },
                ],
                true,
                amount
            );
            if (result && result?.isError) {
                setLoading(false);
                notify({
                    //@ts-ignore
                    title: result?.title,
                    //@ts-ignore
                    description: result?.description,
                });

                onClose();
                return;
            }

            notify({
                title: "Withdraw successful",
                description:
                    "You have successfully withdrawn funds from your smart account.",
                type: "Success",
            });
            onClose();
        } catch (e: any) {
            setLoading(false);
            console.error({ e });
            notify({
                title: "Withdraw failed",
                description: "Your withdraw request has failed.",
                type: "Error",
            });
            onClose();
        } finally {
            setLoading(false);

            onClose();
        }
    };

    const { balance, isLoading } = useTokenBalance({
        tokenAddress: data.address as `0x${string}`,
        address: address as `0x${string}`,
        watch: true,
    });

    const formatBalance = useMemo(() => {
        if (balance?.formatted)
            return `Available: ${formatPrice(Number(balance?.formatted) || 0, {
                currency: false,
                decimals: 2,
            })}`;
        return "";
    }, [balance?.formatted]);

    const renderBalance = useMemo(() => {
        if (!address) return null;
        return (
            <Skeleton isLoading={!balance.value} width={80}>
                <Pressable
                    onPress={() => {
                        setAmount(Number(balance?.formatted));
                    }}
                >
                    <Text
                        smaller
                        color={theme.color.rgba(theme.color.white, 0.7)}
                    >
                        {formatBalance}
                    </Text>
                </Pressable>
            </Skeleton>
        );
    }, [
        address,
        balance?.value,
        formatBalance,
        theme.color,
        balance?.formatted,
    ]);

    return (
        <Modal
            isVisible={isVisible}
            //@ts-ignore
            onClose={onClose}
            strict={true}
            title={`Transfer ${data?.isNFT ? "NFT" : "Funds"}`}
        >
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    paddingTop: 0,
                }}
                gap={theme.spacing.huge}
            >
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.white, 0.7)}
                    lineHeight={theme.spacing.bigger}
                >
                    Transfer your {data?.isNFT ? "vault NFT" : "funds"} to your
                    primary wallet.
                </Text>
                <Box gap={theme.spacing.tiny}>
                    {data?.isNFT ? (
                        <Flex
                            justify="space-between"
                            align="center"
                            style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                opacity: 1,
                                zIndex: 10,
                                borderWidth: 1,
                                borderRadius: 8,
                                backgroundColor: "rgba(208, 219, 218, 0.05)",
                                color: "white",
                                borderColor: "rgba(208, 219, 218, 0.05)",
                                pointerEvents: "auto",
                                minHeight: 60,
                            }}
                        >
                            <Flex align="center" style={{ gap: 8 }}>
                                <Image
                                    source={"assets/pairs/olp.svg"}
                                    width={16}
                                    height={16}
                                    alt={"USDC"}
                                />
                                <Flex style={{ gap: 2 }} direction="column">
                                    <Text smaller color="#D0DBDA">
                                        {data.nftName}
                                    </Text>
                                    <Text
                                        tiny
                                        color="rgba(208, 219, 218, 0.70)"
                                    >
                                        {formatPrice(data?.assets, {
                                            currency: false,
                                        })}{" "}
                                        USDC /{" "}
                                        {formatPrice(data?.shares, {
                                            currency: false,
                                        })}{" "}
                                        OLP
                                    </Text>
                                </Flex>
                            </Flex>
                            <Text smaller color="rgba(208, 219, 218, 0.40)">
                                Locked
                            </Text>
                        </Flex>
                    ) : (
                        <Flex
                            justify="space-between"
                            align="center"
                            style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                opacity: 1,
                                zIndex: 10,
                                borderWidth: 1,
                                borderRadius: 8,
                                backgroundColor: "rgba(208, 219, 218, 0.05)",
                                color: "white",
                                borderColor: "rgba(208, 219, 218, 0.05)",
                                pointerEvents: "auto",
                                minHeight: 60,
                            }}
                        >
                            <Flex align="center" style={{ gap: 8 }}>
                                <Image
                                    source={`assets/pairs/${
                                        data?.isOLP ? "olp" : "usdc"
                                    }.svg`}
                                    width={18}
                                    height={18}
                                    alt={"USDC"}
                                />
                                <Flex style={{ gap: 2 }} direction="column">
                                    <Text smaller color="#D0DBDA">
                                        {data?.isOLP ? "OLP" : "USDC"}
                                    </Text>
                                    {/* <Text
                                        tiny
                                        color="rgba(208, 219, 218, 0.70)"
                                    >
                                        {formatPrice(balance.formatted, {
                                            currency: false,
                                        })}
                                        USDC
                                    </Text> */}
                                </Flex>
                            </Flex>
                            <Text smaller color="rgba(208, 219, 218, 0.90)">
                                {isLoading
                                    ? "..."
                                    : formatPrice(balance.formatted, {
                                          currency: false,
                                      })}{" "}
                                {data?.isOLP ? "OLP" : "USDC"}
                            </Text>
                        </Flex>
                    )}
                    <Flex
                        style={{
                            paddingRight: 8,
                            opacity: 1,
                            zIndex: 10,
                            borderWidth: 1,
                            borderRadius: 8,
                            backgroundColor: "rgba(208, 219, 218, 0.05)",
                            color: "white",
                            borderColor: "rgba(208, 219, 218, 0.05)",
                            pointerEvents: "auto",
                            minHeight: 60,
                        }}
                        justify="space-between"
                        align="center"
                    >
                        <input
                            name="addressToWithdraw"
                            placeholder="Enter wallet address"
                            type="text"
                            value={addressToWithdraw}
                            onChange={(e) => {
                                setAddressToWithdraw(
                                    e.target.value as `0x${string}`
                                );
                            }}
                            style={{
                                padding: "12px 10px",
                                backgroundColor: "transparent",
                                borderWidth: 0,
                                opacity: 1,
                                zIndex: 10,
                                borderColor: "rgba(208, 219, 218, 0.05)",
                                color: "white",
                                width: "100%",
                            }}
                        />
                        <Pressable
                            onPress={() =>
                                setAddressToWithdraw(
                                    wagmiAddress as `0x${string}`
                                )
                            }
                        >
                            <Box
                                style={{
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    borderRadius: 6,
                                    backgroundColor: "#252424",
                                }}
                            >
                                <Text
                                    smaller
                                    semiBold
                                    color="rgba(208, 219, 218, 0.70)"
                                >
                                    Primary (
                                    {`${wagmiAddress?.substring(
                                        0,
                                        4
                                    )}...${wagmiAddress?.substring(
                                        wagmiAddress?.length - 4,
                                        wagmiAddress?.length
                                    )}`}
                                    )
                                </Text>
                            </Box>
                        </Pressable>
                    </Flex>
                    {/* <Input
                    value={addressToWithdraw}
                    onChangeText={(value: string) => {
                        setAddressToWithdraw(value as `0x${string}`);
                    }}
                    // @ts-ignore
                    name="addressToWithdraw"
                    label={"Enter wallet address"}
                    placeholder="0x...."
                    type="text"
                    style={{
                        fontFamily: theme.text.regularMono,
                        fontSize: theme.text.medium,
                        height: 150,
                    }}
                /> */}
                </Box>

                <Flex gap={theme.spacing.medium} justify="end">
                    {/* //@ts-ignore */}
                    <Button
                        text="Close"
                        primary={false}
                        onPress={onClose as () => void}
                    />
                    <Button
                        text="Transfer"
                        primary
                        loading={loading}
                        onPress={handleWithdraw}
                        disabled={loading || !addressToWithdraw?.length}
                    />
                </Flex>
            </Box>
        </Modal>
    );
};

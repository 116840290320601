import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Image } from "@atoms/Image";
import { useAppContext } from "@contexts/app";
import { Pressable } from "react-native";
import Modal from "@organisms/Modal";
import { useCallback, useState } from "react";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { useWeb3Context } from "@contexts/web3";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useAllowance } from "@contexts/hooks/useAllowance";
import { Lottie } from "@atoms/Lottie/Lottie";
import LOTTIE_SPINNER from "../../../../../assets/animations/infinity-white.json";
import { Icon } from "@atoms/Icon";

const Enable1CTModal = () => {
    const [loading, setLoading] = useState(false);
    const { showEnableCTModal, setShowEnableCTModal, toggleSmartAccount } =
        useAppContext();

    return (
        <Modal
            isVisible={showEnableCTModal.visible}
            onClose={() =>
                setShowEnableCTModal({
                    visible: false,
                    delegationStep: -1,
                    allowanceStep: -1,
                })
            }
            hasCustomHeader={true}
            renderCustomHeader={null}
            maxWidth={406}
        >
            <Flex style={{ padding: 16 }} direction="column">
                {/* <Pressable
                    onPress={() =>
                        setShowEnableCTModal({
                            visible: false,
                            delegationStep: -1,
                            allowanceStep: -1,
                        })
                    }
                    style={{ zIndex: 1000 }}
                >
                    <Box
                        style={{
                            position: "absolute",
                            width: 80,
                            height: 50,
                            right: 0,
                        }}
                    ></Box>
                </Pressable> */}
                <Flex justify="space-between" align="center">
                    <Text bigger semiBold color="#D0DBDA">
                        Enable 1-Click Trading
                    </Text>
                    <Pressable
                        onPress={() =>
                            setShowEnableCTModal({
                                visible: false,
                                delegationStep: -1,
                                allowanceStep: -1,
                            })
                        }
                        style={{ zIndex: 1000 }}
                    >
                        <Box>
                            <Icon name="close" size={12} />
                        </Box>
                    </Pressable>
                </Flex>

                <Text
                    color="#D0DBDA"
                    big
                    style={{ marginTop: 16, lineHeight: "normal" }}
                >
                    {
                        "Make the most of Ostium. Enable gasless\ntransactions and 1-click trading"
                    }
                    .
                </Text>

                <Box
                    style={{
                        height: 0.5,
                        marginTop: 16,
                        marginBottom: 4,
                        backgroundColor: "rgba(217, 217, 217, 0.10)",
                    }}
                ></Box>

                <Box style={{ paddingTop: 16 }}>
                    <Text
                        smaller
                        style={{ color: "rgba(208, 219, 218, 0.40)" }}
                    >
                        STEPS
                    </Text>
                    <Text big color="#D0DBDA" lineHeight={26}>
                        Sign the following wallet requests.
                    </Text>
                    <Flex
                        style={{ paddingTop: 20 }}
                        direction="column"
                        gap={24}
                    >
                        <Flex align="center">
                            {showEnableCTModal.delegationStep === 0 ? (
                                <Flex
                                    style={{
                                        backgroundColor:
                                            "rgba(255, 90, 25, 0.20)",
                                        borderRadius: 12,
                                        padding: 12,
                                    }}
                                >
                                    <Text semiBold bigger color="white">
                                        1
                                    </Text>
                                </Flex>
                            ) : (
                                <Flex
                                    style={{
                                        backgroundColor:
                                            "rgba(255, 90, 25, 0.20)",
                                        borderRadius: 12,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        paddingLeft:
                                            showEnableCTModal.delegationStep ===
                                            1
                                                ? 0
                                                : 12,
                                        paddingRight:
                                            showEnableCTModal.delegationStep ===
                                            1
                                                ? 0
                                                : 12,
                                    }}
                                >
                                    {showEnableCTModal.delegationStep === 1 ? (
                                        <Lottie
                                            source={LOTTIE_SPINNER}
                                            width={40}
                                            height={18}
                                        />
                                    ) : (
                                        <Icon name="checkmark" size={16} />
                                    )}
                                </Flex>
                            )}
                            <Flex
                                direction="column"
                                justify="flex-start"
                                style={{ marginLeft: 15, gap: 4 }}
                            >
                                <Text
                                    semiBold
                                    smaller
                                    color="rgba(208, 219, 218, 0.40)"
                                    style={{ textTransform: "uppercase" }}
                                >
                                    Enable account delegation
                                </Text>
                                <Text big color="#D0DBDA" lineHeight={26}>
                                    {"Delegate signatures to a smart wallet."}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex align="center">
                            {showEnableCTModal.allowanceStep === 0 ? (
                                <Flex
                                    style={{
                                        backgroundColor:
                                            "rgba(255, 90, 25, 0.20)",
                                        borderRadius: 12,
                                        padding: 12,
                                    }}
                                >
                                    <Text semiBold bigger color="white">
                                        2
                                    </Text>
                                </Flex>
                            ) : (
                                <Flex
                                    style={{
                                        backgroundColor:
                                            "rgba(255, 90, 25, 0.20)",
                                        borderRadius: 12,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        paddingLeft:
                                            showEnableCTModal.allowanceStep ===
                                            1
                                                ? 0
                                                : 12,
                                        paddingRight:
                                            showEnableCTModal.allowanceStep ===
                                            1
                                                ? 0
                                                : 12,
                                    }}
                                >
                                    {showEnableCTModal.allowanceStep === 1 ? (
                                        <Lottie
                                            source={LOTTIE_SPINNER}
                                            width={40}
                                            height={18}
                                        />
                                    ) : (
                                        <Icon name="checkmark" size={16} />
                                    )}
                                </Flex>
                            )}
                            <Flex
                                direction="column"
                                justify="flex-start"
                                style={{ marginLeft: 15, gap: 4 }}
                            >
                                <Text
                                    semiBold
                                    smaller
                                    color="rgba(208, 219, 218, 0.40)"
                                    style={{ textTransform: "uppercase" }}
                                >
                                    Set Allowance
                                </Text>
                                <Text
                                    big
                                    color="#D0DBDA"
                                    style={{ lineHeight: "normal" }}
                                >
                                    {
                                        "Set the maximum allowance. It’s\nadvisable to set this high."
                                    }
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex justify="space-between" style={{ marginTop: 24 }}>
                        <Pressable>
                            <Flex
                                style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    gap: 12,
                                }}
                                align="center"
                            >
                                {/* <Image
                                    source={"./telegram.png"}
                                    priority
                                    width={20}
                                    height={20}
                                    alt={`logo"`}
                                /> */}
                                <Text
                                    big
                                    color="rgba(208, 219, 218, 0.70)"
                                ></Text>
                            </Flex>
                        </Pressable>
                        <Pressable
                            onPress={() =>
                                showEnableCTModal.delegationStep === 2 &&
                                showEnableCTModal.allowanceStep === 2
                                    ? setShowEnableCTModal({
                                          visible: false,
                                          delegationStep: -1,
                                          allowanceStep: -1,
                                      })
                                    : null
                            }
                        >
                            <Flex
                                style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    gap: 12,
                                    borderRadius: 6,
                                    opacity:
                                        showEnableCTModal.delegationStep ===
                                            2 &&
                                        showEnableCTModal.allowanceStep === 2
                                            ? 1
                                            : 0.5,
                                    backgroundColor:
                                        "rgba(208, 219, 218, 0.90)",
                                }}
                                align="center"
                            >
                                <Text big color="#070606">
                                    Finish
                                </Text>
                            </Flex>
                        </Pressable>
                    </Flex>
                </Box>
            </Flex>
        </Modal>
    );
};

export default Enable1CTModal;

import { Flex } from "@atoms/Flex";
import { useTheme } from "styled-components/native";
import { TabBarItem } from "@screens/Trade/components/Form/TabBar";

export interface ITab {
    text: string;
    id: string;
}

interface ReferralTabsProps {
    tabs: ITab[];
    activeTab: ITab;
    setActiveTab: (tab: ITab) => void;
}

export const ReferralTabs = ({
    tabs,
    activeTab,
    setActiveTab,
}: ReferralTabsProps) => {
    const theme = useTheme();

    return (
        <Flex
            style={{
                borderWidth: 0,
                borderBottomWidth: 2,
                borderBottomColor: theme.color.rgba(theme.color.grey[700], 0.5),
                height: 36,
            }}
        >
            {tabs.map((item, index) => {
                const isFirst = index === 0;
                const isLast = index === tabs.length - 1;

                return (
                    <TabBarItem
                        key={`referrals-main-tab-item-${item.id}-${index}`}
                        item={item}
                        isFirst={isFirst}
                        isLast={isLast}
                        onPress={() => setActiveTab(item)}
                        borderRadius={0}
                        tabFlex={undefined}
                        style={{
                            borderBottomWidth: activeTab.id === item.id ? 2 : 0,
                            borderBottomColor: theme.color.primary,
                            backgroundColor: "transparent",
                        }}
                        textStyle={{
                            color: theme.color.white,
                            fontSize: 12,
                            fontWeight:
                                activeTab.id === item.id ? "500" : "normal",
                        }}
                    />
                );
            })}
        </Flex>
    );
};

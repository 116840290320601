import { Box } from "@atoms/Flex";
import NextImage, { ImageProps } from "next/image";
import { memo } from "react";

export interface ImageInterface {
    source: any;
    priority?: ImageProps["priority"];
    height: number;
    width?: number;
    uri?: string;
    alt?: string;
    lazy?: boolean;
    quality?: number;
    style?: ImageProps["style"];
    selectable?: boolean;
    fill?: boolean;
    onReady?: () => void;
    fetchPriority?: ImageProps["fetchPriority"];
}

export const Image = memo(
    ({
        alt = "",
        height,
        source,
        uri,
        width,
        priority,
        quality,
        style,
        selectable,
        onReady,
        lazy,
        ...props
    }: ImageInterface) => {
        const src =
            typeof source === "string" ? source : uri || source?.src || "";

        return (
            <Box style={{ width, height }}>
                <NextImage
                    src={src}
                    fill={true}
                    alt={alt}
                    onLoad={onReady}
                    unoptimized={true}
                    placeholder="empty"
                    quality={priority ? 100 : quality ?? 75}
                    blurDataURL={src}
                    style={{
                        ...style,
                        userSelect: selectable ? "auto" : "none",
                    }}
                    sizes={
                        "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    }
                    loading={priority ? "eager" : lazy ? "lazy" : undefined}
                    {...props}
                />
            </Box>
        );
    }
);

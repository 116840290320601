import React, { useEffect, useState } from "react";
import { Dimensions, ScaledSize } from "react-native";

export function useDimensions() {
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));

    useEffect(() => {
        function onChange({ window }: { window: ScaledSize; screen: ScaledSize }) {
            setDimensions(window);
        }

        // Use the correct event subscription pattern that works in react-native-web
        const subscription = Dimensions.addEventListener("change", onChange);

        return () => {
            // Proper cleanup that works in both RN and RN Web
            subscription.remove();
        };
    }, []);

    return dimensions;
}

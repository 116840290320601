import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Button } from "@molecules/Button";
import { Statistic } from "@molecules/Statistic";
import { useTheme } from "styled-components/native";
import { Pressable } from "react-native";
import { Image } from "@atoms/Image";
import { getBaseS3URL, formatPrice } from "@utils";
import { useReferrals } from "@contexts/hooks/useReferrals";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { ReferralsList } from "./ReferralsList";
import { useReferralHelpers } from "../hooks/useReferralHelpers";
import { openTelegram } from "../utils/helpers";

export const ReferralStats = ({ onClose }: { onClose: () => void }) => {
    const theme = useTheme();
    const { address, smartAccountAddress } = useOstiumAccount();
    const {
        referredUsers,
        totalReferrals,
        totalReferralDeposits,
        totalReferralVolume,
        isLoading,
        isLoadingReferredUsers,
    } = useReferrals(address, smartAccountAddress as `0x${string}`);
    const { onCopy } = useReferralHelpers();

    return (
        <>
            {/* Referral Stats */}
            <Box
                style={{
                    minHeight: 350,
                }}
            >
                <Box
                    style={{
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            0.05
                        ),
                        borderRadius: theme.spacing.smaller,
                        padding: theme.spacing.medium,
                        marginTop: theme.spacing.medium,
                    }}
                >
                    <Flex gap={theme.spacing.smaller} justify="space-between">
                        <Statistic
                            label="Users"
                            value={totalReferrals}
                            loading={isLoading}
                            tooltip={{
                                title: "Referred Users",
                                minWidth: true,
                                content:
                                    "The total number of users referred to Ostium.",
                            }}
                        />
                        <Statistic
                            label="Deposits"
                            value={`${formatPrice(totalReferralDeposits, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                            tooltip={{
                                title: "Deposits",
                                minWidth: true,
                                content:
                                    "The total amount of deposits made by referred users.",
                            }}
                        />
                        <Statistic
                            label="Volume"
                            value={`${formatPrice(totalReferralVolume, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                            tooltip={{
                                title: "Volume",
                                minWidth: true,
                                content:
                                    "The total volume generated by referred users.",
                            }}
                        />
                    </Flex>
                </Box>

                <ReferralsList
                    users={referredUsers}
                    isLoading={false}
                    // isLoading={isLoadingReferredUsers}
                />
            </Box>

            <Flex gap={theme.spacing.medium} justify="end">
                <Pressable
                    onPress={() => openTelegram("https://t.me/OstiumSupport")}
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: theme.spacing.smaller,
                        padding: theme.spacing.smaller,
                    }}
                >
                    <Image
                        priority
                        source={`${getBaseS3URL("telegram.png")}`}
                        height={16}
                        width={16}
                        fill={true}
                        alt={`/telegram`}
                    />
                    <Text color={theme.color.white}>Reach Out</Text>
                </Pressable>
                <Button text="Copy Link" primary onPress={onCopy} />
            </Flex>
        </>
    );
};

import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { getPairKey, PriceProvider, usePriceContext } from "@contexts/price";
import { Select, SelectOptionType } from "@molecules/Select";
import { useTradeContext } from "@screens/Trade/context";
import { IPerformance } from "@screens/Trade/usePerformance";
import { formatPrice } from "@utils";
import { Pair } from "gql/graphql";

import { useMemo, useState } from "react";
import { FlatList, Pressable } from "react-native";
import { ListRenderItem } from "react-native";
import { useTheme } from "styled-components/native";

enum TopMarket {
    FAVORITES = "FAVORITES",
    LOSERS = "LOSERS",
    GAINERS = "GAINERS",
}

const MARKETS_SELECT_OPTIONS: SelectOptionType[] = [
    {
        text: "Favorites",
        value: TopMarket.FAVORITES,
    },
    {
        text: "Top Losers",
        value: TopMarket.LOSERS,
    },
    {
        text: "Top Gainers",
        value: TopMarket.GAINERS,
    },
];

export const Markets = () => {
    const { performances } = useTradeContext();

    return (
        <PriceProvider>
            <TopMarkets performances={performances} />
        </PriceProvider>
    );
};

const TopMarkets = ({
    performances,
}: {
    performances: Map<string, IPerformance> | null;
}) => {
    const theme = useTheme();

    const { prices } = usePriceContext();
    const { pairs } = useAppContext();
    const { changePair, pair, favorites } = useTradeContext();

    const [selected, setSelected] = useState(MARKETS_SELECT_OPTIONS[0]);

    const markets: Pair[] = useMemo(() => {
        if (!!!pairs && !!!prices && !!!performances) {
            return [];
        }

        if (selected.value === TopMarket.FAVORITES) {
            return [...pairs.values()].filter((pair) =>
                favorites.includes(pair.id)
            );
        }
        return sortMarkets({
            pairs,
            prices,
            performances,
            sortBy: selected.value as TopMarket,
        });
    }, [favorites, pairs, performances, prices, selected.value]);

    const renderMarket: ListRenderItem<Pair> = ({ item }) => {
        const price = prices?.get(getPairKey(item));
        if (!price?.mid) return <></>;
        const performance = performances?.get(getPairKey(item));

        const price24hAgo = performance?.price24hAgo
            ? (100 * (price?.mid - performance?.price24hAgo)) / price?.mid
            : 0;

        return (
            <MarketItem
                item={item}
                price24hAgo={price24hAgo}
                isMarketOpen={price.isMarketOpen}
                isActive={pair?.id === item.id}
                onPress={() => changePair(getPairKey(item))}
            />
        );
    };

    return (
        <Flex align="center" flex={1}>
            <Select
                position="top"
                selected={selected}
                options={MARKETS_SELECT_OPTIONS}
                onChange={setSelected}
            />
            <FlatList
                horizontal
                showsHorizontalScrollIndicator={false}
                data={markets}
                renderItem={renderMarket}
                contentContainerStyle={{
                    paddingHorizontal: theme.spacing.smaller,
                }}
                ItemSeparatorComponent={() => (
                    <Box style={{ width: theme.spacing.smaller }} />
                )}
                ListEmptyComponent={() => (
                    <Text
                        smallest
                        color={theme.color.rgba(theme.color.white, 0.7)}
                    >
                        None
                    </Text>
                )}
                style={{
                    height: "100%",
                }}
            />
        </Flex>
    );
};

function sortMarkets({
    pairs,
    prices,
    performances,
    sortBy,
}: {
    pairs: Map<string, Pair>;
    prices?: Map<
        string,
        {
            mid: number;
            bid: number;
            ask: number;
        }
    >;
    performances: Map<string, IPerformance> | null;
    sortBy: TopMarket;
}) {
    if (!sortBy || !prices || !pairs) return [];

    const filtered = [...pairs.values()].filter((pair) => {
        const pairKey = getPairKey(pair);
        const price = prices.get(pairKey)?.mid;
        const performance = performances?.get(pairKey);
        if (!price || !performance) return false;

        const price24hAgo = (100 * (price - performance?.price24hAgo)) / price;

        if (sortBy === TopMarket.LOSERS) {
            if (price24hAgo >= 0) return false;
        }
        if (sortBy === TopMarket.GAINERS) {
            if (price24hAgo <= 0) return false;
        }
        return true;
    });

    const sorted = filtered.sort((a, b) => {
        if (!prices || !performances) return 0;
        const priceA = prices.get(getPairKey(a))?.mid;
        const priceB = prices.get(getPairKey(b))?.mid;
        const performanceA = performances.get(getPairKey(a));
        const performanceB = performances.get(getPairKey(b));

        if (!performanceA || !priceA || !performanceB || !priceB) return 0;

        const priceChangeA =
            (100 * (priceA - performanceA.price24hAgo)) / priceA;
        const priceChangeB =
            (100 * (priceB - performanceB.price24hAgo)) / priceB;

        switch (sortBy) {
            case TopMarket.GAINERS:
                return priceChangeA < priceChangeB ? 1 : -1;
            case TopMarket.LOSERS:
                return priceChangeA > priceChangeB ? 1 : -1;
            case TopMarket.FAVORITES:
                return 1;
            default:
                return 1;
        }
    });

    return sorted;
}

const MarketItem = ({
    item,
    isActive,
    isMarketOpen,
    price24hAgo,
    onPress,
}: {
    item: Pair;
    isMarketOpen: boolean;
    isActive: boolean;
    price24hAgo: number;
    onPress: () => void;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();

    return (
        <Pressable
            style={{
                justifyContent: "center",
                minWidth: 120,
            }}
            onPress={onPress}
        >
            <Flex
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    height: 24,
                    borderRadius: theme.radius.medium,
                    paddingHorizontal: theme.spacing.smaller,
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovered || isActive ? 0.1 : 0
                    ),
                }}
                align="center"
                justify="space-between"
                gap={theme.spacing.smaller}
            >
                {/* <Image lazy
                    source={getPairLogo(item.from)}
                    width={16}
                    height={16}
                    alt={`${item.from} / ${item.to} logo`}
                /> */}
                <Flex align="center" gap={1}>
                    <Text smallest semiBold>
                        {item.from}
                    </Text>
                    <Text
                        smallest
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        {` / ${item.to}`}
                    </Text>
                </Flex>
                <Text
                    smallest
                    semiBold
                    green={price24hAgo > 0}
                    red={price24hAgo < 0}
                >
                    {`${formatPrice(price24hAgo, { decimals: 2 })}%`}
                </Text>
            </Flex>
        </Pressable>
    );
};

import { useQuery, gql } from "@hooks/useApollo";
import { useWeb3Context } from "@contexts/web3";

import { DropResult } from "@hello-pangea/dnd";
import { useStoredState } from "@hooks/useStoredState";
import { SelectOptionType } from "@molecules/Select";
import { GET_USER } from "@screens/Portfolio/Positions/PortfolioStatistics";
import { Operation, PositionOrderTypes, Timeframe } from "@screens/Trade/utils";
import { Limit, LpNft, Order, Pair, Trade, User } from "gql/graphql";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IListColumn } from "../../theme/components/organisms/List/Column";
import { IListTabOption } from "../Trade/components/List/Tabs";
import { Sort } from "../Trade/components/List/Template/context";
import {
    Column,
    HISTORY_COLUMNS,
    ORDER_COLUMNS,
    TRADE_COLUMNS,
} from "../Trade/components/List/utils";
import { PRECISION_2 } from "@ostium/formulae/src";

import { BigNumber } from "@utils";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useAppContext } from "@contexts/app";
import { useBalance } from "@hooks/useBalance";
import { useTokenBalance } from "@hooks/useTokenBalance";
import { GET_ORDERS, GET_TRADES } from "@screens/Trade/components/List/context";

export enum Tab {
    TRADES = "TRADES",
    ORDERS = "ORDERS",
    HISTORY = "HISTORY",
    TRADERS = "TRADERS",
    MOST_TRADES = "MOST_TRADES",
}

interface IColumns {
    [key: string]: IListColumn[];
}

export const HISTORY_TIMEFRAMES: SelectOptionType[] = [
    {
        text: "1 day",
        value: Timeframe.Daily,
    },
    {
        text: "1 week",
        value: Timeframe.Weekly,
    },
    {
        text: "1 month",
        value: Timeframe.Monthly,
    },
    {
        text: "3 months",
        value: Timeframe.Yearly,
    },
];

export const OPERATIONS: SelectOptionType[] = [
    {
        text: "All",
        value: Operation.All,
    },

    {
        text: "Open",
        value: Operation.Open,
    },
    {
        text: "Close",
        value: Operation.Close,
    },
    {
        text: "TakeProfit",
        value: Operation.TakeProfit,
    },
    {
        text: "StopLoss",
        value: Operation.StopLoss,
    },
    {
        text: "Liquidation",
        value: Operation.Liquidation,
    },
    {
        text: "Cancelled",
        value: Operation.Cancelled,
    },
];

const OffBoardModalContext = createContext(
    {} as {
        pair?: Pair;
        pairs: { [key: string]: Pair };

        tabs: IListTabOption[];
        showAllPairs: boolean;
        isCollapsed?: boolean;
        toggleShowAllPairs: () => void;
        toggleCollapsed?: () => void;
        timeframe: SelectOptionType;
        operation: SelectOptionType;

        // data
        userData?: User;
        tradesData: Trade[];
        ordersData: (Limit | Order)[];
        historyData: Order[];
        isLoadingTrades: boolean;
        isLoadingOrders: boolean;
        isLoadingHistory: boolean;

        // columns
        initialColumns: IColumns;
        onChangeOperation: (value: SelectOptionType) => void;
        onChangeTimeframe: (value: SelectOptionType) => void;
        onChangeSort: (value: IListColumn) => void;
        onToggleColumn: (column: IListColumn) => void;
        onResetColumns: () => void;
        onUpdateColumn: (result: DropResult) => void;
        onChangeTab: (id: string) => void;
        currentTab: string | null;
        totalTrades: number;
        showOffboardingModal: boolean;
        setShowOffboardingModal: (status: boolean) => void;
        balance: {
            formatted: number | undefined;
            value: bigint;
            decimals: number;
            symbol: string;
        };
        olpBalance: {
            formatted: number | undefined;
            value: bigint;
            decimals: number;
            symbol: string;
        };
        showTransferModal: {
            isVisible: boolean;
            data:
                | undefined
                | {
                      address?: string;
                      symbol?: string;
                      isNFT?: boolean;
                      nftId?: number;
                      nftName?: string;
                      assets?: number;
                      shares?: number;
                      isOLP?: boolean;
                  };
        };
        setShowTransferModal: (status: {
            isVisible: boolean;
            data:
                | undefined
                | {
                      address?: string;
                      symbol?: string;
                      isNFT?: boolean;
                      nftId?: number;
                      nftName?: string;
                      assets?: number;
                      shares?: number;
                      isOLP?: boolean;
                  };
        }) => void;
        lockedDeposits: LpNft[];
        isDataLoading: boolean;
        refetchTrades: () => void;
    }
);

// @ts-ignore
const DEFAULT_SORT: { [key in Tab]: Sort } = {
    [Tab.TRADES]: {
        by: null,
        direction: "desc",
        tab: Tab.TRADES,
    },
    [Tab.ORDERS]: {
        by: Column.TIME,
        direction: "desc",
        tab: Tab.ORDERS,
    },
    [Tab.HISTORY]: {
        by: Column.TIME,
        direction: "desc",
        tab: Tab.HISTORY,
    },
};

function useOffBoardModal({
    isCollapsed,
    pair,
    pairs,
    address,
    toggleCollapsed,
}: {
    address?: `0x${string}`;
    pair: Pair | null;
    pairs: Map<string, Pair>;
    isCollapsed?: boolean;
    toggleCollapsed?: () => void;
}) {
    const {
        showOffboardingModal,
        setShowOffboardingModal,
        setIsAAv2ModalVisible,
        setHasMigration,
    } = useAppContext();
    const [currentTab, setCurrentTab] = useState<string | null>(null);
    const [ordersData, setOrdersData] = useState<(Limit | Order)[]>([]);
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const [totalTrades, setTotalTrades] = useState(0);
    const { currentChain, storageContract } = useWeb3Context();
    //const [showOffboardingModal, setShowOffboardingModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState({
        isVisible: false,
        data: undefined,
    });
    const [showAllPairs, setShowAllPairs] = useStoredState(
        true,
        "SHOW_ALL_PAIRS"
    );

    const [timeframe, setTimeframe] = useStoredState<SelectOptionType>(
        HISTORY_TIMEFRAMES[1],
        "HISTORY_TIMEFRAME",
        HISTORY_TIMEFRAMES[1]
    );
    const [operation, setOperation] = useStoredState<SelectOptionType>(
        OPERATIONS[0],
        "HISTORY_OPERATIONS",
        OPERATIONS[0]
    );
    const [isDelayDone, setIsDelayDone] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsDelayDone(true);
        }, 2000);
    }, []);

    const initialColumns: IColumns = useMemo(() => {
        return {
            [Tab.TRADES]: TRADE_COLUMNS,
            [Tab.ORDERS]: ORDER_COLUMNS,
            [Tab.HISTORY]: HISTORY_COLUMNS,
        };
    }, []);

    const { balance } = useBalance({
        address,
        watch: true,
    });
    const { balance: olpBalance } = useTokenBalance({
        tokenAddress: currentChain?.contracts?.vault,
        address,
        watch: true,
    });

    // const getMarketOrders = useCallback(
    //     async (item: any) => {
    //         try {
    //             const { trade, wantedPrice } =
    //                 await storageContract?.reqID_pendingMarketOrder(
    //                     item.id.split("_")[0]
    //                 );
    //             if (trade) {
    //                 const result: Order = {
    //                     ...item,
    //                     leverage: trade.leverage.toString(),
    //                     collateral: trade.collateral.toString(),
    //                     stopLossPrice: trade.sl.toString(),
    //                     takeProfitPrice: trade.tp.toString(),
    //                     isBuy: trade.buy,
    //                     price: wantedPrice.toString(),
    //                     notional: BigNumber.from(trade.collateral)
    //                         .mul(trade.leverage)
    //                         .div(PRECISION_2)
    //                         .toString(),
    //                     orderType: PositionOrderTypes.Market,
    //                 };
    //                 return result;
    //             }
    //         } catch (err) {
    //             console.warn(err);
    //         }
    //     },
    //     [storageContract]
    // );

    const onChangeTab = useCallback((id: string) => {
        setCurrentTab(id);
    }, []);

    // const parseOrders = useCallback(
    //     async (data: { limits: Limit[]; orders: Order[] }) => {
    //         try {
    //             setIsLoadingOrders(true);

    //             let results = await Promise.all(
    //                 data.orders.map(getMarketOrders)
    //             );
    //             results = results.filter((item) => item);

    //             const unique = new Map(
    //                 [...data.limits, ...(results as Order[])].map((item) => [
    //                     item.id,
    //                     item,
    //                 ])
    //             );

    //             setOrdersData([...unique.values()]);
    //             setIsLoadingOrders(false);
    //         } catch (err) {
    //             console.warn(err);
    //             setIsLoadingOrders(false);
    //         }
    //     },
    //     [getMarketOrders]
    // );

    // useEffect(() => {
    //     if (!address && ordersData?.length) {
    //         setIsLoadingOrders(false);
    //         setOrdersData([]);
    //     }
    // }, [address, ordersData?.length]);

    // const { data: userData } = useQuery(GET_USER, {
    //     variables: {
    //         id: address,
    //     },
    //     skip: !address,
    //     pollInterval: 5000,
    //     fetchPolicy: "cache-and-network",
    // });

    // useQuery(GET_ORDERS, {
    //     variables: {
    //         trader: address?.toLowerCase(),
    //     },
    //     skip: !address,
    //     pollInterval: 5000,
    //     fetchPolicy: "cache-and-network",
    //     onCompleted: parseOrders,
    //     notifyOnNetworkStatusChange: true,
    // });

    const {
        data: tradesData,
        loading: isLoadingTrades,
        refetch,
    } = useQuery(GET_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 5000,
        fetchPolicy: "network-only",
    });

    const getMarketOrders = useCallback(
        async (item: any) => {
            try {
                if (!storageContract) return null;

                const result = await storageContract?.reqID_pendingMarketOrder(
                    item.id.split("_")[0]
                );

                // Check if result exists and has required properties
                if (result && result.trade && result.wantedPrice) {
                    const { trade, wantedPrice } = result;
                    return {
                        ...item,
                        leverage: trade.leverage.toString(),
                        collateral: trade.collateral.toString(),
                        stopLossPrice: trade.sl.toString(),
                        takeProfitPrice: trade.tp.toString(),
                        isBuy: trade.buy,
                        price: wantedPrice.toString(),
                        notional: BigNumber.from(trade.collateral)
                            .mul(trade.leverage)
                            .div(PRECISION_2)
                            .toString(),
                        orderType: PositionOrderTypes.Market,
                    } as Order;
                }
                return null;
            } catch (err) {
                console.warn(err);
                return null;
            }
        },
        [storageContract]
    );

    const parseOrders = useCallback(
        async (data: { limits: Limit[]; orders: Order[] }) => {
            try {
                setIsLoadingOrders(true);

                let results = await Promise.all(
                    data.orders.map(getMarketOrders)
                );

                results = results.filter((item) => item);

                const unique = new Map(
                    [...data.limits, ...(results as Order[])].map((item) => [
                        item.id,
                        item,
                    ])
                );

                setOrdersData([...unique.values()]);
                setIsLoadingOrders(false);
            } catch (err) {
                console.warn(err);
                setIsLoadingOrders(false);
            }
        },
        [getMarketOrders]
    );

    useQuery(GET_ORDERS, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address || !storageContract,
        pollInterval: 5000,
        fetchPolicy: "cache-and-network",
        onCompleted: parseOrders,
        notifyOnNetworkStatusChange: true,
    });

    const { data: vaultData, loading: isLoadingVault } = useQuery(GET_VAULT, {
        pollInterval: 5000,
        skip: !address,
        variables: {
            trader: address?.toLowerCase(),
        },
    });

    const toggleShowAllPairs = useCallback(
        () => setShowAllPairs(!showAllPairs),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showAllPairs]
    );

    const tabs: Partial<IListTabOption>[] = useMemo(
        () => [
            {
                id: Tab.TRADES,
                text: `Trades (${
                    tradesData?.trades?.length
                        ? `${tradesData?.trades?.length}`
                        : "0"
                })`,
            },
            {
                id: Tab.ORDERS,
                text: `Orders ${
                    ordersData?.length ? `(${ordersData?.length})` : ""
                }`,
            },
            {
                id: Tab.HISTORY,
                text: `History`,
            },
        ],
        [tradesData?.trades?.length, ordersData?.length]
    );

    const tradesLength = useMemo(
        () => tradesData?.trades?.length ?? 0,
        [tradesData]
    );
    const ordersLength = useMemo(() => ordersData?.length ?? 0, [ordersData]);
    const balanceFormatted = useMemo(
        () => Number(balance?.formatted) || 0,
        [balance]
    );
    const olpBalanceFormatted = useMemo(
        () => Number(olpBalance?.formatted) || 0,
        [olpBalance]
    );
    const lockedDeposits: LpNft[] = useMemo(
        () =>
            vaultData?.lpNFTs.filter((item: LpNft) => {
                const atTime = new Date(Number(item.atTime) * 1000);
                const unlocksOn = new Date(
                    atTime.getTime() + item.lockDuration * 1000
                );

                return !item.isUnlocked;
            }) || [],
        [vaultData]
    );
    const lockedDepositsLength = useMemo(
        () => lockedDeposits.length ?? 0,
        [lockedDeposits]
    );
    const isDataLoading = useMemo(
        () =>
            isLoadingTrades ||
            isLoadingVault ||
            isLoadingOrders ||
            balance.formatted === undefined ||
            olpBalance.formatted === undefined,
        [
            isLoadingTrades,
            isLoadingVault,
            balance.formatted,
            olpBalance.formatted,
            isLoadingOrders,
        ]
    );

    useEffect(() => {
        if (
            (tradesLength > 0 ||
                ordersLength > 0 ||
                balanceFormatted > 0 ||
                olpBalanceFormatted > 0 ||
                lockedDepositsLength > 0) &&
            isDelayDone
        ) {
            setHasMigration(true);
            setIsAAv2ModalVisible(true);
        }

        if (
            tradesLength &&
            tradesLength === 0 &&
            ordersLength &&
            ordersLength === 0 &&
            balanceFormatted &&
            balanceFormatted === 0 &&
            olpBalanceFormatted &&
            olpBalanceFormatted === 0 &&
            lockedDepositsLength &&
            lockedDepositsLength === 0
        ) {
            setHasMigration(false);
        }
    }, [
        tradesLength,
        balanceFormatted,
        olpBalanceFormatted,
        lockedDepositsLength,
        setIsAAv2ModalVisible,
        isDelayDone,
        setHasMigration,
        ordersLength,
    ]);

    useEffect(() => {
        if (tradesLength > 0 && totalTrades === 0) {
            setTotalTrades(tradesLength);
        }
    }, [tradesLength, totalTrades]);

    return {
        address,
        //userData: userData?.user,
        // historyData: historyData?.orders || [],
        tradesData: tradesData?.trades || [],
        ordersData,
        isLoadingTrades,
        //isLoadingOrders,
        // isLoadingHistory,
        pair,
        pairs,
        showAllPairs,
        timeframe,
        tabs,
        isCollapsed,
        toggleCollapsed,
        operation,
        lockedDeposits,
        // onChangeOperation,

        // Columns
        initialColumns,
        // onChangeTimeframe,
        toggleShowAllPairs,
        onChangeTab,
        currentTab,
        totalTrades,
        showOffboardingModal,
        setShowOffboardingModal,
        balance,
        showTransferModal,
        setShowTransferModal,
        olpBalance,
        isDataLoading,
        refetchTrades: refetch,
    };
}

const OffBoardModalProvider = ({
    children,
    pair,
    pairs,
    address,
    isCollapsed,
    toggleCollapsed,
}: {
    children: JSX.Element;
    pair: Pair | null;
    pairs: Map<string, Pair>;
    address?: `0x${string}`;
    isCollapsed?: boolean;
    toggleCollapsed?: () => void;
}) => {
    const value = useOffBoardModal({
        address,
        pairs,
        pair,
        isCollapsed,
        toggleCollapsed,
    });

    return (
        // @ts-ignore
        <OffBoardModalContext.Provider value={value}>
            {children}
        </OffBoardModalContext.Provider>
    );
};

const useOffBoardModalContext = () => useContext(OffBoardModalContext);

export { OffBoardModalProvider, useOffBoardModalContext };

const GET_VAULT = gql`
    query getUserData($trader: Bytes) @api(name: subgraph) {
        lpNFTs(
            where: { user: $trader }
            orderBy: atTime
            orderDirection: desc
        ) {
            id
            assetsDeposited
            assetsDiscount
            atTime
            isUnlocked
            lockDuration
            shares
        }
    }
`;

// const GET_TRADES = gql`
//     query ListTrades($trader: Bytes, $orderDirection: String)
//     @api(name: subgraph) {
//         trades(where: { trader: $trader, isOpen: true }) {
//             id
//             index
//             trader
//             tradeNotional
//             tradeType
//             openPrice
//             isBuy
//             notional
//             collateral
//             leverage
//             highestLeverage
//             stopLossPrice
//             takeProfitPrice
//             timestamp
//             funding
//             rollover
//             pair {
//                 id
//                 from
//                 to
//                 feed
//                 accRollover
//                 accFundingLong
//                 accFundingShort
//                 curFundingLong
//                 curFundingShort
//                 lastFundingRate
//                 lastFundingVelocity
//                 maxFundingFeePerBlock
//                 lastFundingBlock
//                 lastFundingTime
//                 rolloverFeePerBlock
//                 lastRolloverBlock
//                 longOI
//                 shortOI
//                 spreadP
//                 maxLeverage
//                 tradeSizeRef
//                 group {
//                     name
//                     maxLeverage
//                     minLeverage
//                 }
//             }
//         }
//     }
// `;

import { isMobile } from "@constants";
import { Maybe } from "gql/graphql";

export { BigNumber } from "@ethersproject/bignumber";

export function timeAgo(date: Date) {
    const currentDate = new Date();
    const timestamp = new Date(date).getTime();
    const timeDiff = currentDate.getTime() - timestamp;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44); // Average days in a month
    const years = Math.floor(days / 365);

    if (seconds < 60) {
        return seconds + "s ago";
    } else if (minutes < 60) {
        return minutes + "m ago";
    } else if (hours < 24) {
        return hours + "h ago";
    } else if (days < 7) {
        return days + "d ago";
    } else if (weeks < 4) {
        return weeks + "w ago";
    } else if (months < 12) {
        return months + "mo ago";
    } else {
        return years + "y ago";
    }
}

export function currencyLimitDigits(type: "fiat" | "crypto") {
    switch (type) {
        case "fiat":
            return {
                integerLimit: 7,
                decimalLimit: 6,
            };
        case "crypto":
            return {
                integerLimit: 7,
                decimalLimit: 8,
            };
    }
}

export function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getPairShortName(from?: string, to?: string) {
    let condition = from;
    if (from === "USD") {
        condition = to;
    }
    return condition;
}

export function getPairName(from?: string, to?: string) {
    let condition = from;
    if (from === "USD") {
        condition = to;
    }
    return getSymbolName(condition);
}

export function getSymbolName(condition: string | undefined) {
    switch (condition) {
        case "ETH":
            return "Ethereum";
        case "BTC":
            return "Bitcoin";
        case "USDC":
            return "USDC";
        case "SOL":
            return "Solana";
        case "XAU":
            return "Gold";
        case "XAG":
            return "Silver";
        case "WTI":
            return "Oil";
        case "EUR":
            return "Euro";
        case "USD":
            return "US Dollar";
        case "JPY":
            return "Yen";
        case "GBP":
            return "Pound";
        case "DAI":
            return "DAI";
        case "OLP":
            return "OLP";
        case "XPD":
            return "Palladium";
        case "XPT":
            return "Platinum";
        case "SPX":
            return "S&P 500";
        case "ARS":
            return "Argentine Peso";
        case "NG":
            return "Natural Gas";
        case "HOGS":
            return "Lean Hogs";
        case "HSI":
            return "Hang Seng Index";
        case "HKD":
            return "Hong Kong Dollar";
        case "LE":
            return "Live Cattle";
        case "NIK":
            return "Nikkei 225";
        case "HG":
            return "Copper";
        case "ZS":
            return "Soybeans";
        case "CL":
            return "WTI Oil";
        case "LCO":
            return "Brent Oil";
        case "CJ":
            return "Cocoa";
        case "DAX":
            return "DAX";
        case "FTSE":
            return "FTSE 100";
        case "SPY":
            return "S&P 500";
        case "DJI":
            return "Dow Jones";
        case "MEX":
            return "Mexican Peso";
        case "MXN":
            return "Mexican Peso";
        case "CAD":
            return "Canadian Dollar";
        case "CHF":
            return "Swiss Franc";
        case "AUD":
            return "Australian Dollar";
        case "NDX":
            return "Nasdaq";
        case "TSLA":
            return "Tesla";
        case "NVDA":
            return "NVIDIA";
        case "MSFT":
            return "Microsoft";
        case "GOOG":
            return "Google";
        case "AMZN":
            return "Amazon";
        case "META":
            return "Meta";

        default:
            return condition || "Unknown";
    }
}

export function getFunkyPairName(from?: string, to?: string) {
    let condition = from;

    if (from === "USD") condition = to;
    switch (condition) {
        case "ETH":
            return "Onchain Oil";
        case "BTC":
            return "Digital Gold";
        case "SOL":
            return "Solana";
        case "XAU":
            return "Boomer Gold";
        case "XAG":
            return "Lunar Lustre";
        case "WTI":
            return "Texas Treasure";
        case "EUR":
            return "Euro Sync";
        case "USD":
            return "US Dollar";
        case "JPY":
            return "Zen Yen";
        case "GBP":
            return "Royal Riches";
        case "XPD":
            return "Astral Alloy";
        case "XPT":
            return "Eternal Ice";
        case "SPX":
            return "S&P Stash";
        case "ARS":
            return "Argentine Peso";
        case "HKD":
            return "Hong Kong Dollar";
        case "HOGS":
            return "Skinny Pigs";
        case "NG":
            return "Natural Gas";
        case "BRN":
            return "Black Gold";
        case "HSI":
            return "Oriental Index";
        case "HG":
            return "Dr. Copper";
        case "LCO":
            return "Black Gold";
        case "CL":
            return "Texas Treasure";
        case "NIK":
            return "Japanese Jewel";
        case "LE":
            return "Moo Moolah";
        case "ZS":
            return "Soyboy";
        case "CJ":
            return "Cocoa Conquest";
        case "CAD":
            return "Maple Money";
        case "MEX":
            return "Tariff Target";
        case "DAX":
            return "Deutsche Dynamik";
        case "FTSE":
            return "Big Ben Bounty";
        case "NDAQ":
            return "Tech Treasure";
        case "DJI":
            return "Industrial Icon";
        case "SPX":
            return "S&P Stash";
        case "NDX":
            return "Nasdaq Necessity";
        case "TSLA":
            return "Tesla";
        case "NVDA":
            return "NVIDIA";
        case "MSFT":
            return "Microsoft";
        case "GOOG":
            return "Google";
        case "AMZN":
            return "Amazon";
        case "META":
            return "Meta";
        default:
            return getPairName(from, to);
    }
}

export function getPairLogo(from: string, to?: string) {
    if (from === "USD" && to != null)
        return getPairBaseS3URL(`${to.toLowerCase()}.svg`);
    return getPairBaseS3URL(`${from.toLowerCase()}.svg`);
}

export function getChainLogo(logo: string) {
    switch (logo) {
        case "arbitrum":
            return getPairBaseS3URL("arbitrum.svg");
        case "matic":
            return getPairBaseS3URL("matic.svg");
        case "ethereum":
            return getPairBaseS3URL("eth.svg");

        default:
            return null;
    }
}

export function formatDate(
    date: Date | string,
    options: Intl.DateTimeFormatOptions | undefined = {
        timeStyle: "medium",
        dateStyle: "short",
    },
    showSuffix: boolean = false
) {
    return `${new Intl.DateTimeFormat("en-GB", options)?.format(
        new Date(date)
    )}${showSuffix ? " UTC" : ""}`;
}

export function formatNumber(number: number) {
    return new Intl.NumberFormat("en-US").format(number);
}

export function formatNumberWithSuffix(number: number) {
    number = Math.floor(number);
    if (number >= 1e9) return Math.floor(number / 1e8) / 10 + "B";
    if (number >= 1e6) return Math.floor(number / 1e5) / 10 + "M";
    if (number >= 1e3) return Math.floor(number / 1e2) / 10 + "K";
    return formatNumber(number);
}

export function formatPrice(
    number: Maybe<number | string> | undefined,
    options: {
        decimals?: number;
        currency?: boolean;
        significant?: number;
    } = {
        currency: true,
        decimals: undefined,
        significant: undefined,
    }
) {
    if (typeof number === "string") return "-";
    if (number && isNaN(number)) return "-";

    let defaultOptions: Intl.NumberFormatOptions = {
        maximumSignificantDigits: options?.significant,
        minimumSignificantDigits: options?.significant,
        minimumFractionDigits:
            options?.decimals != null ? options?.decimals : undefined,
        maximumFractionDigits:
            options?.decimals != null ? options?.decimals : 6,
    };

    if (options.currency) {
        defaultOptions = {
            ...defaultOptions,
            style: "currency",
            currency: "USD",
        };
    }

    return new Intl.NumberFormat("en-US", {
        ...defaultOptions,
    }).format(number ? number : 0);
}

export function formatLargeNumber(number: number): string {
    if (Math.abs(number) >= 1e9) {
        return (number / 1e9).toFixed(1) + "B";
    } else if (Math.abs(number) >= 1e6) {
        return (number / 1e6).toFixed(1) + "M";
    } else if (Math.abs(number) >= 1e3) {
        return (number / 1e3).toFixed(1) + "K";
    } else {
        return formatPrice(number, {
            currency: false,
            decimals: 0,
        });
    }
}

export function formatList(list: string[]) {
    return new Intl.ListFormat("en-US", {
        style: "narrow",
    }).format(list);
}

export function formatAddress(address?: string) {
    return `${address?.substring(0, 4)}...${address?.substring(
        address?.length - 4,
        address?.length
    )}`;
}

export async function delay(time: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

export function capitalizeFirstLetter(string: any) {
    if (typeof string === "string")
        return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
    return string;
}

export function getBaseS3URL(name: string) {
    return `https://static.ostium.io/assets/${name}`;
}

export function getPairBaseS3URL(name: string) {
    return `https://static.ostium.io/assets/pairs/${name}`;
}

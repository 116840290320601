import ErrorBoundary from "@atoms/ErrorBoundary";
import { Box, Flex } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { PriceProvider } from "@contexts/price";
import { TRADE_HEADER_HEIGHT } from "@screens/Trade";
import { useTradeContext } from "@screens/Trade/context";
import { useTheme } from "styled-components/native";

import { BoxContainer } from "@atoms/BoxContainer";
import { Portal } from "@atoms/Portal";
import dynamic from "next/dynamic";
import { memo } from "react";
import { SIDEBAR_WIDTH } from "@constants";
import { useMedia } from "@hooks/useMedia";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { useWeb3Context } from "@contexts/web3";
import { useRouter } from "next/router";

const PairStatistics = dynamic(() => import("./PairStatistics"), {
    ssr: true,
});
const PairSelect = dynamic(() => import("./PairSelect"), {
    ssr: true,
});

export const TradeHeader = memo(() => {
    const theme = useTheme();
    const media = useMedia();
    const { settings } = useAppContext();
    const { changePair, pair } = useTradeContext();

    if (settings?.theme?.boxed) {
        return (
            <PriceProvider>
                <Box style={[{ zIndex: 100 }]}>
                    <Flex
                        align="center"
                        justify="space-between"
                        style={[
                            {
                                flexWrap: "wrap",
                                position: "relative",
                                zIndex: 10,
                            },
                        ]}
                        gap={theme.spacing.smaller}
                    >
                        <BoxContainer
                            style={{
                                borderWidth: 0,
                                width: media.isMobile ? "100%" : SIDEBAR_WIDTH,
                                height: "100%",
                            }}
                        >
                            <PairSelect selected={pair} onChange={changePair} />
                        </BoxContainer>

                        <BoxContainer
                            style={{
                                flex: 1,
                                height: TRADE_HEADER_HEIGHT,
                                borderWidth: 0,
                            }}
                        >
                            <PairStatistics pair={pair} />
                        </BoxContainer>
                    </Flex>
                </Box>
            </PriceProvider>
        );
    }

    return (
        <PriceProvider pair={pair}>
            <Box
                style={[
                    {
                        zIndex: 100,
                        borderTopWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.1),
                        borderBottomWidth: settings?.theme?.boxed ? 0 : 1,
                    },
                ]}
            >
                <Flex
                    align="center"
                    justify="space-between"
                    style={[
                        {
                            flexWrap: "wrap",
                            position: "relative",
                            zIndex: 10,
                        },
                    ]}
                >
                    <Flex
                        style={{
                            height: TRADE_HEADER_HEIGHT,
                            position: "relative",
                            zIndex: 10,
                        }}
                        justify="center"
                        align="center"
                    >
                        <ErrorBoundary>
                            <Box
                                style={{
                                    width: media.isMobile
                                        ? "100%"
                                        : SIDEBAR_WIDTH,
                                    height: "100%",
                                }}
                            >
                                <PairSelect
                                    selected={pair}
                                    onChange={changePair}
                                />
                            </Box>
                        </ErrorBoundary>
                    </Flex>
                    <ErrorBoundary>
                        <PairStatistics pair={pair} />
                    </ErrorBoundary>
                </Flex>
            </Box>
        </PriceProvider>
    );
});

export const TradeHeaderMobile = memo(() => {
    const theme = useTheme();
    const { isCTAActive, showedLaunchBanners } = useAppContext();
    const { changePair, pair } = useTradeContext();
    const { isOtherNetwork } = useWeb3Context();
    const { isMobile } = useMedia();
    const router = useRouter();
    const isRestricted = router?.query?.restricted;

    return (
        <Portal>
            <Box
                style={{
                    height: TRADE_HEADER_HEIGHT,
                    position: "fixed",
                    top:
                        HEADER_HEIGHT +
                        (isCTAActive
                            ? showedLaunchBanners
                                ? CTA_HEIGHT
                                : isMobile && (isOtherNetwork || isRestricted)
                                ? CTA_HEIGHT + 90
                                : CTA_HEIGHT + 60
                            : 0),
                    left: 0,
                    right: 0,
                }}
            >
                <Flex>
                    <Box
                        style={{
                            height: TRADE_HEADER_HEIGHT,
                            position: "relative",
                            zIndex: 10,
                            width: "100%",
                        }}
                    >
                        <ErrorBoundary>
                            <PriceProvider>
                                <PairSelect
                                    selected={pair}
                                    onChange={changePair}
                                />
                            </PriceProvider>
                        </ErrorBoundary>
                    </Box>
                </Flex>
            </Box>
        </Portal>
    );
});

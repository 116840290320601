import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { memo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";

export const Checkbox = memo(
    ({
        label,
        isActive,
        onToggle,
    }: {
        label?: string;
        isActive: boolean;
        onToggle: (isActive: boolean) => void;
    }) => {
        const theme = useTheme();

        const [isHovered, setIsHovered] = useState(false);

        return (
            <Pressable onPress={() => onToggle(!isActive)}>
                <Flex
                    gap={theme.spacing.smaller}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    align="center"
                    style={{
                        paddingVertical: theme.spacing.tiny,
                    }}
                >
                    <Box
                        style={{
                            borderWidth: 2,
                            width: 24,
                            height: 24,
                            borderRadius: theme.radius.medium,
                            borderColor: theme.color.rgba(
                                theme.color.white,
                                isHovered ? 0.2 : 0.1
                            ),
                            backgroundColor: theme.color.rgba(
                                theme.color.black,
                                1
                            ),
                        }}
                        justify="center"
                        align="center"
                    >
                        {isActive ? <Icon name="checkmark" size={12} /> : null}
                    </Box>
                    {label ? (
                        <Text smallest semiBold numberOfLines={1}>
                            {label}
                        </Text>
                    ) : null}
                </Flex>
            </Pressable>
        );
    }
);

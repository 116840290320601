import styled, { css } from "styled-components/native";
import { Text as RNText, TextProps } from "react-native";
import { isWeb } from "../../constants";
import Animated from "react-native-reanimated";
import { ThemeInterface } from "types/theme";
import { memo } from "react";

export interface IText extends TextProps {
    secondaryFont?: boolean;
    lineHeight?: number | string;
    letterSpacing?: number;
    mono?: boolean;
    light?: boolean;
    semiBold?: boolean;
    bold?: boolean;
    bolder?: boolean;
    textAlign?: string;
    whiteSpace?: string;
    textOverflow?: string;
    ellipsis?: boolean;
    uppercase?: boolean;
    lowercase?: boolean;
    capitalize?: boolean;

    color?: string;
    primary?: boolean;
    green?: boolean;
    red?: boolean;
    white?: boolean;
    black?: boolean;

    size?: string | number;
    tiny?: boolean;
    smallest?: boolean;
    smaller?: boolean;
    sml?: boolean;
    small?: boolean;
    medium?: boolean;
    big?: boolean;
    bigger?: boolean;
    biggest?: boolean;
    huge?: boolean;
    pointerEvents?: string;
    usePPSemiBold?: boolean;
}

export const Text = memo(styled(RNText)<IText>`
    text-align: ${({ textAlign }: { textAlign: string }) =>
        textAlign || "left"};

    ${({ whiteSpace }: { whiteSpace: string }) => {
        if (whiteSpace && isWeb) {
            return css`
                white-space: ${whiteSpace};
            `;
        }
    }}

    ${({ textOverflow }: { textOverflow: string }) => {
        if (textOverflow && isWeb) {
            return css`
                text-overflow: ${textOverflow};
            `;
        }
    }}

	${({ ellipsis }: { ellipsis: boolean }) => {
        if (ellipsis)
            return css`
                text-overflow: ellipsis;
                white-space: nowrap;
            `;
    }}
    
	${({ lineHeight, size }: { lineHeight: string; size: number }) => {
        if (isWeb) {
            return css`
                line-height: ${lineHeight
                    ? `${lineHeight}px`
                    : size
                    ? `${size}px`
                    : "100%"};
            `;
        }
        if (lineHeight || size)
            return css`
                line-height: ${lineHeight ?? size}px;
            `;
    }}
	${({
        uppercase,
        lowercase,
        capitalize,
    }: {
        uppercase: boolean;
        lowercase: boolean;
        capitalize: boolean;
    }) => {
        if (uppercase)
            return css`
                text-transform: uppercase;
            `;
        if (lowercase)
            return css`
                text-transform: lowercase;
            `;
        if (capitalize)
            return css`
                text-transform: capitalize;
            `;
    }};
    ${({
        bold,
        semiBold,
        mono,
        usePPSemiBold,
    }: {
        bold: boolean;
        bolder: boolean;
        semiBold: boolean;
        mono: boolean;
        usePPSemiBold: boolean;
    }) => {
        if (usePPSemiBold) {
            return css`
                font-weight: 600;
                font-family: ${({ theme }) => theme.text.semiBoldPP};
            `;
        }

        if (bold) {
            if (mono) {
                return css`
                    font-weight: 700;
                    font-feature-settings: "ss04";
                    font-family: ${({ theme }) => theme.text.boldMono};
                `;
            }
            return css`
                font-weight: 700;
                font-family: ${({ theme }) => theme.text.bold};
            `;
        }

        if (semiBold) {
            if (mono) {
                return css`
                    font-weight: 600;
                    font-feature-settings: "ss04";
                    font-family: ${({ theme }) => theme.text.semiBoldMono};
                `;
            }
            return css`
                font-weight: 600;
                font-family: ${({ theme }) => theme.text.semiBold};
            `;
        }

        if (mono) {
            return css`
                font-weight: 500;
                font-feature-settings: "ss04";
                font-family: ${({ theme }) => theme.text.regularMono};
            `;
        }

        return css`
            font-family: ${({ theme }) => theme.text.regular};
            font-weight: 400;
        `;
    }};
    ${({
        theme,
        primary,
        red,
        white,
        green,
        black,
        color,
    }: {
        theme: ThemeInterface;
        primary: boolean;
        red: boolean;
        green: boolean;
        white: boolean;
        black: boolean;
        color: string;
    }) => {
        if (color)
            return css`
                color: ${color};
            `;
        if (primary)
            return css`
                color: ${theme.color.primary};
            `;
        if (black)
            return css`
                color: ${theme.color.black};
            `;
        if (red)
            return css`
                color: ${theme.color.red};
            `;
        if (green)
            return css`
                color: ${theme.color.green};
            `;
        if (white)
            return css`
                color: ${theme.color.white};
            `;
        return css`
            color: ${theme.color.white};
        `;
    }};
    ${({
        theme,
        tiny,
        smallest,
        smaller,
        sml,
        small,
        big,
        bigger,
        biggest,
        huge,
        size,
    }: {
        theme: ThemeInterface;
        tiny: boolean;
        smallest: boolean;
        smaller: boolean;
        sml: boolean;
        small: boolean;
        big: boolean;
        bigger: boolean;
        biggest: boolean;
        huge: boolean;
        size: number;
    }) => {
        if (size)
            return css`
                font-size: ${size}px;
            `;
        if (tiny)
            return css`
                font-size: ${theme.text.tiny}px;
            `;
        if (smallest)
            return css`
                font-size: ${theme.text.smallest}px;
            `;
        if (smaller)
            return css`
                font-size: ${theme.text.smaller}px;
            `;
        if (sml)
            return css`
                font-size: ${theme.text.sml}px;
            `;
        if (small)
            return css`
                font-size: ${theme.text.small}px;
            `;
        if (big)
            return css`
                font-size: ${theme.text.big}px;
            `;
        if (bigger)
            return css`
                font-size: ${theme.text.bigger}px;
            `;
        if (biggest)
            return css`
                font-size: ${theme.text.biggest}px;
            `;
        if (huge)
            return css`
                font-size: ${theme.text.huge}px;
            `;
        return css`
            font-size: ${theme.text.medium}px;
        `;
    }};
`);

Text.displayName = "Text";
export const AnimatedText = Animated.createAnimatedComponent(Text);
AnimatedText.displayName = "AnimatedText";

"use client";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useTheme } from "styled-components/native";
import { ITooltip, Tooltip } from "../Tooltip";

import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import { useOutsideAlerter } from "@hooks/useOutsidePress";
import {
    forwardRef,
    memo,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    FlatList,
    ListRenderItem,
    Pressable,
    TextStyle,
    ViewStyle,
} from "react-native";
import { Statistic } from "../Statistic";
import {
    CurrencyInput,
    CurrencyInputOptions,
    CurrencyInputRef,
} from "./CurrencyInput";

export type Option = {
    id: string;
    label?: string;
    value?: string;
    image?: string;
};
export interface InputRef extends HTMLInputElement, CurrencyInputRef {}
export interface InputProps {
    type: "currency" | "textarea" | "text" | "select";
    placeholder?: string;
    value?: string | number;
    label?: string;
    labelRight?: string;
    labelRightStyle?: TextStyle;
    tooltip?: ITooltip;
    maxLength?: number;
    minLength?: number;
    readOnly?: boolean;
    inputOptions?: CurrencyInputOptions;
    defaultValue?: string | number;

    min?: number;
    max?: number;
    // Options
    currentOption?: Option | null;
    options?: Option[];

    onChange?: (value: number | null) => void;
    onFocus?: () => void;
    onBlur?: (value: number | string) => void;
    limitReset?: boolean;
    onChangeText?: (value: number | string) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    selectTextOnFocus?: boolean;
    style?: TextStyle;
    inputRight?: boolean;
    prefix?: string;
    name?: string;
    isActive?: boolean;
    isActiveColor?: string;
    extraLabel?: string;
    error?: string;
    containerStyle?: ViewStyle;
    extraLabelStyle?: TextStyle;
    children?: JSX.Element | JSX.Element[];
    renderLabelRight?: JSX.Element | JSX.Element[] | null;
    renderLabelLeft?: JSX.Element | JSX.Element[] | null;
    renderFocused?: JSX.Element | JSX.Element[] | null;
    renderRight?: JSX.Element | JSX.Element[] | null;
    toggleDropdown?: () => void;
    onOptionChange?: (item: Option) => void;
    renderOption?: (item: Option) => JSX.Element;
}
export const Input = memo(
    forwardRef<InputRef, InputProps>(
        (
            {
                value,
                label,
                labelRight,
                labelRightStyle,
                tooltip,
                readOnly,
                onChange,
                placeholder,
                name,
                min = 0,
                max,
                type,
                inputOptions,
                limitReset,
                disabled,
                autoFocus,
                style,
                maxLength,
                minLength,
                options,
                currentOption,
                children,
                inputRight,
                extraLabel,
                extraLabelStyle,
                prefix,
                isActive,
                isActiveColor,
                renderLabelRight,
                renderLabelLeft,
                renderFocused,
                containerStyle,
                error,
                defaultValue,
                selectTextOnFocus,
                renderRight,
                renderOption,
                onFocus,
                onBlur,
                onChangeText,
                onOptionChange,
                ...props
            },
            ref
        ) => {
            const theme = useTheme();

            const inputRef = useRef<HTMLInputElement>();
            const currencyInputRef = useRef<CurrencyInputRef>();
            const wrapperRef = useRef(null);

            const [isFocused, setIsFocused] = useState(false);
            const [isOpened, setIsOpened] = useState(false);
            const [isHovered, setIsHovered] = useState(false);

            const inputStyle = {
                lineHeight: "100%",
                fontFamily: theme.text.bold,
                fontSize: theme.text.big,
                fontWeight: 700,
                color: disabled
                    ? theme.color.rgba(theme.color.white, 0.4)
                    : theme.color.white,
                padding: 0,
                backgroundColor: "transparent",
                outline: "none",
                border: "transparent",
                textAlign: inputRight ? "right" : "left",
                marginBottom: inputRight ? 0 : -2,
                marginTop: inputRight ? -2 : 0,
            };

            // @ts-ignore
            useImperativeHandle(ref, () => ({
                ...(type === "text" || type === "textarea"
                    ? inputRef.current
                    : currencyInputRef.current),
            }));

            useOutsideAlerter(wrapperRef, () => {
                setIsOpened(false);
            });

            const toggleDropdown = () => {
                if (props?.toggleDropdown) props?.toggleDropdown();
                else setIsOpened(!isOpened);
            };

            const onFocusEvent = () => {
                setIsOpened(false);
                setIsFocused(true);
                if (onFocus) onFocus();
            };

            const onBlurEvent = (value: number) => {
                setIsFocused(false);
                if (onBlur) onBlur(value);
            };

            const onWrapperPress = () => {
                if (value === undefined) {
                    toggleDropdown();
                    return;
                }
                if (type === "text" || type === "textarea") {
                    inputRef?.current?.focus();
                    return;
                }
                currencyInputRef?.current?.input?.focus();
            };

            const renderOptionItem: ListRenderItem<Option> = useMemo(
                () => ({ item, index }) => {
                    return (
                        <InputOptionItem
                            onPress={() => {
                                if (onOptionChange) onOptionChange(item);
                                setIsOpened(false);
                            }}
                            isActive={item?.value === currentOption?.value}
                            isFirst={index === 0}
                            renderOption={renderOption}
                            item={item}
                        />
                    );
                },
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [currentOption, options]
            );

            return (
                <Box ref={wrapperRef}>
                    <Pressable
                        onPress={onWrapperPress}
                        style={{
                            padding: theme.spacing.small,
                            paddingVertical: theme.spacing.medium,
                            opacity: disabled ? 0.4 : 1,
                            zIndex: 10,
                            borderWidth: 1,
                            borderRadius: theme.radius.small,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                isFocused || isOpened || isHovered ? 0.1 : 0.05
                            ),
                            minHeight: inputRight ? "auto" : "auto",
                            borderColor: theme.color.rgba(
                                error
                                    ? theme.color.red
                                    : isActive
                                    ? isActiveColor || theme.color.white
                                    : theme.color.white,
                                error || isActive
                                    ? 1
                                    : isFocused || isOpened || isHovered
                                    ? 0.1
                                    : 0.05
                            ),
                            pointerEvents: disabled ? "none" : "auto",
                            ...containerStyle,
                        }}
                        // @ts-ignore
                        onMouseEnter={() => {
                            setIsHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false);
                        }}
                    >
                        <Box
                            style={{
                                position: "relative",
                                zIndex: 100,
                                flexDirection: inputRight ? "row" : "column",
                            }}
                        >
                            {label ||
                            labelRight ||
                            renderLabelLeft ||
                            inputRight ||
                            extraLabel ||
                            extraLabelStyle ? (
                                <Flex
                                    justify="space-between"
                                    style={{
                                        zIndex: 101,
                                        marginBottom: inputRight
                                            ? 0
                                            : theme.spacing.smaller,
                                    }}
                                >
                                    <Flex
                                        align="center"
                                        style={{
                                            gap: theme.spacing.tiny,
                                        }}
                                    >
                                        {tooltip ? (
                                            <Tooltip {...tooltip}>
                                                {renderLabelLeft || (
                                                    <Flex align="center">
                                                        <Text
                                                            selectable={false}
                                                            smaller
                                                            color={theme.color.rgba(
                                                                theme.color
                                                                    .white,
                                                                0.7
                                                            )}
                                                            style={{
                                                                marginRight:
                                                                    theme
                                                                        .spacing
                                                                        .tiny,
                                                            }}
                                                        >
                                                            {label}
                                                        </Text>

                                                        <Icon
                                                            name="question"
                                                            color={theme.color.rgba(
                                                                theme.color
                                                                    .white,
                                                                0.7
                                                            )}
                                                            size={10}
                                                        />
                                                    </Flex>
                                                )}
                                            </Tooltip>
                                        ) : (
                                            renderLabelLeft || (
                                                <Text
                                                    selectable={false}
                                                    smaller
                                                    color={theme.color.rgba(
                                                        theme.color.white,
                                                        0.7
                                                    )}
                                                >
                                                    {label}
                                                </Text>
                                            )
                                        )}

                                        {extraLabel ? (
                                            <>
                                                <Text
                                                    selectable={false}
                                                    smaller
                                                    lineHeight={
                                                        theme.text.smaller
                                                    }
                                                    style={extraLabelStyle}
                                                >
                                                    {extraLabel}
                                                </Text>
                                            </>
                                        ) : null}
                                    </Flex>

                                    <Box align="flex-end">
                                        {renderLabelRight || (
                                            <Text
                                                selectable={false}
                                                smaller
                                                color={theme.color.rgba(
                                                    theme.color.white,
                                                    0.7
                                                )}
                                                style={labelRightStyle}
                                            >
                                                {labelRight}
                                            </Text>
                                        )}
                                    </Box>
                                </Flex>
                            ) : null}

                            <Flex
                                align="center"
                                justify="space-between"
                                style={{ flex: 1 }}
                                gap={theme.spacing.tiny}
                            >
                                {prefix ? (
                                    <Box
                                        style={{
                                            marginBottom: -2.5,
                                            marginTop: -2.5,
                                        }}
                                    >
                                        <Text
                                            bold
                                            bigger
                                            lineHeight={theme.text.bigger}
                                            color={theme.color.rgba(
                                                theme.color.white,
                                                0.7
                                            )}
                                        >
                                            {prefix}
                                        </Text>
                                    </Box>
                                ) : null}
                                <Box
                                    flex={1}
                                    style={{
                                        marginBottom: -2.5,
                                        marginTop: -2.5,
                                    }}
                                >
                                    {value != null ? (
                                        type === "currency" ? (
                                            <CurrencyInput
                                                // @ts-ignore
                                                ref={currencyInputRef}
                                                maskOptions={inputOptions}
                                                value={value}
                                                name={name}
                                                onFocus={onFocusEvent}
                                                onBlur={onBlurEvent}
                                                placeholder={placeholder}
                                                type="text"
                                                limitReset={limitReset}
                                                min={min}
                                                max={max}
                                                defaultValue={defaultValue}
                                                autoFocus={autoFocus}
                                                onChange={onChange}
                                                disabled={readOnly || disabled}
                                                style={{
                                                    ...inputStyle,
                                                    ...style,
                                                }}
                                            />
                                        ) : (
                                            <>
                                                {type === "textarea" ? (
                                                    <textarea
                                                        // @ts-ignore
                                                        ref={ref}
                                                        name={name}
                                                        min={min}
                                                        max={max}
                                                        minLength={minLength}
                                                        maxLength={maxLength}
                                                        type={type}
                                                        pattern={`^\d+\.{0,1}\d{0,2}$"`}
                                                        value={value}
                                                        disabled={readOnly}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        // @ts-ignore
                                                        style={{
                                                            ...inputStyle,
                                                            ...style,
                                                        }}
                                                        onFocus={onFocus}
                                                        onBlur={(e) =>
                                                            onBlur &&
                                                            onBlur(
                                                                e.target.value
                                                            )
                                                        }
                                                        onChange={(e) =>
                                                            onChangeText &&
                                                            onChangeText(
                                                                e?.target?.value
                                                            )
                                                        }
                                                    />
                                                ) : type === "text" ? (
                                                    // @ts-ignore
                                                    <input
                                                        // @ts-ignore
                                                        ref={ref}
                                                        name={name}
                                                        min={min}
                                                        max={max}
                                                        type={type}
                                                        pattern={`^\d+\.{0,1}\d{0,2}$"`}
                                                        value={value}
                                                        disabled={readOnly}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        // @ts-ignore
                                                        style={{
                                                            ...inputStyle,
                                                            ...style,
                                                        }}
                                                        onBlur={(e) =>
                                                            onBlur &&
                                                            onBlur(
                                                                e.target.value
                                                            )
                                                        }
                                                        onFocus={onFocus}
                                                        onChange={(e) =>
                                                            onChangeText &&
                                                            onChangeText(
                                                                e.target.value
                                                            )
                                                        }
                                                        autoFocus={autoFocus}
                                                        selectTextOnFocus={
                                                            selectTextOnFocus
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        )
                                    ) : null}
                                </Box>

                                {renderRight ? renderRight : null}
                                {currentOption ? (
                                    <Pressable
                                        onPress={() => {
                                            if (options?.length)
                                                toggleDropdown();
                                            else {
                                                if (
                                                    type === "text" ||
                                                    type === "textarea"
                                                )
                                                    inputRef?.current?.focus();
                                                else
                                                    currencyInputRef?.current?.input?.focus();
                                            }
                                        }}
                                    >
                                        <Flex align="center">
                                            {currentOption?.image ? (
                                                <>
                                                    <Image
                                                        lazy
                                                        alt=""
                                                        source={
                                                            currentOption.image
                                                        }
                                                        width={16}
                                                        height={16}
                                                        selectable={false}
                                                    />
                                                </>
                                            ) : null}
                                            <Text
                                                selectable={false}
                                                bold
                                                big
                                                style={{
                                                    lineHeight:
                                                        theme.text.medium,
                                                    marginLeft:
                                                        theme.spacing.smallest,
                                                }}
                                            >
                                                {currentOption?.value}
                                            </Text>
                                            {options?.length ? (
                                                <Box
                                                    style={{
                                                        marginLeft:
                                                            theme.spacing
                                                                .smallest,
                                                    }}
                                                >
                                                    <Icon
                                                        name="caret"
                                                        size={8}
                                                        rotate={
                                                            isOpened ? -90 : 90
                                                        }
                                                    />
                                                </Box>
                                            ) : null}
                                        </Flex>
                                    </Pressable>
                                ) : null}
                            </Flex>
                        </Box>
                        <Box
                            style={{
                                marginTop: 0,
                            }}
                        >
                            {children}
                        </Box>
                    </Pressable>

                    {error ? (
                        <Box
                            style={{
                                backgroundColor: theme.color.red,
                                padding: theme.spacing.smaller,
                                marginBottom: theme.spacing.smaller,
                                borderBottomLeftRadius: theme.radius.medium,
                                borderBottomRightRadius: theme.radius.medium,
                            }}
                        >
                            <Text smallest>{error}</Text>
                        </Box>
                    ) : null}

                    {renderFocused && isFocused ? (
                        <Box
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                right: 0,
                                marginTop: -10,
                                backgroundColor: theme.color.black,
                                borderBottomLeftRadius: theme.radius.medium,
                                borderBottomRightRadius: theme.radius.medium,
                                zIndex: 10,
                            }}
                        >
                            <Box
                                style={{
                                    borderBottomLeftRadius: theme.radius.medium,
                                    borderBottomRightRadius:
                                        theme.radius.medium,
                                    backgroundColor: theme.color.rgba(
                                        theme.color.white,
                                        0.05
                                    ),
                                }}
                            >
                                <Box
                                    style={{
                                        borderBottomLeftRadius:
                                            theme.radius.medium,
                                        borderBottomRightRadius:
                                            theme.radius.medium,

                                        paddingTop: theme.spacing.smaller,
                                        borderWidth: 1,
                                        borderColor: theme.color.rgba(
                                            theme.color.white,
                                            0.1
                                        ),
                                        backgroundColor: theme.color.rgba(
                                            theme.color.white,
                                            0.1
                                        ),
                                        borderTopWidth: 0,
                                    }}
                                >
                                    <Box
                                        style={{
                                            borderTopWidth: 1,
                                            borderColor: theme.color.rgba(
                                                theme.color.white,
                                                0.1
                                            ),
                                            backgroundColor: theme.color.rgba(
                                                theme.color.white,
                                                0.05
                                            ),
                                            padding: theme.spacing.small,
                                        }}
                                    >
                                        {renderFocused}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ) : null}

                    {isOpened ? (
                        <Box
                            style={{
                                borderBottomLeftRadius: theme.radius.medium,
                                borderBottomRightRadius: theme.radius.medium,
                                zIndex: 10,
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: "100%",
                                marginTop: -10,
                                backgroundColor: theme.color.black,
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: theme.color.rgba(
                                        theme.color.white,
                                        0.05
                                    ),
                                    borderBottomLeftRadius: theme.radius.medium,
                                    borderBottomRightRadius:
                                        theme.radius.medium,
                                    overflow: "hidden",
                                    borderWidth: 1,
                                    borderTopWidth: 0,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.2
                                    ),
                                }}
                            >
                                <Box
                                    style={{
                                        backgroundColor: theme.color.rgba(
                                            theme.color.white,
                                            0.1
                                        ),
                                    }}
                                >
                                    <FlatList
                                        data={options}
                                        renderItem={renderOptionItem}
                                        style={{
                                            paddingTop: theme.spacing.smaller,
                                            maxHeight: 172,
                                        }}
                                        contentContainerStyle={{
                                            borderTopWidth: 1,
                                            borderColor: theme.color.rgba(
                                                theme.color.white,
                                                0.1
                                            ),
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            );
        }
    )
);

const InputOptionItem = memo(
    ({
        item,
        isActive,
        isFirst,
        isLast,
        onPress,
        renderOption,
    }: {
        onPress: () => void;
        item: Option;
        isActive: boolean;
        isFirst?: boolean;
        isLast?: boolean;
        renderOption?: (item: Option) => JSX.Element;
    }) => {
        const theme = useTheme();

        const [isHovered, setIsHovered] = useState(false);

        return (
            <Pressable onPress={() => (!isActive ? onPress() : null)}>
                <Box
                    onMouseEnter={() => {
                        setIsHovered(true);
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false);
                    }}
                >
                    <Flex
                        align="center"
                        style={{
                            // opacity: isActive ? 0.4 : isHovered ? 0.4 : 1,

                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                isActive || isHovered ? 0.05 : 0
                            ),
                            borderTopWidth: isFirst ? 0 : 1,
                            borderColor: theme.color.rgba(
                                theme.color.white,
                                isFirst ? 0 : 0.05
                            ),
                            paddingHorizontal: theme.spacing.small,
                            paddingVertical: theme.spacing.small,
                        }}
                    >
                        {renderOption ? (
                            <Flex flex={1}>{renderOption(item)}</Flex>
                        ) : (
                            <>
                                {item?.image ? (
                                    <Box
                                        style={{
                                            marginRight: theme.spacing.big,
                                        }}
                                    >
                                        <Image
                                            lazy
                                            alt=""
                                            source={item.image}
                                            width={24}
                                            height={24}
                                            selectable={false}
                                        />
                                    </Box>
                                ) : null}

                                <Statistic
                                    small
                                    selectable={false}
                                    label={item.label}
                                    value={item.value}
                                />
                            </>
                        )}
                    </Flex>
                </Box>
            </Pressable>
        );
    }
);

import type { SelectOptionType } from "@molecules/Select";
import type { IMark } from "@molecules/Slider";

export enum AssetClass {
    Crypto = "Crypto",
    Commodity = "Commodity",
    Forex = "Forex",
    Indices = "Index",
    Stocks = "Stocks",
}

export enum Operation {
    All = "All",
    Cancelled = "Cancelled",
    Open = "Open",
    Close = "Close",
    Liquidation = "Liquidation",
    TakeProfit = "TakeProfit",
    StopLoss = "StopLoss",
}

export enum Timeframe {
    Hourly = "Hourly",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly",
    All = "All",
}

export enum PositionTypes {
    Long = "Long",
    Short = "Short",
}

export enum PositionOrderTypes {
    Market = "Market",
    Limit = "Limit",
    Stop = "Stop",
}

type RangeType = {
    time: "1H" | "1D" | "1W" | "1M";
    text: string;
};

export const POSITION_TYPES: PositionTypes[] = [
    PositionTypes.Long,
    PositionTypes.Short,
];

export const ORDER_TYPES: PositionOrderTypes[] = [
    PositionOrderTypes.Market,
    PositionOrderTypes.Limit,
    PositionOrderTypes.Stop,
];

export const RANGES: RangeType[] = [
    {
        time: "1H",
        text: "1h",
    },
    {
        time: "1D",
        text: "1d",
    },
    {
        time: "1M",
        text: "1mo",
    },
];

export const CANDLE_RANGES: RangeType[] = [
    {
        time: "1H",
        text: "5s",
    },
    {
        time: "1D",
        text: "1m",
    },
    {
        time: "1M",
        text: "1h",
    },
];

export const TIMEFRAME = [
    {
        text: "Last hour",
        value: Timeframe.Hourly,
    },
    {
        text: "Last day",
        value: Timeframe.Daily,
    },
    {
        text: "Last week",
        value: Timeframe.Weekly,
    },
    {
        text: "Alltime",
        value: Timeframe.All,
    },
];

export type TimeframeType = {
    id: Timeframe;
    text: string;
    long: string;
    period: number;
};

export const TIMEFRAMES: SelectOptionType[] = [
    {
        value: Timeframe.Hourly,
        text: "1 hour",
        // period: 60,
    },
    {
        value: Timeframe.Daily,
        text: "1 day",
        // period: 24,
    },
    {
        value: Timeframe.Weekly,
        text: "1 week",
        // period: 7,
    },
    {
        value: Timeframe.Monthly,
        text: "1 month",
        // period: 31,
    },
    {
        value: Timeframe.All,
        text: "All time",
        // period: 90,
    },
];

export const TAKE_PROFIT_MARKS: IMark[] = [
    {
        value: 25,
        text: "25%",
    },
    {
        value: 50,
        text: "50%",
    },
    {
        value: 75,
        text: "75%",
    },
    {
        value: 100,
        text: "100%",
    },
    {
        value: 500,
        text: "500%",
    },
    {
        value: 900,
        text: "900%",
    },
];

export const STOP_LOSS_MARKS: IMark[] = [
    {
        value: 0,
        text: "None",
    },
    {
        value: -5,
        text: "-5%",
    },
    {
        value: -10,
        text: "-10%",
    },
    {
        value: -25,
        text: "-25%",
    },
    {
        value: -50,
        text: "-50%",
    },
    {
        value: -85,
        text: "-85%",
    },
];

export function getAssetClassDecimals(assetClass: string) {
    switch (assetClass) {
        case AssetClass.Commodity:
            return 2;
        case AssetClass.Crypto:
            return 4;
        case AssetClass.Forex:
            return 4;
        case AssetClass.Indices:
            return 2;
        case AssetClass.Stocks:
            return 2;
        default:
            return 2;
    }
}

export const LEVERAGE_MARKS: { [key: string]: IMark[] } = {
    tinyiest: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "2.5x",
            value: 2.5,
        },
        {
            text: "5x",
            value: 5,
        },
        {
            text: "7.5x",
            value: 7.5,
        },
        {
            text: "10x",
            value: 10,
        },
    ],
    tiny: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "5x",
            value: 5,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "15x",
            value: 15,
        },
        {
            text: "20x",
            value: 20,
        },
    ],
    smaller: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "20x",
            value: 20,
        },
        {
            text: "30x",
            value: 30,
        },
        {
            text: "40x",
            value: 40,
        },
        {
            text: "50x",
            value: 50,
        },
    ],
    small: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "25x",
            value: 25,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "75x",
            value: 75,
        },
        {
            text: "100x",
            value: 100,
        },
    ],
    medium: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "25x",
            value: 25,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "125x",
            value: 125,
        },
        {
            text: "150x",
            value: 150,
        },
    ],
    big: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "150x",
            value: 150,
        },
        {
            text: "200x",
            value: 200,
        },
        {
            text: "250x",
            value: 250,
        },
    ],
    biggest: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "150x",
            value: 150,
        },
        {
            text: "250x",
            value: 250,
        },
        {
            text: "500x",
            value: 500,
        },
    ],
    huge: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "250x",
            value: 250,
        },
        {
            text: "500x",
            value: 500,
        },
        {
            text: "750x",
            value: 750,
        },
        {
            text: "1000x",
            value: 1000,
        },
    ],
};

import { useAppContext } from "@contexts/app";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { usePrivy } from "@privy-io/react-auth";

import { useAccount } from "wagmi";

export function useOstiumAccount() {
    const { isOneClickTradingEnabled } = useAppContext();
    const { address: wagmiAddress, connector } = useAccount();
    const { user, authenticated } = usePrivy();
    const { smartAccountAddress, embeddedWalletAddress } =
        usePrivySmartWalletContext();

    const wagmiAddressAfterCheck =
        connector && connector.id !== "io.privy.wallet" && authenticated
            ? wagmiAddress
            : undefined;

    const address =
        user && (user.google?.email || user?.email?.address)
            ? smartAccountAddress
            : wagmiAddressAfterCheck;

    return {
        address: authenticated
            ? (address?.toLowerCase() as `0x${string}` | undefined)
            : undefined,
        smartAccountAddress,
        wagmiAddress: wagmiAddressAfterCheck,
        embeddedWalletAddress,
        isSocialLogin: user && (user?.google?.email || user?.email?.address),
    };
}

import { useMemo } from 'react';

interface WeekProgressInfo {
    weekStart: number;
    weekEnd: number;
    weekProgressPercentage: number;
    startDate: string;
    endDate: string;
}

/**
 * Hook to calculate week progress information for progress bars
 * @param startTime - The unix timestamp for the start of the week/period
 * @param endTime - The unix timestamp for the end of the week/period
 * @returns An object with week progress information
 */
export const useWeekProgress = (
    startTime?: number,
    endTime?: number
): WeekProgressInfo => {
    return useMemo(() => {
        // Normalize timestamps to start of day in UTC
        const normalizeToUTCDay = (timestamp: number) => {
            const date = new Date(timestamp * 1000);
            date.setUTCHours(0, 0, 0, 0);
            return Math.floor(date.getTime() / 1000);
        };

        if (!startTime || !endTime) {
            return {
                weekStart: 0,
                weekEnd: 0,
                weekProgressPercentage: 0,
                startDate: "",
                endDate: "",
            };
        }

        const currentTime = Math.floor(Date.now() / 1000);
        const weekStart = normalizeToUTCDay(startTime);
        const weekEnd = normalizeToUTCDay(endTime);
        const normalizedCurrentTime = normalizeToUTCDay(currentTime);

        // Calculate progress percentage based on days
        const totalDays = Math.ceil((weekEnd - weekStart) / (24 * 60 * 60));
        const elapsedDays = Math.ceil((normalizedCurrentTime - weekStart) / (24 * 60 * 60));
        
        // If same day or invalid period, return 100%
        const weekProgressPercentage = totalDays <= 0 ? 100 : Math.min(
            Math.round((elapsedDays / totalDays) * 100),
            100
        );

        // Format dates for display
        const startDate = new Date(weekStart * 1000).toLocaleDateString("en-US", {
            timeZone: 'UTC',
            month: "long",
            day: "numeric",
            // To be removed once we finish testing
            // year: "numeric",
            // hour: "numeric",
            // minute: "numeric",
        });

        const endDate = new Date(weekEnd * 1000).toLocaleDateString("en-US", {
            timeZone: 'UTC',
            month: "long",
            day: "numeric",
            // To be removed once we finish testing
            // year: "numeric",
            // hour: "numeric",
            // minute: "numeric",
        });

        return {
            weekStart,
            weekEnd,
            weekProgressPercentage,
            startDate,
            endDate,
        };
    }, [startTime, endTime]);
};
import { getPairKey } from '@contexts/price';
import { useQuery, gql } from "@hooks/useApollo";
import { Pair, type PriceHistorical } from "gql/graphql";
import { useMemo, useRef } from "react";

export interface IPerformance {
    id: string;
    from: string;
    to: string;
    price24hAgo: number;
    highest: number;
    lowest: number;
    historical?: PriceHistorical[];
}

export function usePerformance(
    pairs: Pair[] | null,
    chainId: number,
    initialPerformances?: IPerformance[]
) {
    const initial = useMemo(() => {
        const newPerformance = new Map<`${string}-${string}`, IPerformance>();

        initialPerformances?.map((result: any) => {
            newPerformance.set(getPairKey(result), {
                id: result.id,
                from: result.from,
                to: result.to,
                price24hAgo: result.price24hAgo,
                highest: result.highest,
                lowest: result.lowest,
            });
        });

        return newPerformance;
    }, [initialPerformances]);

    const performances = useRef<Map<string, IPerformance> | null>(initial);

    const { loading } = useQuery<{ performances: IPerformance[] }>(
        GET_PERFORMANCES_24HR,
        {
            variables: {
                chainId,
            },
            skip: pairs == null,
            pollInterval: 10000,
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
                const newPerformances = new Map<`${string}-${string}`, IPerformance>();

                data.performances.map((result: any) => {
                    newPerformances.set(getPairKey(result), {
                        id: result.id,
                        from: result.from,
                        to: result.to,
                        price24hAgo: result.price24hAgo,
                        highest: result.highest,
                        lowest: result.lowest,
                    });
                });

                performances.current = newPerformances;
            },
        });

    return { performances: performances.current, loading };
}

const GET_PERFORMANCES_24HR = gql`
    query performances($chainId: Float!) {
        performances(chainId: $chainId) {
            id
            from
            to
            price24hAgo
            highest
            lowest
        }
    }
`;


import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { capitalizeFirstLetter } from "@utils";
import { memo, useState } from "react";
import { Pressable, ViewStyle, type TextStyle } from "react-native";
import { useTheme } from "styled-components/native";

export type ITab = {
    id: string;
    text: string;
    green?: boolean;
    red?: boolean;
    isActive?: boolean;
    count?: number;
};

interface ITabBar {
    tabs: ITab[];
    small?: boolean;
    onChange: (item: ITab) => void;
    borderRadius?: number;
    style?: ViewStyle;
    tabStyle?: ViewStyle;
}

export const TabBar = memo(
    ({ tabs, small, borderRadius, style, tabStyle, onChange }: ITabBar) => {
        const [isHovered, setIsHovered] = useState(false);
        const theme = useTheme();
        return (
            <Flex
                style={{
                    borderRadius: borderRadius ?? theme.radius.small,
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovered ? 0.1 : 0.05
                    ),
                    borderWidth: 1,
                    borderColor: theme.color.rgba(theme.color.white, 0.05),
                    height: small ? 28 : 36,
                    ...style,
                }}
                onMouseEnter={() => {
                    setIsHovered(true);
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                }}
            >
                {tabs.map((item, index) => {
                    const isFirst = index === 0;
                    const isLast = index === tabs.length - 1;
                    return (
                        <TabBarItem
                            small={small}
                            key={`tab-bar-item-${item.id}-${index}`}
                            item={item}
                            isFirst={isFirst}
                            isLast={isLast}
                            onPress={() => onChange(item)}
                            style={tabStyle}
                            tabFlex={1}
                            // borderRadius={borderRadius}
                        />
                    );
                })}
            </Flex>
        );
    }
);

export const TabBarItem = memo(
    ({
        item,
        small,
        isFirst,
        isLast,
        onPress,
        style,
        borderRadius,
        textStyle,
        tabFlex,
    }: {
        item: ITab;
        small?: boolean;
        isFirst: boolean;
        isLast: boolean;
        onPress: () => void;
        borderRadius?: number;
        style?: ViewStyle;
        textStyle?: TextStyle;
        tabFlex?: number | undefined;
    }) => {
        const theme = useTheme();

        const [isHovered, setIsHovered] = useState(false);

        return (
            <Pressable onPress={onPress} style={{ flex: tabFlex }}>
                <Box
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    align="center"
                    justify="center"
                    style={{
                        paddingHorizontal: small
                            ? theme.spacing.smallest
                            : theme.spacing.small,
                        borderTopRightRadius:
                            borderRadius ?? theme.radius.small,
                        borderBottomRightRadius:
                            borderRadius ?? theme.radius.small,
                        borderBottomLeftRadius:
                            borderRadius ?? theme.radius.small,
                        borderTopLeftRadius: borderRadius ?? theme.radius.small,
                        height: "100%",
                        borderColor: "transparent",
                        backgroundColor: theme.color.rgba(
                            item.red
                                ? theme.color.red
                                : item.green
                                ? theme.color.green
                                : theme.color.white,
                            item.isActive
                                ? 0.2
                                : item.red || item.green
                                ? 0.1
                                : isHovered
                                ? 0.05
                                : 0
                        ),
                        ...style,
                    }}
                >
                    <Text
                        semiBold
                        tiny={small}
                        smaller={!small}
                        selectable={false}
                        color={
                            item.red
                                ? theme.color.red
                                : item.green
                                ? theme.color.green
                                : theme.color.white
                        }
                        style={{
                            ...textStyle,
                        }}
                    >
                        {capitalizeFirstLetter(item.text)}
                    </Text>

                    {item?.count ? (
                        <Text
                            tiny={small}
                            color={
                                item.red
                                    ? theme.color.red
                                    : item.green
                                    ? theme.color.green
                                    : theme.color.white
                            }
                        >
                            {item.count}
                        </Text>
                    ) : null}
                </Box>
            </Pressable>
        );
    }
);

import { Box, Flex } from "@atoms/Flex";
import { Image } from "@atoms/Image";
import { InfinityLoader } from "@atoms/LoadingIndicator";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import useAnalytics from "@hooks/useAnalytics";
import { Button } from "@molecules/Button";
import Modal from "@organisms/Modal";
import { memo, useEffect, useMemo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { Connector, useConnect } from "wagmi";

export const ConnectModal = memo(() => {
    const {
        connectors,
        connectAsync,
        isPending,
        error,
        isError,
        reset,
    } = useConnect();
    const { address } = useOstiumAccount();
    const { identifyUser } = useAnalytics();
    const { showConnectModal, toggleConnectModal } = useAppContext();
    const theme = useTheme();

    const [
        connectingToConnector,
        setConnectingToConnector,
    ] = useState<null | Connector>(null);

    useEffect(() => {
        if (address) {
            identifyUser(address);
        }
    }, [address, identifyUser]);

    useEffect(() => {
        if (!showConnectModal) {
            reset();
        }
    }, [reset, showConnectModal]);

    const parsedError = useMemo(() => {
        // @ts-ignore
        switch (error?.code) {
            case 4001:
                return "User rejected the request";
            case -32002:
                // Default: "Request of type 'wallet_requestPermissions' already pending for origin http://localhost:3000. Please wait."
                return "Wallet provider already open in another window.";

            default:
                return error?.message;
        }
    }, [error]);

    return (
        <Modal
            title="Connect"
            isVisible={showConnectModal}
            onClose={toggleConnectModal}
        >
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    paddingTop: 0,
                }}
            >
                {isPending || isError ? (
                    <Box>
                        <Box align="center">
                            {connectingToConnector ? (
                                <Box gap={theme.spacing.smaller} align="center">
                                    <Box
                                        style={{
                                            paddingVertical:
                                                theme.spacing.bigger,
                                        }}
                                    >
                                        <WalletIcon
                                            big
                                            connector={connectingToConnector}
                                        />
                                    </Box>
                                    <Text
                                        big
                                        semiBold
                                    >{`Continue in ${connectingToConnector?.name}`}</Text>
                                    <Text
                                        small
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        Accept connection request in the wallet
                                    </Text>
                                </Box>
                            ) : null}

                            <Box
                                style={{
                                    marginVertical: theme.spacing.small,
                                    minHeight: 40,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {!!error ? (
                                    <Text
                                        red
                                        small
                                        semiBold
                                        lineHeight={theme.text.big}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {parsedError}
                                    </Text>
                                ) : (
                                    <InfinityLoader small />
                                )}
                            </Box>
                        </Box>
                        <Flex gap={theme.spacing.smaller}>
                            <Button
                                // primary
                                stroke
                                noBorder
                                onPress={reset}
                                text="Change Wallet"
                                style={{
                                    flex: 1,
                                }}
                            />
                            <Button
                                primary
                                style={{
                                    flex: 1,
                                }}
                                noBorder
                                stroke
                                onPress={async () => {
                                    try {
                                        await connectAsync({
                                            connector: connectingToConnector as Connector,
                                        });
                                    } catch (err) {
                                        // setError(err?.message as string);
                                    }
                                }}
                                text="Try again"
                            />
                        </Flex>
                    </Box>
                ) : (
                    <Box gap={theme.spacing.smaller}>
                        {connectors.map((connector) => {
                            const onPress = async () => {
                                try {
                                    setConnectingToConnector(connector);
                                    await connectAsync({ connector });
                                    reset();
                                    toggleConnectModal();
                                } catch (err) {
                                    console.warn(err);
                                }
                            };

                            return (
                                <Wallet
                                    key={connector.uid}
                                    connector={connector}
                                    onPress={onPress}
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Modal>
    );
});

export const WalletIcon = ({
    connector,
    big,
}: {
    connector?: Connector;
    big?: boolean;
}) => {
    const size = big ? 56 : 32;

    switch (connector?.id) {
        case "walletConnect":
            return (
                <Image
                    lazy
                    alt={connector.name}
                    source={"https://static.ostium.io/assets/walletconnect.svg"}
                    width={size}
                    height={size}
                />
            );
        case "safe":
            return (
                <Flex
                    style={{
                        flexWrap: "wrap",
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Image
                        lazy
                        alt={connector.name}
                        source={"assets/metamask.svg"}
                        width={14}
                        height={14}
                    />
                    <Image
                        lazy
                        alt={connector.name}
                        source={"assets/brave.svg"}
                        width={14}
                        height={14}
                    />
                    <Image
                        lazy
                        alt={connector.name}
                        source={"assets/rabby.svg"}
                        width={14}
                        height={14}
                    />
                    <Image
                        lazy
                        alt={connector.name}
                        source={"assets/coinbase.svg"}
                        width={14}
                        height={14}
                    />
                </Flex>
            );
        default:
            return (
                <Image
                    lazy
                    alt={connector?.name}
                    source={connector?.icon}
                    width={size}
                    height={size}
                />
            );
    }
};

const Wallet = memo(
    ({ connector, onPress }: { connector: Connector; onPress: () => void }) => {
        const theme = useTheme();
        const [isHovered, setIsHovered] = useState(false);

        if (connector.id === "safe") return null;

        return (
            <Box
                onMouseEnter={() => {
                    setIsHovered(true);
                }}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Pressable
                    onPress={onPress}
                    style={{
                        padding: theme.spacing.big,
                        flexDirection: "row",
                        alignItems: "center",
                        gap: theme.spacing.big,
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            isHovered ? 0.1 : 0.05
                        ),
                        borderRadius: theme.radius.medium,
                        // @ts-ignore
                        transition: "background 0.1s linear",
                        willChange: "background",
                    }}
                >
                    <Box
                        style={{
                            width: 32,
                            height: 32,
                        }}
                    >
                        <WalletIcon connector={connector} />
                    </Box>
                    <Text semiBold>
                        {connector.id === "safe"
                            ? "Browser Wallet"
                            : connector.name}
                    </Text>
                </Pressable>
            </Box>
        );
    }
);

import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { Image } from "@atoms/Image";
import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import {
    NAVIGATION_MORE_LINKS,
    Navigation,
    NavigationLinkType,
    NavigationMobile,
} from "@organisms/Header/Navigation";
import { UserSettings } from "@screens/components/Header/User";
import { useRouting } from "expo-next-react-navigation";
import { memo, useCallback, useEffect, useState } from "react";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { useDisconnect } from "wagmi";
import { BlurView } from "@atoms/BlurView";
import { Portal } from "@atoms/Portal";
import { NavigationLink } from "@organisms/Header/Link";
import { Pressable } from "react-native";
import { NewVersionButton } from "./Buttons/NewVersionButton";
import { Socials } from "./Socials";
import { useAppContext } from "@contexts/app";
import Modal from "@organisms/Modal";
import { Referrals } from "@screens/Referrals";
import { AppAlert } from "@screens/Trade/components/Banners/AppAlert";
import { usePrivy } from "@privy-io/react-auth";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { WithdrawModal } from "./User/Sidebar/WithdrawModal";
import { LinearGradient } from "expo-linear-gradient";
import { useWeb3Context } from "@contexts/web3";
import { useAllowance } from "@contexts/hooks/useAllowance";
import Enable1CTModal from "./User/Enable1CTModal";
import { formatNumberWithSuffix, getBaseS3URL } from "@utils";
import { Tooltip } from "@molecules/Tooltip";
import { useDepositArbitrumUsdc } from "@contexts/hooks/useDepositArbitrumUsdc";
import { FunkitProvider } from "@funkit/connect";
import { FUNKIT_CONFIG, FUNKIT_THEME } from "constants/funkit";
import "@funkit/connect/styles.css";
import { usePointsByUserId } from "@screens/Points/hooks/usePoints";
import styled from "styled-components";

export const HEADER_HEIGHT = 60;
export const CTA_HEIGHT = 35;

const Header = memo(({ simple }: { simple?: boolean }) => {
    return (
        <FunkitProvider funkitConfig={FUNKIT_CONFIG} theme={FUNKIT_THEME}>
            <HeaderInner simple={simple} />
        </FunkitProvider>
    );
});

const HeaderInner = memo(({ simple }: { simple?: boolean }) => {
    const media = useMedia();
    const theme = useTheme();
    const { navigate } = useRouting();
    const { address, smartAccountAddress, isSocialLogin } = useOstiumAccount();
    const { disconnect } = useDisconnect();
    const { user } = usePrivy();
    const startDeposit = useDepositArbitrumUsdc();
    const [isReferralModalVisible, setReferralModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scStatusLoading, setScStatusLoading] = useState(true);
    const {
        isOneClickTradingEnabled,
        toggleSmartAccount,
        setIsOneClickTradingEnabled,
        setIsWithdrawModalVisible,
        isWithdrawModalVisible,
        isAAv2ModalVisible,
        setIsAAv2ModalVisible,
        isSCBannerModalVisible,
        setIsSCBannerModalVisible,
        setShowedLaunchBanners,
        showedLaunchBanners,
        setShowEnableCTModal,
        showEnableCTModal,
    } = useAppContext();
    const { logoutSmartAccount, sendSmartWalletOrder, embeddedWalletAddress } =
        usePrivySmartWalletContext();
    const { tradingContract, currentChain, provider } = useWeb3Context();
    const toggleReferralModal = useCallback(() => {
        setReferralModalVisible(!isReferralModalVisible);
    }, [isReferralModalVisible]);
    const { checkAllowance, getAllowance, updateAllowance } = useAllowance(
        currentChain.contracts.storage
    );

    const openCompetition = () => {
        navigate({
            routeName: "Contest",
            web: {
                as: "/contest",
                pathname: "/contest",
            },
        });
    };

    const onDisconnect = () => {
        disconnect();
        logoutSmartAccount();
    };

    const { isMobile } = useMedia();

    const checkSmartAccountStatus = useCallback(async () => {
        const delegation = await tradingContract.delegations(address);

        if (delegation.toLowerCase() !== smartAccountAddress?.toLowerCase()) {
            setIsOneClickTradingEnabled(false);
            setScStatusLoading(false);
            return;
        }

        const currentAllowance = await getAllowance();
        if (!currentAllowance || currentAllowance < 100000) {
            setScStatusLoading(false);
            setIsOneClickTradingEnabled(false);
            return;
        }

        setScStatusLoading(false);
        setIsOneClickTradingEnabled(true);
    }, [
        address,
        tradingContract,
        smartAccountAddress,
        setIsOneClickTradingEnabled,
        getAllowance,
    ]);

    useEffect(() => {
        if (
            address &&
            tradingContract &&
            embeddedWalletAddress &&
            address?.toLowerCase() !== embeddedWalletAddress?.toLowerCase() &&
            !isSocialLogin
        ) {
            checkSmartAccountStatus();
        }
    }, [
        address,
        tradingContract,
        smartAccountAddress,

        setIsOneClickTradingEnabled,
        checkSmartAccountStatus,
        isSocialLogin,
        embeddedWalletAddress,
    ]);

    const handleEnableSmartAccount = useCallback(async () => {
        try {
            setLoading(true);
            setShowEnableCTModal({
                visible: true,
                delegationStep: 1,
                allowanceStep: 0,
            });
            const delegation = await tradingContract.delegations(address);

            if (
                delegation.toLowerCase() !== smartAccountAddress?.toLowerCase()
            ) {
                const tx = await tradingContract?.setDelegate(
                    smartAccountAddress
                );
                //@ts-ignore
                await provider.waitForTransaction(tx.hash, 1);
            }
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 0,
            });
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 1,
            });
            const currentAllowance = await getAllowance();

            if (true) {
                await checkAllowance(10000000);

                updateAllowance();
            }
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 2,
            });
            toggleSmartAccount();
            setLoading(false);
        } catch (e) {
            console.log("error", e);
            setShowEnableCTModal({
                visible: false,
                delegationStep: -1,
                allowanceStep: -1,
            });
            setLoading(false);
        }
    }, [
        smartAccountAddress,
        tradingContract,
        address,
        checkAllowance,
        getAllowance,
        toggleSmartAccount,
        updateAllowance,
        provider,

        setShowEnableCTModal,
    ]);

    const { points: userPointsData } = usePointsByUserId(address || "");

    const points = userPointsData?.totalPoints
        ? Number(userPointsData.totalPoints)
        : 0;

    const preSeasonPoints = userPointsData?.preSeasonPoints
        ? Number(userPointsData.preSeasonPoints)
        : 0;

    const totalPoints = points + preSeasonPoints;

    const PointsBadge = styled.span`
        background-color: #8b4513;
        color: white;
        border-radius: 20px;
        padding: 2px 10px;
        font-size: 0.9em;
        margin-left: 5px;
    `;

    const NAVIGATION_LINKS: NavigationLinkType[] = [
        {
            label: "Trade",
            url: "/trade",
        },
        {
            label: "Explore",
            url: "/explore",
        },
        {
            label: "Strategies",
            url: "/strategies",
        },
        {
            label: (
                <>
                    Points
                    {
                        <PointsBadge>
                            {totalPoints
                                ? formatNumberWithSuffix(totalPoints)
                                : "New"}
                        </PointsBadge>
                    }
                </>
            ),
            url: "/points",
        },
        {
            label: "Vault",
            url: "/vault",
        },
        {
            label: "Leaderboard",
            url: "/leaderboard",
        },
        {
            label: "Portfolio",
            url: "/portfolio",
        },
        {
            label: "More",
            items: NAVIGATION_MORE_LINKS,
        },
    ];

    return (
        // <Portal>
        <Box
            style={{
                position: "fixed",
                left: 0,
                right: 0,
                zIndex: 1,
                top: 0,
            }}
            align="center"
        >
            <Box
                style={{
                    width: "100%",
                    zIndex: 10,
                }}
            >
                <BlurView
                    intensity={1000}
                    style={{
                        zIndex: 100,
                        backgroundColor: theme.color.background,
                    }}
                >
                    <AppAlert />
                    {/* <SmartWalletAlert /> */}
                    <Flex
                        align="center"
                        justify="space-between"
                        style={{
                            paddingLeft: media.isDesktopSmall
                                ? 0
                                : theme.spacing.big,
                            paddingRight: media.isDesktopSmall
                                ? 0
                                : theme.spacing.smaller,

                            height: HEADER_HEIGHT,
                        }}
                    >
                        <Flex flex={1} style={{ height: "100%" }}>
                            <Link
                                href="/"
                                suppressHydrationWarning
                                prefetch={false}
                            >
                                <Box
                                    style={{
                                        position: "relative",
                                        height: "100%",
                                        paddingRight: theme.spacing.big,
                                        paddingLeft: media.isDesktopSmall
                                            ? theme.spacing.big
                                            : 0,
                                    }}
                                    justify="center"
                                >
                                    {media.isDesktopSmall ? (
                                        <Image
                                            alt="Ostium Logo"
                                            source="https://static.ostium.io/assets/logo-square.svg"
                                            width={24}
                                            height={24}
                                            selectable={false}
                                        />
                                    ) : (
                                        <Image
                                            alt="Ostium Logo"
                                            source={
                                                "https://static.ostium.io/assets/logo.svg"
                                            }
                                            width={123}
                                            height={24}
                                            selectable={false}
                                            priority
                                        />
                                    )}
                                </Box>
                            </Link>

                            {!media.isDesktopSmall && !simple ? (
                                <Navigation items={NAVIGATION_LINKS} />
                            ) : null}
                        </Flex>

                        {!simple ? (
                            <Flex
                                align="center"
                                justify="flex-end"
                                style={{
                                    height: "100%",
                                    zIndex: 10,
                                }}
                                gap={theme.spacing.smaller}
                            >
                                {/* {media.isMobile ? null : (
                                    <Button
                                        primary
                                        small
                                        stroke
                                        // noBorder
                                        style={{ height: 32 }}
                                        onPress={openCompetition}
                                        text={"Competition"}
                                    />
                                )} */}

                                {/* <NewVersionButton /> */}
                                {/* {address ? (
                                        <Button
                                            primary
                                            small
                                            stroke
                                            style={{ height: 36 }}
                                            onPress={openCompetition}
                                            text={"Competition"}
                                        />
                                    ) : null} */}

                                {address ? (
                                    <Button
                                        primary
                                        small
                                        stroke
                                        noBorder
                                        style={{ height: 32 }}
                                        onPress={startDeposit}
                                        text={"Fund"}
                                    />
                                ) : null}

                                {address && !isMobile ? (
                                    <>
                                        {isOneClickTradingEnabled ||
                                        scStatusLoading ? null : (
                                            <Pressable
                                                onPress={() => {
                                                    handleEnableSmartAccount();
                                                }}
                                            >
                                                <LinearGradient
                                                    colors={[
                                                        "rgba(255, 90, 25, 0.40)",
                                                        "rgba(255, 90, 25, 0.00)",
                                                    ]}
                                                    start={{ x: 0, y: 1 }}
                                                    end={{ x: 1, y: 0 }}
                                                    style={{
                                                        borderRadius: 6,
                                                        borderWidth: 0.5,
                                                        backgroundColor:
                                                            "rgba(208, 219, 218, 0.10)", // Backup solid color
                                                        display: "flex",
                                                        height: 32,
                                                        paddingHorizontal: 12,
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                        gap: 10,
                                                    }}
                                                >
                                                    <Text
                                                        smaller
                                                        color={
                                                            theme.color.greyish
                                                        }
                                                    >
                                                        {loading
                                                            ? "InProgress..."
                                                            : "Enable 1-Click Trading"}
                                                    </Text>
                                                </LinearGradient>
                                            </Pressable>
                                            // <Button
                                            //     primary
                                            //     small
                                            //     // stroke
                                            //     noBorder
                                            //     style={{ height: 32 }}
                                            //     onPress={() => {
                                            //         handleEnableSmartAccount();
                                            //     }}
                                            //     disabled={loading}
                                            //     text={
                                            //         loading
                                            //             ? "InProgress..."
                                            //             : "Enable Account"
                                            //     }
                                            // />
                                        )}
                                    </>
                                ) : null}

                                {address ? (
                                    <Button
                                        small
                                        noBorder
                                        stroke
                                        onPress={toggleReferralModal}
                                        iconProps={{
                                            name: "referrals",
                                            size: 18,
                                        }}
                                    />
                                ) : null}

                                <UserSettings />

                                <Modal
                                    isVisible={isWithdrawModalVisible}
                                    title="Withdraw Funds"
                                >
                                    <WithdrawModal
                                        onClose={() =>
                                            setIsWithdrawModalVisible(false)
                                        }
                                        sendSmartWalletOrder={
                                            sendSmartWalletOrder
                                        }
                                    />
                                </Modal>
                                {media.isDesktopSmall ? (
                                    <Box
                                        style={{
                                            height: "100%",
                                            marginRight: theme.spacing.smaller,
                                        }}
                                    >
                                        <MobileMenuButton
                                            items={NAVIGATION_LINKS}
                                        />
                                    </Box>
                                ) : null}
                            </Flex>
                        ) : (
                            <Flex
                                style={{
                                    height: "100%",
                                    paddingRight: media.isDesktopSmall
                                        ? theme.spacing.bigger
                                        : theme.spacing.smallest,
                                }}
                                align="center"
                                gap={theme.spacing.smaller}
                            >
                                <NavigationLink
                                    label="Links"
                                    items={NAVIGATION_MORE_LINKS}
                                />
                                {address ? (
                                    <Button
                                        iconProps={{
                                            name: "disconnect",
                                        }}
                                        small
                                        primary
                                        stroke
                                        onPress={onDisconnect}
                                        text="Disconnect"
                                        style={{
                                            width: media.isDesktopSmall
                                                ? 32
                                                : undefined,
                                            paddingHorizontal: 0,
                                        }}
                                    />
                                ) : null}
                            </Flex>
                        )}
                    </Flex>
                </BlurView>
            </Box>
            {!showedLaunchBanners && isMobile ? <LaunchBannerMobile /> : null}
            {showedLaunchBanners || isMobile ? null : <LaunchBanner />}
            <Modal
                isVisible={isAAv2ModalVisible}
                onClose={() => setIsAAv2ModalVisible(false)}
                hasCustomHeader={true}
                renderCustomHeader={null}
                maxWidth={406}
            >
                <AAV2Modal
                    address={address as string}
                    onClose={() => setIsAAv2ModalVisible(false)}
                />
            </Modal>
            <Modal
                isVisible={isSCBannerModalVisible}
                onClose={() => setIsSCBannerModalVisible(false)}
                hasCustomHeader={true}
                renderCustomHeader={null}
                maxWidth={406}
            >
                <SCUpgradeModal
                    onClose={() => setIsSCBannerModalVisible(false)}
                />
            </Modal>
            <Modal
                isVisible={isReferralModalVisible}
                onClose={toggleReferralModal}
                title={"Earn Referral Rewards"}
            >
                <Referrals onClose={toggleReferralModal} />
            </Modal>
            <Enable1CTModal />
        </Box>
    );
});

const LaunchBannerMobile = () => {
    const theme = useTheme();
    const {
        setIsAAv2ModalVisible,
        setIsSCBannerModalVisible,
        setShowedLaunchBanners,
        hasMigration,
    } = useAppContext();
    const { user } = usePrivy();

    return (
        <Flex
            gap={theme.spacing.big}
            style={{
                paddingLeft: theme.spacing.smaller,
                paddingRight: theme.spacing.smaller,
                paddingTop: theme.spacing.tiny,
                paddingBottom: theme.spacing.medium,
                borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                width: "100%",
                gap: 12,
                zIndex: 1000,
                backgroundColor: "black",
            }}
            direction="column"
        >
            <LinearGradient
                colors={[
                    theme.color.rgba(theme.color.orange, 0.3),
                    theme.color.rgba(theme.color.orange, 0.2),
                    theme.color.rgba(theme.color.orange, 0.1),
                    theme.color.rgba(theme.color.orange, 0.2),
                    theme.color.rgba(theme.color.orange, 0.3),
                ]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                    pointerEvents: "none",
                    zIndex: 1000,
                    opacity: 1,
                    // @ts-ignore
                    transition: "0.1s opacity ease-in-out",
                }}
            />
            {user && (user.google?.email || user?.email?.address) ? (
                <>
                    <Flex align="center" justify="space-between">
                        <Text bold medium color={"rgba(208, 219, 218, 0.70)"}>
                            Shipping Season is Here 🚢
                        </Text>
                        <Pressable
                            onPress={() => {
                                setShowedLaunchBanners(true);
                                window?.localStorage.setItem(
                                    "showed_launch_banner",
                                    "true"
                                );
                            }}
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Icon name="close" size={14} />
                            </Box>
                        </Pressable>
                    </Flex>
                    <Pressable onPress={() => setIsSCBannerModalVisible(true)}>
                        <Flex align="center" justify="space-between">
                            <Flex
                                style={{
                                    padding: 12,
                                    borderRadius: 6,
                                    border: "0.5px solid #353434",
                                    backgroundColor: "rgba(30, 30, 30, 0.90)",
                                    width: "100%",
                                }}
                                justify="space-between"
                                align="center"
                            >
                                <Text semiBold big color="#D0DBDA">
                                    Smart Contract Upgrades
                                </Text>
                                <Flex
                                    align="center"
                                    gap={theme.spacing.smallest}
                                >
                                    <Text
                                        small
                                        color="rgba(208, 219, 218, 0.70)"
                                    >
                                        Live Now
                                    </Text>
                                    <Icon
                                        name="bullet"
                                        size={10}
                                        color={theme.color.vividGreen}
                                    />
                                    <Icon
                                        name="caret"
                                        color={theme.color.rgba(
                                            "#D0DBDAB2",
                                            0.7
                                        )}
                                        size={10}
                                        rotate={0}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Pressable>
                </>
            ) : (
                <>
                    <Flex align="center" justify="space-between">
                        <Text bold medium color="white" marginTop={16}>
                            Shipping Season is Here 🚢
                        </Text>
                        <Pressable
                            onPress={() => {
                                setShowedLaunchBanners(true);
                                window?.localStorage.setItem(
                                    "showed_launch_banner",
                                    "true"
                                );
                            }}
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Icon name="close" size={14} />
                            </Box>
                        </Pressable>
                    </Flex>
                    <Flex
                        align="center"
                        justify="space-evenly"
                        gap={theme.spacing.small}
                    >
                        <Pressable
                            style={{
                                flex: 1,
                            }}
                            onPress={() => setIsSCBannerModalVisible(true)}
                        >
                            <Flex
                                direction="column"
                                flex={1}
                                style={{
                                    gap: 8,
                                    padding: 12,
                                    borderRadius: 12,
                                    border: "0.5px solid #353434",
                                    backgroundColor: "#1E1E1E",
                                    flex: 1,
                                    width: "100%",
                                }}
                                gap={theme.spacing.smallest}
                            >
                                <Flex
                                    align="center"
                                    gap={theme.spacing.smallest}
                                >
                                    <Text
                                        smaller
                                        color="rgba(208, 219, 218, 0.70)"
                                    >
                                        Live Now
                                    </Text>
                                    <Icon
                                        name="bullet"
                                        size={10}
                                        color={theme.color.vividGreen}
                                    />
                                </Flex>
                                <Text small color="#D0DBDA">
                                    Smart Contract Upgrade
                                </Text>
                            </Flex>
                        </Pressable>
                        <Pressable
                            style={{
                                flex: 1,
                            }}
                            onPress={() => setIsAAv2ModalVisible(true)}
                        >
                            <Flex
                                direction="column"
                                flex={1}
                                style={{
                                    gap: 8,
                                    padding: 12,
                                    borderRadius: 12,
                                    border: "0.5px solid #353434",
                                    backgroundColor: "#1E1E1E",
                                    flex: 1,
                                    width: "100%",
                                }}
                            >
                                {hasMigration ? (
                                    <Flex justifyContent="space-between">
                                        <Text
                                            smaller
                                            color="rgba(208, 219, 218, 0.70)"
                                        >
                                            Live Now
                                        </Text>
                                        <Flex
                                            style={{
                                                paddingLeft: 6,
                                                paddingRight: 6,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                borderRadius: 4,
                                                backgroundColor: "#FC0",
                                                marginLeft: 20,
                                            }}
                                            direction=""
                                        >
                                            <Tooltip
                                                title="Can't see your funds"
                                                content="If you have trades on v1, click migrate funds & transfer them into your primary wallet"
                                                position="bottom-left"
                                            >
                                                <Flex
                                                    style={{ gap: 4 }}
                                                    align="center"
                                                >
                                                    <Icon
                                                        name="question"
                                                        color={theme.color.rgba(
                                                            theme.color.black,
                                                            0.8
                                                        )}
                                                        size={10}
                                                    />
                                                    <Text tiny color="#070606">
                                                        Migrate Funds
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                ) : (
                                    <Text
                                        smaller
                                        color="rgba(208, 219, 218, 0.70)"
                                    >
                                        Live Now
                                    </Text>
                                )}

                                <Text small color="#D0DBDA">
                                    Simplified Trading UX
                                </Text>
                            </Flex>
                        </Pressable>
                    </Flex>
                </>
            )}
        </Flex>
    );
};

const LaunchBanner = () => {
    const theme = useTheme();
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const {
        setIsAAv2ModalVisible,
        setIsSCBannerModalVisible,
        setShowedLaunchBanners,
        hasMigration,
    } = useAppContext();
    const { user } = usePrivy();

    return (
        <Flex
            gap={theme.spacing.big}
            style={{
                padding: theme.spacing.medium,
                borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                height: CTA_HEIGHT,
                width: "100%",
                zIndex: 1000,
                backgroundColor: "black",
            }}
            align="center"
            justify="space-between"
        >
            <LinearGradient
                colors={[
                    theme.color.rgba(theme.color.orange, 0.3),
                    theme.color.rgba(theme.color.orange, 0.2),
                    theme.color.rgba(theme.color.orange, 0.1),
                    theme.color.rgba(theme.color.orange, 0.2),
                    theme.color.rgba(theme.color.orange, 0.3),
                ]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                    pointerEvents: "none",
                    zIndex: 0,
                    opacity: 1,
                    // @ts-ignore
                    transition: "0.1s opacity ease-in-out",
                }}
            />
            <Flex
                align="center"
                justify="space-between"
                style={{ flexGrow: 1 }}
                gap={theme.spacing.medium}
            >
                <Text bold smaller color={"#D0DBDA"}>
                    Shipping Season is Here 🚢
                </Text>
                <Pressable
                    onPress={() => setIsSCBannerModalVisible(true)}
                    style={{ flexGrow: 1 }}
                >
                    <Flex
                        justify="center"
                        style={{
                            backgroundColor:
                                hoveredItem === "sc-upgrade"
                                    ? "rgba(208, 219, 218, 0.40)"
                                    : user &&
                                      (user.google?.email ||
                                          user?.email?.address)
                                    ? "auto"
                                    : "rgba(208, 219, 218, 0.10)",
                            paddingTop: theme.spacing.tiny,
                            paddingBottom: theme.spacing.tiny,
                            borderRadius: theme.spacing.smallest,
                            paddingLeft: theme.spacing.bigger,
                            paddingRight: theme.spacing.bigger,
                            transition: "all 0.1s",
                        }}
                        align="center"
                        onMouseEnter={() => setHoveredItem("sc-upgrade")}
                        onMouseLeave={() => setHoveredItem(null)}
                        gap={theme.spacing.smallest}
                    >
                        {user &&
                        (user.google?.email || user?.email?.address) ? null : (
                            <Box style={{ marginRight: 8 }}>
                                <Image
                                    source={getBaseS3URL("toll.png")}
                                    width={18}
                                    height={18}
                                    alt={`logo"`}
                                />
                            </Box>
                        )}

                        <Text color="#D0DBDA" smaller>
                            Better trading
                        </Text>
                        <Box
                            style={{
                                width: 1,
                                marginLeft: 8,
                                marginRight: 8,
                                height: 10,
                                backgroundColor: "rgba(208, 219, 218, 0.20)",
                            }}
                        ></Box>
                        <Text color="#D0DBDA" smaller>
                            Lower fees
                        </Text>

                        <Box
                            style={{
                                width: 1,
                                marginLeft: 8,
                                marginRight: 8,
                                height: 10,
                                backgroundColor: "rgba(208, 219, 218, 0.20)",
                            }}
                        ></Box>
                        <Text color="#D0DBDA" smaller>
                            More markets
                        </Text>
                        {/* <Text
                            style={{ marginLeft: 8 }}
                            color="rgba(208, 219, 218, 0.70)"
                            smaller
                        >
                            Live Now
                        </Text>
                        <Icon
                            name="bullet"
                            size={10}
                            color={theme.color.vividGreen}
                        /> */}
                    </Flex>
                </Pressable>
                {user && (user.google?.email || user?.email?.address) ? null : (
                    <Pressable
                        onPress={() => setIsAAv2ModalVisible(true)}
                        style={{ flexGrow: 1 }}
                    >
                        <Flex
                            justify="center"
                            style={{
                                backgroundColor:
                                    hoveredItem === "aa-v2"
                                        ? "rgba(208, 219, 218, 0.40)"
                                        : user &&
                                          (user.google?.email ||
                                              user?.email?.address)
                                        ? "auto"
                                        : "rgba(208, 219, 218, 0.10)",
                                paddingTop: theme.spacing.tiny,
                                paddingBottom: theme.spacing.tiny,
                                borderRadius: theme.spacing.smallest,
                                paddingLeft: theme.spacing.bigger,
                                paddingRight: theme.spacing.bigger,
                                transition: "all 0.1s",
                            }}
                            align="center"
                            onMouseEnter={() => setHoveredItem("aa-v2")}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            <Box style={{ marginRight: 8 }}>
                                <Image
                                    source={getBaseS3URL("arrow.png")}
                                    width={18}
                                    height={18}
                                    alt={`logo"`}
                                />
                            </Box>
                            <Text color="#D0DBDA" smaller>
                                Simplified wallet experience
                            </Text>
                            <Box
                                style={{
                                    width: 1,
                                    marginLeft: 8,
                                    marginRight: 8,
                                    height: 10,
                                    backgroundColor:
                                        "rgba(208, 219, 218, 0.20)",
                                }}
                            ></Box>
                            <Text color="#D0DBDA" smaller>
                                Gasless transactions
                            </Text>
                            {hasMigration ? (
                                <Flex
                                    style={{
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        borderRadius: 4,
                                        backgroundColor: "#FC0",
                                        marginLeft: 20,
                                    }}
                                    direction=""
                                >
                                    <Tooltip
                                        title="Can't see your funds"
                                        content="If you have trades on v1, click migrate funds & transfer them into your primary wallet"
                                        position="bottom-left"
                                    >
                                        <Flex style={{ gap: 4 }} align="center">
                                            <Icon
                                                name="question"
                                                color={theme.color.rgba(
                                                    theme.color.black,
                                                    0.8
                                                )}
                                                size={10}
                                            />
                                            <Text smaller color="#070606">
                                                Migrate Funds
                                            </Text>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            ) : null}
                            {/* <Text
                                style={{ marginLeft: 8, marginRight: 8 }}
                                color="rgba(208, 219, 218, 0.70)"
                                smaller
                            >
                                Live Now
                            </Text>
                            <Icon
                                name="bullet"
                                size={10}
                                color={theme.color.vividGreen}
                            /> */}
                        </Flex>
                    </Pressable>
                )}
            </Flex>
            <Flex gap={theme.spacing.small}>
                {user && (user.google?.email || user?.email?.address) ? (
                    <Button
                        smallest
                        primary
                        noBorder
                        stroke
                        text="Learn More"
                        onPress={() => setIsSCBannerModalVisible(true)}
                    />
                ) : null}
                <Pressable
                    onPress={() => {
                        setShowedLaunchBanners(true);
                        window?.localStorage.setItem(
                            "showed_launch_banner",
                            "true"
                        );
                    }}
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Icon name="close" size={12} />
                    </Box>
                </Pressable>
            </Flex>
        </Flex>
    );
};

const SCUpgradeModal = ({ onClose }: { onClose: any }) => {
    return (
        <Flex style={{ padding: 16 }} direction="column">
            <Pressable onPress={onClose} style={{ zIndex: 1000 }}>
                <Box
                    style={{
                        position: "absolute",
                        width: 80,
                        height: 50,
                        right: 0,
                    }}
                ></Box>
            </Pressable>
            <Image
                source={getBaseS3URL("sc_upgrade.png")}
                width={368}
                height={229}
                alt={`logo"`}
            />
            <Box style={{ paddingTop: 16 }}>
                <Text big color="#D0DBDA" lineHeight={26}>
                    Edit your open positions. Pay less in fees. Trade more
                    assets.
                </Text>
                <Flex style={{ paddingTop: 20 }} direction="column" gap={24}>
                    <Flex align="center">
                        <Flex
                            style={{
                                backgroundColor: "rgba(255, 90, 25, 0.20)",
                                borderRadius: 12,
                                padding: 12,
                            }}
                        >
                            <Image
                                source={getBaseS3URL("pen.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                        </Flex>
                        <Flex
                            direction="column"
                            justify="flex-start"
                            style={{ marginLeft: 15, gap: 4 }}
                        >
                            <Text
                                semiBold
                                smaller
                                color="rgba(208, 219, 218, 0.40)"
                                style={{ textTransform: "uppercase" }}
                            >
                                Partial Close & Edit Collateral
                            </Text>
                            <Text big color="#D0DBDA">
                                Your open trades are now more flexible.
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex align="center">
                        <Flex
                            style={{
                                backgroundColor: "rgba(255, 90, 25, 0.20)",
                                borderRadius: 12,
                                padding: 12,
                            }}
                        >
                            <Image
                                source={getBaseS3URL("receipt.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                        </Flex>
                        <Flex
                            direction="column"
                            justify="flex-start"
                            style={{ marginLeft: 15, gap: 4 }}
                        >
                            <Text
                                semiBold
                                smaller
                                color="rgba(208, 219, 218, 0.40)"
                                style={{ textTransform: "uppercase" }}
                            >
                                New Fee Structure
                            </Text>
                            <Text big color="#D0DBDA">
                                Pay less in fees.
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex align="center">
                        <Flex
                            style={{
                                backgroundColor: "rgba(255, 90, 25, 0.20)",
                                borderRadius: 12,
                                padding: 12,
                            }}
                        >
                            <Image
                                source={getBaseS3URL("listPlus.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                        </Flex>
                        <Flex
                            direction="column"
                            justify="flex-start"
                            style={{ marginLeft: 15, gap: 4 }}
                        >
                            <Text
                                semiBold
                                smaller
                                color="rgba(208, 219, 218, 0.40)"
                                style={{ textTransform: "uppercase" }}
                            >
                                7 New Assets
                            </Text>
                            <Text big color="#D0DBDA" lineHeight={26}>
                                {
                                    "Expanded coverage includes new indices\n and FX."
                                }
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justify="space-between" style={{ marginTop: 24 }}>
                    <Pressable
                        onPress={() =>
                            window.open("https://t.me/ostiumio", "_blank")
                        }
                    >
                        <Flex
                            style={{
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 12,
                                paddingBottom: 12,
                                gap: 12,
                            }}
                            align="center"
                        >
                            <Image
                                source={getBaseS3URL("telegram.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                            <Text big color="rgba(208, 219, 218, 0.70)">
                                Reach Out
                            </Text>
                        </Flex>
                    </Pressable>
                    <Pressable onPress={onClose}>
                        <Flex
                            style={{
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 12,
                                paddingBottom: 12,
                                gap: 12,
                                borderRadius: 6,
                                backgroundColor: "rgba(208, 219, 218, 0.90)",
                            }}
                            align="center"
                        >
                            <Text big color="#070606">
                                Got it
                            </Text>
                        </Flex>
                    </Pressable>
                </Flex>
            </Box>
        </Flex>
    );
};

const AAV2Modal = ({ onClose, address }: { onClose: any; address: string }) => {
    const {
        isOneClickTradingEnabled,
        setShowOffboardingModal,
        setShowEnableCTModal,
        hasMigration,
    } = useAppContext();

    return (
        <Flex style={{ padding: 16 }} direction="column">
            <Pressable onPress={onClose} style={{ zIndex: 1000 }}>
                <Box
                    style={{
                        position: "absolute",
                        width: 80,
                        height: 50,
                        right: 0,
                    }}
                ></Box>
            </Pressable>
            <Image
                source={getBaseS3URL("aav2.png")}
                width={368}
                height={229}
                alt={`logo"`}
            />
            <Box style={{ paddingTop: 16 }}>
                {/* <Text big color="#D0DBDA" lineHeight={26}>
                    Enjoy benefits like gas sponsorship and one-click trading,
                    directly from your primary wallet.
                </Text> */}
                <Flex direction="column" gap={24}>
                    <Flex align="center">
                        <Flex
                            style={{
                                backgroundColor: "rgba(255, 90, 25, 0.20)",
                                borderRadius: 12,
                                padding: 12,
                            }}
                        >
                            <Image
                                source={getBaseS3URL("arrow.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                        </Flex>
                        <Flex
                            direction="column"
                            justify="flex-start"
                            style={{ marginLeft: 15, gap: 4 }}
                        >
                            <Text
                                semiBold
                                smaller
                                color="rgba(208, 219, 218, 0.40)"
                                style={{ textTransform: "uppercase" }}
                            >
                                1-Click Trading
                            </Text>
                            <Text big color="#D0DBDA" lineHeight={26}>
                                {
                                    "Never leave your wallet. Delegate\n signing to a smart account."
                                }
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex align="center">
                        <Flex
                            style={{
                                backgroundColor: "rgba(255, 90, 25, 0.20)",
                                borderRadius: 12,
                                padding: 12,
                            }}
                        >
                            <Image
                                source={getBaseS3URL("coins.png")}
                                width={20}
                                height={20}
                                alt={`logo"`}
                            />
                        </Flex>
                        <Flex
                            direction="column"
                            justify="flex-start"
                            style={{ marginLeft: 15, gap: 4 }}
                        >
                            <Text
                                semiBold
                                smaller
                                color="rgba(208, 219, 218, 0.40)"
                                style={{ textTransform: "uppercase" }}
                            >
                                Gasless Transactions
                            </Text>
                            <Text big color="#D0DBDA">
                                Never worry about funds for gas again.
                            </Text>
                        </Flex>
                    </Flex>

                    {/* <Flex align="center">
                        <Text
                            lineHeight={24}
                            big
                            color="rgba(208, 219, 218, 0.70)"
                        >
                            {
                                "If you have any funds in smart account v1 then\nyou'll need to migrate them back to your primary wallet."
                            }
                        </Text>
                    </Flex> */}
                </Flex>

                {hasMigration ? (
                    <Box
                        style={{
                            backgroundColor: "rgba(208, 219, 218, 0.10)",
                            padding: 12,
                            borderRadius: 8,
                            marginTop: 24,
                        }}
                    >
                        <Flex style={{ gap: 12 }} direction="column">
                            <Flex style={{ gap: 10 }} align="center">
                                <Image
                                    source={getBaseS3URL("info.png")}
                                    width={16}
                                    height={16}
                                    alt={`info"`}
                                />
                                <Text sml semiBold color="#FFCC00">
                                    Important
                                </Text>
                            </Flex>
                            <Text
                                big
                                color="#D0DBDA"
                                style={{ lineHeight: "normal" }}
                            >
                                {
                                    "To make use of this, you need to migrate\nyour funds back to your connected wallet."
                                }
                            </Text>
                        </Flex>
                        <Flex justify="space-between" style={{ marginTop: 24 }}>
                            <Pressable
                                onPress={() =>
                                    window.open(
                                        "https://t.me/ostiumio",
                                        "_blank"
                                    )
                                }
                            >
                                <Flex
                                    style={{
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        gap: 12,
                                    }}
                                    align="center"
                                >
                                    <Image
                                        source={getBaseS3URL("telegram.png")}
                                        width={20}
                                        height={20}
                                        alt={`logo"`}
                                    />
                                    <Text big color="rgba(208, 219, 218, 0.70)">
                                        Reach Out
                                    </Text>
                                </Flex>
                            </Pressable>
                            {address ? (
                                <Pressable
                                    onPress={() => {
                                        onClose();
                                        setShowOffboardingModal(true);
                                    }}
                                >
                                    <Flex
                                        style={{
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            paddingTop: 12,
                                            paddingBottom: 12,
                                            gap: 12,
                                            borderRadius: 6,
                                            backgroundColor:
                                                "rgba(208, 219, 218, 1)",
                                        }}
                                        align="center"
                                    >
                                        <Text big color="#070606">
                                            Migrate Funds
                                        </Text>
                                    </Flex>
                                </Pressable>
                            ) : null}
                        </Flex>
                    </Box>
                ) : (
                    <Flex justify="space-between" style={{ marginTop: 24 }}>
                        <Pressable
                            onPress={() =>
                                window.open("https://t.me/ostiumio", "_blank")
                            }
                        >
                            <Flex
                                style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    gap: 12,
                                }}
                                align="center"
                            >
                                <Image
                                    source={getBaseS3URL("telegram.png")}
                                    width={20}
                                    height={20}
                                    alt={`logo"`}
                                />
                                <Text big color="rgba(208, 219, 218, 0.70)">
                                    Reach Out
                                </Text>
                            </Flex>
                        </Pressable>
                        {address ? (
                            <Pressable
                                onPress={() => {
                                    onClose();
                                    if (isOneClickTradingEnabled) return;
                                    setShowEnableCTModal({
                                        visible: true,
                                        delegationStep: 1,
                                        allowanceStep: 0,
                                    });
                                }}
                            >
                                <Flex
                                    style={{
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        gap: 12,
                                        borderRadius: 6,
                                        backgroundColor:
                                            "rgba(208, 219, 218, 1)",
                                    }}
                                    align="center"
                                >
                                    <Text big color="#070606">
                                        {isOneClickTradingEnabled
                                            ? "Close"
                                            : "Enable"}
                                    </Text>
                                </Flex>
                            </Pressable>
                        ) : null}
                    </Flex>
                )}
            </Box>
        </Flex>
    );
};

export const MobileMenuButton = ({
    items,
}: {
    items: NavigationLinkType[];
}) => {
    const theme = useTheme();
    const { isCTAActive } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <Pressable
                onPress={toggleOpen}
                style={{
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Box
                    style={{
                        paddingHorizontal: theme.spacing.smaller,
                        height: 32,
                        borderRadius: theme.radius.medium,
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            isOpen ? 0.2 : 0.1
                        ),
                    }}
                    align="center"
                    justify="center"
                >
                    <Icon
                        name="burger"
                        color={theme.color.rgba(theme.color.white, 0.4)}
                        size={24}
                    />
                </Box>
            </Pressable>
            <Portal>
                <Box
                    style={{
                        position: "fixed",
                        top: HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: isOpen ? 1 : -1,
                        opacity: isOpen ? 1 : 0,
                        backgroundColor: theme.color.black,
                    }}
                    justify="space-between"
                >
                    <NavigationMobile items={items} onToggle={toggleOpen} />
                    <Box
                        style={{
                            padding: theme.spacing.tiny,
                        }}
                    >
                        <Socials big />
                    </Box>
                </Box>
            </Portal>
        </>
    );
};

export default Header;

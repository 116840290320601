import { ApolloError, gql, useSubscription } from "@hooks/useApollo";
import { Pair } from "gql/graphql";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAppContext } from "./app";
import type { Price } from "utils";

const PriceContext = createContext(
    {} as {
        prices?: Map<`${string}-${string}`, Price>;
        price?: Price | null;
        error?: ApolloError;
    }
);

const MAX_RETRIES = 3;

function getPairKey(pair: { from: string; to: string }): `${string}-${string}` {
    return `${pair.from}-${pair.to}`;
}

function usePrice({ pair }: { pair?: Pair }) {
    const { initial } = useAppContext();
    const [prices, setPrices] = useState(
        new Map<`${string}-${string}`, Price>(new Map())
    );
    const [retryCount, setRetryCount] = useState(0);

    const retrySubscription = useCallback(() => {
        if (retryCount < MAX_RETRIES) {
            setRetryCount((prev) => prev + 1);
            console.log(`Retrying... Attempt ${retryCount + 1}`);
        } else {
            console.error("Max retries reached. Subscription failed.");
        }
    }, [retryCount]);

    useEffect(() => {
        setPrices(
            new Map(
                initial?.prices?.map((p) => [
                    getPairKey(p),
                    {
                        mid: p.mid,
                        bid: p.bid,
                        ask: p.ask,
                        isMarketOpen: p.isMarketOpen,
                        timestampSeconds: p.timestampSeconds,
                        id: p.id,
                        from: p.from,
                        to: p.to,
                    },
                ])
            )
        );
    }, [initial?.prices]);

    const { error } = useSubscription(SUBSCRIPTION_PRICES, {
        onData: ({ data }) => {
            if (data) {
                const newPrice = data.data.price;
                if (newPrice && newPrice.mid) {
                    setPrices((prev) => {
                        const newPrices = new Map(prev);
                        newPrices.set(getPairKey(newPrice), {
                            mid: newPrice.mid,
                            bid: newPrice.bid,
                            ask: newPrice.ask,
                            isMarketOpen: newPrice.isMarketOpen,
                            timestampSeconds: newPrice.timestampSeconds,
                            id: newPrice.id,
                            from: newPrice.from,
                            to: newPrice.to,
                        });
                        return newPrices;
                    });
                }
            }
        },
        onError: () => {
            retrySubscription();
        },
    });

    const price = useMemo(() => {
        if (pair) return prices.get(getPairKey(pair));
        return null;
    }, [pair, prices]);

    useEffect(() => {
        return () => {
            setPrices(new Map());
        };
    }, []);

    return { price, prices, error };
}

const useGetPrice = (pair: Pair) => {
    const { prices } = usePrice({ pair });
    return useMemo(() => prices.get(getPairKey(pair)), [pair, prices]);
};

const PriceProvider = ({
    children,
    pair,
}: {
    children: JSX.Element | null;
    pair?: Pair;
}) => {
    const value = usePrice({ pair });

    return (
        <PriceContext.Provider value={value}>{children}</PriceContext.Provider>
    );
};

const SUBSCRIPTION_PRICES = gql`
    subscription PriceSubscription {
        price {
            from
            to
            isMarketOpen
            mid
            bid
            ask
            timestampSeconds
        }
    }
`;
const usePriceContext = () => useContext(PriceContext);

export { PriceProvider, usePriceContext, useGetPrice, getPairKey };

export const getPrice = (
    pair: Pair,
    prices?: Map<`${string}-${string}`, Price>
) => {
    return (
        prices?.get(getPairKey(pair)) || {
            mid: 0,
            bid: 0,
            ask: 0,
            isMarketOpen: false,
        }
    );
};

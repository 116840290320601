import { Box, Flex } from "@atoms/Flex";
import { Button } from "@molecules/Button";
import Modal from "@organisms/Modal";
import { Text } from "@atoms/Text";
import { useTheme } from "styled-components/native";
import { ONBOARDING_STEPS, useAppContext } from "@contexts/app";
import { Image } from "@atoms/Image";
import { getBaseS3URL, getPairLogo } from "@utils";
import { Tooltip } from "@molecules/Tooltip";
import { Icon } from "@atoms/Icon";
import { CTA_HEIGHT, HEADER_HEIGHT } from "../Header";
import { Checkbox } from "@molecules/Checkbox";
import { useState } from "react";
import { TextInput } from "react-native";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { MESSAGE } from "@screens/Authentication/AcceptTerms";
import { useMedia } from "@hooks/useMedia";
import { useTutorialContext } from "@organisms/Tutorial/context";
import { useDepositArbitrumUsdc } from "@contexts/hooks/useDepositArbitrumUsdc";

const OnboardingModal = () => {
    const theme = useTheme();
    const media = useMedia();

    const {
        onboardingStep,
        setOnboardingStep,
        showOnboardingModal,
        setShowOnboardingModal,
        refetchUser,
    } = useAppContext();

    const { toggleActive } = useTutorialContext();
    const startDeposit = useDepositArbitrumUsdc({ onExit: () => {
        setOnboardingStep(ONBOARDING_STEPS.START_TRADE);
    }})
    const { address } = useOstiumAccount();
    const [loading, setLoading] = useState(false);

    const handleAcceptTnCClick = async () => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                address: address as string,
                message: MESSAGE as string,
                signedMessage: `signed:${MESSAGE}` as string,
            });

            await fetch(`/api/user/accept-terms`, {
                method: "POST",
                body: params,
            });
            await refetchUser();
            setOnboardingStep(ONBOARDING_STEPS.FUND);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const handleSkipFundClick = () => {
        setShowOnboardingModal(false);
        setOnboardingStep(ONBOARDING_STEPS.TNC);
    };
    const handleShowMeHowClick = () => {
        if (window.location.pathname.includes("trade")) {
            toggleActive();
        }
        setShowOnboardingModal(false);
        setOnboardingStep(ONBOARDING_STEPS.TNC);
    };

    const handleFinishClick = () => {
        setShowOnboardingModal(false);
        setOnboardingStep(ONBOARDING_STEPS.TNC);
    };

    const { isCTAActive } = useAppContext();
    const [isTNCActive, setIsTNCActive] = useState(false);

    return (
        <Modal
            title="Welcome!"
            isVisible={showOnboardingModal}
            useGradient={true}
            onClose={
                onboardingStep === ONBOARDING_STEPS.TNC
                    ? undefined
                    : () => {
                          setShowOnboardingModal(false);
                      }
            }
            style={{
                position: "absolute",
                top: media.isMobile ? "auto" : HEADER_HEIGHT,
                // TODO: add this back in
                // top:
                //     isCTAActive === true
                //         ? HEADER_HEIGHT + CTA_HEIGHT
                //         : HEADER_HEIGHT,
                right: media.isMobile ? "auto" : 48,
                left: "auto",
                borderRadius: 12,
                overflow: "hidden",
            }}
            hasBorder
        >
            <Box
                style={{
                    paddingTop: theme.spacing.bigger,
                    paddingBottom: theme.spacing.bigger,
                    paddingLeft:
                        onboardingStep === ONBOARDING_STEPS.START_TRADE
                            ? 0
                            : theme.spacing.bigger,
                    paddingRight:
                        onboardingStep === ONBOARDING_STEPS.START_TRADE
                            ? 0
                            : theme.spacing.bigger,
                }}
            >
                <Flex style={{ gap: 6 }} justify="center">
                    <Box
                        style={{
                            borderRadius: 3,
                            minWidth: 48,
                            minHeight: 6,
                            backgroundColor:
                                onboardingStep >= 1
                                    ? theme.color.greyish
                                    : theme.color.darkGrey,
                        }}
                    ></Box>
                    <Box
                        style={{
                            borderRadius: 3,
                            minWidth: 48,
                            minHeight: 6,
                            backgroundColor:
                                onboardingStep >= 2
                                    ? theme.color.greyish
                                    : theme.color.darkGrey,
                        }}
                    ></Box>
                    <Box
                        style={{
                            borderRadius: 3,
                            minWidth: 48,
                            minHeight: 6,
                            backgroundColor:
                                onboardingStep >= 3
                                    ? theme.color.greyish
                                    : theme.color.darkGrey,
                        }}
                    ></Box>
                </Flex>
                {onboardingStep === ONBOARDING_STEPS.TNC ? (
                    <Flex
                        style={{ gap: 24, marginTop: theme.spacing.bigger }}
                        direction="column"
                    >
                        <Text bigger>Personalize Your Account</Text>
                        <Flex
                            style={{ visibility: "hidden" }}
                            direction="column"
                            gap={theme.spacing.smallest}
                        >
                            <Text
                                style={{
                                    color: theme.color.greyish,
                                    fontSize: 14,
                                }}
                            >
                                Username
                            </Text>
                            <TextInput
                                style={{
                                    paddingHorizontal: 16,
                                    paddingVertical: 8,
                                    backgroundColor:
                                        "rgba(208, 219, 218, 0.10)",
                                    borderRadius: 4,
                                    borderWidth: 0.5,
                                    borderColor: "rgba(208, 219, 218, 0.70)",
                                    color: theme.color.greyish,
                                    marginTop: 4,
                                    height: 32,
                                }}
                            />
                        </Flex>
                        <Box>
                            <Flex
                                style={{
                                    fontSize: "12px",
                                    color: "#9AA1A1",
                                }}
                                gap={theme.spacing.smallest}
                            >
                                <Checkbox
                                    isActive={isTNCActive}
                                    onToggle={(isActive: boolean) => {
                                        setIsTNCActive(!isTNCActive);
                                    }}
                                />
                                <Text lineHeight={20} small>
                                    I agree to Ostium’s Terms of Use and attest
                                    I am not a U.S. person, am not located in
                                    the U.S. and am not a resident of or located
                                    in a restricted jurisdiction.
                                </Text>
                            </Flex>
                        </Box>
                        <Button
                            style={{ marginTop: theme.spacing.huge }}
                            text="Next"
                            newPrimary
                            biggest
                            onPress={handleAcceptTnCClick}
                            disabled={!isTNCActive || loading}
                            loading={loading}
                        />
                    </Flex>
                ) : null}
                {onboardingStep === ONBOARDING_STEPS.FUND ? (
                    <Flex
                        style={{ gap: 24, marginTop: theme.spacing.bigger }}
                        direction="column"
                    >
                        <Text bigger>Fund Your Account</Text>
                        <Flex style={{ gap: 16 }} direction="column">
                            <Text sml style={{ color: theme.color.greyish }}>
                                There are 3 ways to deposit:
                            </Text>
                            <Flex style={{ gap: 12 }} direction="column">
                                <Flex style={{ gap: 16 }} align="center">
                                    <Box
                                        style={{
                                            width: 24,
                                            height: 24,
                                            borderRadius: 16,
                                            backgroundColor:
                                                "rgba(255, 255, 255, 0.03)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text
                                            sml
                                            style={{
                                                color: theme.color.rgba(
                                                    theme.color.greyish,
                                                    0.7
                                                ),
                                            }}
                                            mono
                                        >
                                            1
                                        </Text>
                                    </Box>
                                    <Text
                                        sml
                                        style={{
                                            color: theme.color.rgba(
                                                theme.color.greyish,
                                                0.7
                                            ),
                                        }}
                                    >
                                        Receive Funds from a Crypto Wallet
                                    </Text>
                                </Flex>
                                <Flex style={{ gap: 16 }} align="center">
                                    <Box
                                        style={{
                                            width: 24,
                                            height: 24,
                                            borderRadius: 16,
                                            backgroundColor:
                                                "rgba(255, 255, 255, 0.03)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text
                                            sml
                                            style={{
                                                color: theme.color.rgba(
                                                    theme.color.greyish,
                                                    0.7
                                                ),
                                            }}
                                            mono
                                        >
                                            2
                                        </Text>
                                    </Box>
                                    <Text
                                        sml
                                        style={{
                                            color: theme.color.rgba(
                                                theme.color.greyish,
                                                0.7
                                            ),
                                        }}
                                    >
                                        Transfer Funds from a Connected Wallet
                                    </Text>
                                </Flex>
                                <Flex style={{ gap: 16 }} align="center">
                                    <Box
                                        style={{
                                            width: 24,
                                            height: 24,
                                            borderRadius: 16,
                                            backgroundColor:
                                                "rgba(255, 255, 255, 0.03)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text
                                            sml
                                            style={{
                                                color: theme.color.rgba(
                                                    theme.color.greyish,
                                                    0.7
                                                ),
                                            }}
                                            mono
                                        >
                                            3
                                        </Text>
                                    </Box>
                                    <Text
                                        sml
                                        style={{
                                            color: theme.color.rgba(
                                                theme.color.greyish,
                                                0.7
                                            ),
                                        }}
                                    >
                                        Buy Funds with a Card
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex justify="space-between">
                                <Text
                                    smaller
                                    style={{
                                        color: theme.color.rgba(
                                            theme.color.greyish,
                                            0.7
                                        ),
                                    }}
                                >
                                    Be sure to only deposit
                                </Text>
                                <Flex
                                    style={{
                                        gap: 4,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        borderRadius: 10,
                                        backgroundColor:
                                            "rgba(208, 219, 218, 0.10)",
                                    }}
                                >
                                    <Image
                                        alt={"image"}
                                        width={14}
                                        // @ts-ignore
                                        height={14}
                                        selectable={false}
                                        source={getPairLogo("USDC")}
                                    />
                                    <Text
                                        smaller
                                        style={{
                                            color: theme.color.rgba(
                                                theme.color.greyish,
                                                0.7
                                            ),
                                        }}
                                    >
                                        USDC
                                    </Text>
                                    <Tooltip
                                        {...{
                                            title: "Deposit USDC",
                                            content:
                                                "Only send USDC on Arbitrum One",
                                            top: 10,
                                            position: "bottom-left",
                                            minWidth: 270,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                height: 13,
                                                width: 13,
                                            }}
                                            align="center"
                                            justify="center"
                                        >
                                            <Icon
                                                name="question"
                                                color={theme.color.rgba(
                                                    theme.color.white,
                                                    0.6
                                                )}
                                                size={10}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Box>
                            <Button
                                style={{ marginTop: theme.spacing.huge }}
                                text="Fund"
                                newPrimary
                                biggest
                                onPress={startDeposit}
                            />
                            <Button
                                style={{ marginTop: theme.spacing.bigger }}
                                text="Skip"
                                biggest
                                onPress={handleSkipFundClick}
                            />
                        </Box>
                    </Flex>
                ) : null}
                {onboardingStep === ONBOARDING_STEPS.START_TRADE ? (
                    <Flex
                        style={{ marginTop: theme.spacing.bigger }}
                        direction="column"
                    >
                        <Text
                            style={{
                                paddingLeft: theme.spacing.bigger,
                                paddingRight: theme.spacing.bigger,
                            }}
                            bigger
                        >
                            You’re Ready to Trade!
                        </Text>
                        <Flex
                            style={{
                                backgroundImage: `url(${getBaseS3URL(
                                    "onboarding_grid.svg"
                                )})`,
                                minHeight: 333,
                                backgroundSize: "cover",
                            }}
                            justify="center"
                        >
                            {/* <img
                                alt={"Ostium logo mark"}
                                src={"/onboarding_grid.svg"}
                                //@ts-ignore
                                width={400}
                                height={264}
                            /> */}
                        </Flex>
                        <Box
                            style={{
                                paddingLeft: theme.spacing.bigger,
                                paddingRight: theme.spacing.bigger,
                            }}
                        >
                            <Button
                                style={{
                                    marginTop: theme.spacing.huge,
                                }}
                                text="Show Me How"
                                newPrimary
                                biggest
                                onPress={handleShowMeHowClick}
                            />
                            <Button
                                style={{ marginTop: theme.spacing.bigger }}
                                text="Finish"
                                biggest
                                onPress={handleFinishClick}
                            />
                        </Box>
                    </Flex>
                ) : null}
            </Box>
        </Modal>
    );
};

export default OnboardingModal;

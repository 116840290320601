import { useEffect, useState } from "react";
import {
    SeasonsService,
    StandingsService,
    PointsService,
} from "../../../services/PointsService";
import {
    TradingStandingsResponse,
    TradingStandingsHookResult,
    LPStandingsResponse,
    LPStandingsHookResult,
} from "../types/standings";
import { Season, SeasonResponse, SeasonsListResponse } from "../types/seasons";
import type {
    PointsRankingsResponse,
    UserPointsByIdResponse,
} from "../types/points";

// Season hooks
export const useCurrentSeason = () => {
    const [season, setSeason] = useState<Season | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchCurrentSeason = async () => {
            try {
                setLoading(true);
                const data =
                    await SeasonsService.getCurrentSeason<SeasonResponse>();
                if (isMounted) {
                    setSeason(data.season);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchCurrentSeason();

        return () => {
            isMounted = false;
        };
    }, []);

    return { season, loading, error };
};

export const useSeasonById = (id: string) => {
    const [season, setSeason] = useState<Season | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchSeason = async () => {
            if (!id) return;

            try {
                setLoading(true);
                const data = await SeasonsService.getSeasonById<SeasonResponse>(
                    id
                );
                if (isMounted) {
                    setSeason(data.season);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchSeason();

        return () => {
            isMounted = false;
        };
    }, [id]);

    return { season, loading, error };
};

export const useSeasonMetrics = () => {
    const [metrics, setMetrics] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchMetrics = async () => {
            try {
                setLoading(true);
                const data = await SeasonsService.getSeasonMetrics();
                if (isMounted) {
                    setMetrics(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchMetrics();

        return () => {
            isMounted = false;
        };
    }, []);

    return { metrics, loading, error };
};

// Trading standings hooks
export const useCurrentTradingStandings = (): TradingStandingsHookResult => {
    const [standings, setStandings] = useState<TradingStandingsResponse>({
        standings: [],
        metadata: {
            totalOrders: 0,
            totalVolume: "0",
            calculationTime: 0,
            totalReferralBonus: "0",
            totalVolumeWithReferrals: "0",
            seasonId: "",
            lastCalculated: "",
        },
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchStandings = async () => {
            try {
                setLoading(true);

                const data =
                    await StandingsService.getCurrentTradingStandings<TradingStandingsResponse>();

                if (isMounted) {
                    setStandings(data);
                }
            } catch (err) {
                console.error("Error fetching standings:", err);
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchStandings();

        return () => {
            isMounted = false;
        };
    }, []);

    return { standings, loading, error };
};

export const useTradingStandingsBySeasonId = (seasonId: string) => {
    const [standings, setStandings] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchStandings = async () => {
            if (!seasonId) return;

            try {
                setLoading(true);
                const data =
                    await StandingsService.getTradingStandingsBySeasonId(
                        seasonId
                    );
                if (isMounted) {
                    setStandings(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchStandings();

        return () => {
            isMounted = false;
        };
    }, [seasonId]);

    return { standings, loading, error };
};

export const useFilteredTradingStandings = (
    startTime: number,
    endTime: number,
    limit = 50
) => {
    const [standings, setStandings] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchStandings = async () => {
            try {
                setLoading(true);
                const data = await StandingsService.getFilteredTradingStandings(
                    startTime,
                    endTime,
                    limit
                );
                if (isMounted) {
                    setStandings(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchStandings();

        return () => {
            isMounted = false;
        };
    }, [startTime, endTime, limit]);

    return { standings, loading, error };
};

// LP standings hooks
export const useCurrentLPStandings = (): LPStandingsHookResult => {
    const [standings, setStandings] = useState<LPStandingsResponse>({
        standings: [],
        metadata: {
            totalUsers: 0,
            totalShares: "0",
            totalActions: 0,
            calculationTime: 0,
            totalScore: "0",
            paidShare: 0,
            seasonId: "",
            lastCalculated: "",
        },
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchStandings = async () => {
            try {
                setLoading(true);

                const data =
                    await StandingsService.getCurrentLPStandings<LPStandingsResponse>();

                if (isMounted) {
                    setStandings(data);
                }
            } catch (err) {
                console.error("Error fetching standings:", err);
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchStandings();

        return () => {
            isMounted = false;
        };
    }, []);

    return { standings, loading, error };
};

export const useLPStandingsBySeasonId = (seasonId: string) => {
    const [standings, setStandings] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchStandings = async () => {
            if (!seasonId) return;

            try {
                setLoading(true);
                const data = await StandingsService.getLPStandingsBySeasonId(
                    seasonId
                );
                if (isMounted) {
                    setStandings(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchStandings();

        return () => {
            isMounted = false;
        };
    }, [seasonId]);

    return { standings, loading, error };
};

// Points hooks
export const usePointsBySeasonId = (seasonId: string) => {
    const [points, setPoints] = useState<PointsRankingsResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchPoints = async () => {
            if (!seasonId) return;

            try {
                setLoading(true);
                const data =
                    await PointsService.getPointsBySeasonId<PointsRankingsResponse>(
                        seasonId
                    );
                if (isMounted) {
                    setPoints(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchPoints();

        return () => {
            isMounted = false;
        };
    }, [seasonId]);

    return { points, loading, error };
};

export const usePointsByUserId = (userId: string) => {
    const [points, setPoints] = useState<UserPointsByIdResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchPoints = async () => {
            if (!userId) return;

            try {
                setLoading(true);
                const data =
                    await PointsService.getPointsByUserId<UserPointsByIdResponse>(
                        userId
                    );
                if (isMounted) {
                    setPoints(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchPoints();

        return () => {
            isMounted = false;
        };
    }, [userId]);

    return { points, loading, error };
};

export const useLeaderboard = (seasonId: string, page = 0, limit = 50) => {
    const [leaderboard, setLeaderboard] =
        useState<PointsRankingsResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchLeaderboard = async () => {
            if (!seasonId) return;

            try {
                setLoading(true);
                const data =
                    await PointsService.getLeaderboard<PointsRankingsResponse>(
                        seasonId,
                        page,
                        limit
                    );
                if (isMounted) {
                    setLeaderboard(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchLeaderboard();

        return () => {
            isMounted = false;
        };
    }, [seasonId, page, limit]);

    return { leaderboard, loading, error };
};

// Service functions for more direct calls
export const fetchServices = {
    getCurrentSeason: async () => {
        return await SeasonsService.getCurrentSeason();
    },

    getSeasonById: async (id: string) => {
        return await SeasonsService.getSeasonById(id);
    },

    getSeasonMetrics: async () => {
        return await SeasonsService.getSeasonMetrics();
    },
};

export const useAllSeasons = () => {
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchSeasons = async () => {
            try {
                setLoading(true);
                const data = await SeasonsService.getAllSeasons<Season[]>();
                if (isMounted) {
                    setSeasons(data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchSeasons();

        return () => {
            isMounted = false;
        };
    }, []);

    return { seasons, loading, error };
};

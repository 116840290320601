import { Flex } from "@atoms/Flex";
import { Image } from "@atoms/Image";
import { Text } from "@atoms/Text";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useNotificationContext } from "@contexts/notification";
import { AVAILABLE_CHAINS, useWeb3Context } from "@contexts/web3";
import { Button } from "@molecules/Button";
import { getChainLogo } from "@utils";
import { memo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { parseWeb3Error } from "utils";

export const NetworkSwitcher = memo(() => {
    const { notify } = useNotificationContext();
    const { address } = useOstiumAccount();
    const { currentChain, isOtherNetwork, switchChain } = useWeb3Context();
    const theme = useTheme();
    const logo = getChainLogo(currentChain.logo);

    const [isHovering, setIsHovering] = useState(false);

    const toggleChain = async () => {
        try {
            if (isOtherNetwork) {
                await switchChain();
                return;
            }
            await switchChain(
                currentChain.id === AVAILABLE_CHAINS.arbitrumSepolia.id
                    ? AVAILABLE_CHAINS.arbitrum.id
                    : AVAILABLE_CHAINS.arbitrumSepolia.id
            );
        } catch (err) {
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
        }
    };

    if (!address) return null;
    if (isOtherNetwork)
        return (
            <Button
                red
                small
                style={{ height: 32 }}
                text="Wrong Network"
                onPress={switchChain}
            />
        );

    return (
        <Pressable onPress={toggleChain}>
            <Flex
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                align="center"
                justify="center"
                gap={theme.spacing.smaller}
                style={{
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovering ? 0.2 : 0.1
                    ),
                    borderRadius: theme.radius.medium,
                    height: 32,
                    minWidth: 36,
                    paddingHorizontal: theme.spacing.small,
                }}
            >
                <Text smallest bold capitalize primary>
                    {currentChain.type}
                </Text>
                <Image
                    lazy
                    source={logo}
                    alt="chain-logo"
                    width={16}
                    height={16}
                    selectable={false}
                />
            </Flex>
        </Pressable>
    );
});

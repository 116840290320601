import { useWeb3Context } from "@contexts/web3";
import { useFundWallet } from "@privy-io/react-auth";
import { useCallback } from "react";
import { useOstiumAccount } from "./useOstiumAccount";
import { useFunkitCheckout } from "@funkit/connect";
import { AVAILABLE_CHAINS } from "@contexts/web3";
import { Address } from "viem";

const DEFAULT_DEPOSIT_AMOUNT = 0;
const DEFAULT_EXPIRY_TIME = 35 * 60 * 1000 // 35 minutes

interface DepositArbitrumUsdcProps {
  onExit?: () => void
}

function useFunkitDepositArbitrumUsdc({ onExit }: DepositArbitrumUsdcProps) {
  const { switchChain } = useWeb3Context();
  const { beginCheckout } = useFunkitCheckout({
    onClose: useCallback(() => {
      // Call the optional onExit callback
      onExit?.();
      // Switch back to the original chain when the modal is closed
      switchChain();
    }, [onExit, switchChain]),
  });
  return {
    startFunkitDeposit: useCallback(async (recipientAddr: Address) => {
      await beginCheckout({
        modalTitle: "Deposit",
        iconSrc: "https://sdk-cdn.fun.xyz/images/usdc.svg",
        targetChain: AVAILABLE_CHAINS.arbitrum.id.toString(),
        targetAsset: AVAILABLE_CHAINS.arbitrum.contracts.token as Address,
        targetAssetAmount: DEFAULT_DEPOSIT_AMOUNT,
        targetAssetTicker: AVAILABLE_CHAINS.arbitrum.symbol,
        checkoutItemTitle: AVAILABLE_CHAINS.arbitrum.symbol,
        customRecipient: recipientAddr,
        expirationTimestampMs: DEFAULT_EXPIRY_TIME
      })
    }, [beginCheckout])
  }
}

export function useDepositArbitrumUsdc({
  onExit
}: DepositArbitrumUsdcProps = {
  onExit: () => undefined
}) {
  const { currentChain } = useWeb3Context();
  const { address } = useOstiumAccount();
  const { fundWallet } = useFundWallet({
    onUserExited: onExit,
  });
  const { startFunkitDeposit } = useFunkitDepositArbitrumUsdc({ onExit });

  return useCallback(async () => {
    if (!address) return

    if (currentChain.type === "mainnet") {
      // Funkit deposit is only available on mainnet
      await startFunkitDeposit(address)
    } else {
      // Otherwise fallback to privy deposit
      fundWallet(address)
    }
  }, [address, startFunkitDeposit, currentChain.type, fundWallet])
}

import { useRef, useMemo, useCallback } from "react";
import { useNotificationContext } from "@contexts/notification";
import { useAppContext } from "@contexts/app";
import { InputRef } from "@molecules/Input";

export const useReferralHelpers = () => {
    const { userFE } = useAppContext();
    const { notify } = useNotificationContext();
    const inputRef = useRef<InputRef>(null);

    const refCode = useMemo(() => {
        return userFE?.referralCode || userFE?.accessCode || "";
    }, [userFE]);

    const accessCode = useMemo(() => {
        return userFE?.accessCode || "";
    }, [userFE]);

    const referralUrl = useMemo(() => {
        if (userFE?.referralCode) {
            const refParam = "ref=" + (userFE?.referralCode || "");
            const newUrl =
                "https://ostium.app/trade?from=CL&to=USD&" +
                (refParam ? refParam : "");
            return newUrl;
        }
        return "";
    }, [userFE]);

    const onCopy = useCallback(() => {
        notify({
            title: "Referral link copied to clipboard",
        });
        inputRef.current?.input?.focus?.();
        navigator.clipboard.writeText(referralUrl);
    }, [notify, referralUrl]);

    const onCodeCopy = useCallback(() => {
        notify({
            title: "Access code copied to clipboard",
        });
        inputRef.current?.input?.focus?.();
        //@ts-ignore
        navigator.clipboard.writeText(refCode);
    }, [notify, refCode]);

    return {
        refCode,
        accessCode,
        referralUrl,
        onCopy,
        onCodeCopy,
        inputRef,
    };
};

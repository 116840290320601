import { Box } from "@atoms/Flex";
import dynamic from "next/dynamic";
import { memo, useState } from "react";

export const SplineBackground = memo(({ delay = 200 }: { delay?: number }) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    // return (
    //     <video width="1080" height="1080" controls={false} autoPlay loop>
    //         <source src="https://static.ostium.io/assets/animation.mp4" type="video/mp4" />
    //         Your browser does not support the video tag.
    //     </video>
    // );

    const Spline = dynamic(() => import("@splinetool/react-spline"), {
        ssr: true,
    });

    return (
        <Box
            style={{
                width: "100%",
                height: "100%",
                transition: "1s opacity",
                willChange: "opacity",
                opacity: hasLoaded ? 1 : 0,
            }}
        >
            <Spline
                scene="https://prod.spline.design/O1wrkEDQIGeCI-D5/scene.splinecode"
                style={{
                    width: "100%",
                    height: "100%",
                }}
                onLoad={() => {
                    setTimeout(() => {
                        setHasLoaded(true);
                    }, delay);
                }}
            />
        </Box>
    );
});

import { createContext, useCallback, useContext, useMemo } from "react";
import { useAccount, useClient, useSwitchChain } from "wagmi";

import { providers } from "ethers";

import { PublicClient, Transport, WalletClient } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";

import { OstiumFaucet } from "./faucet/OstiumFaucet";
import { OstiumTrading } from "@ostium/smart-contracts/dist/typechain/src/OstiumTrading";
import { OstiumTradingStorage } from "@ostium/smart-contracts/dist/typechain/src/OstiumTradingStorage";
import { OstiumVault } from "@ostium/smart-contracts/dist/typechain/src/OstiumVault";
import { OstiumLockedDepositNft } from "@ostium/smart-contracts/dist/typechain/src/OstiumLockedDepositNft";

// TODO: Switch to simple wagmi implementation using only ABI and contract address vs using the SDK to connect to contract
// import OstiumTrading from
// "@ostium/smart-contracts/dist/artifacts/src/OstiumTrading.sol/OstiumTrading.json";

// @ts-ignore
import { OstiumVault__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumVault__factory";
// @ts-ignore
import { OstiumLockedDepositNft__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumLockedDepositNft__factory";
// @ts-ignore
import { OstiumFaucet__factory } from "./faucet/OstiumFaucet__factory";
// @ts-ignore
import { OstiumTrading__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumTrading__factory";
// @ts-ignore
import { OstiumTradingStorage__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumTradingStorage__factory";

import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ApolloProvider } from "@apollo/client/react/context";
import { IS_PRODUCTION } from "@constants";
import { MultiAPILink } from "@habx/apollo-multi-endpoint-link";
import { createClient } from "graphql-ws";
import { arbitrum, arbitrumSepolia } from "viem/chains";
import { useOstiumAccount } from "./hooks/useOstiumAccount";

interface IWeb3 {
    currentChain: CurrentChain;
    isOtherNetwork: boolean;
    provider: providers.JsonRpcProvider | providers.FallbackProvider;
    signer?: providers.JsonRpcSigner;
    tradingContract: OstiumTrading;
    storageContract: OstiumTradingStorage;
    faucetContract: OstiumFaucet;
    vaultContract: OstiumVault;
    lockedDepositNftContract: OstiumLockedDepositNft;
    switchChain: (chainId?: number) => Promise<void>;
    // publicClient: PublicClient;
    // walletClient: WalletClient;
}
const Web3Context = createContext({} as IWeb3);

export type CurrentChain = {
    id: number;
    type: "mainnet" | "testnet";
    name: "arbitrum" | "sepolia";
    explorer: string;
    contracts: {
        token: `0x${string}` | undefined;
        trading: `0x${string}`;
        storage: `0x${string}`;
        whitelist: `0x${string}`;
        vault: `0x${string}`;
        faucet: `0x${string}`;
        lockedDepositNft: `0x${string}`;
        tradingCallbacks: `0x${string}`;
    };
    symbol: string;
    logo: string;
    url: string;
};

export const CONTRACT_ADDRESS: {
    [key: string]: { [key: string]: `0x${string}` };
} = {
    arbitrum: {
        timelockOwnerAddress: "0xeB85dC6095c74D36500C9cdcaCc15EcDC223Bbf7",
        proxyAdminAddress: "0x083F97BabF33D4abC03151B5DEc98170761f4025",
        registryAddress: "0x799a139aE56e11F0476aCE2f6118CfcAed9608d2",
        vaultAddress: "0x20D419a8e12C45f88fDA7c5760bb6923Cee27F98",
        lockedDepositNftAddress: "0xb4f1123BE58f5d69E1cf565ED8756C7fcf31c8D3",
        timelockManagerAddress: "0xbd80d6Eb7D21F6bd3BbBAdf8b7E15F85ffe3888B",
        whitelistAddress: "0xe006fAb1ac752B4F0574746F02493B8aCFA3b537",
        tradingStorageAddress: "0xccd5891083a8acd2074690f65d3024e7d13d66e7",
        tradingAddress: "0x6D0bA1f9996DBD8885827e1b2e8f6593e7702411",
        tradingCallbacksAddress: "0x7720fC8c8680bF4a1Af99d44c6c265a74e9742a9",
        pairsStorageAddress: "0x260E349F643f12797fDc6f8c9d3df211D5577823",
        pairInfosAddress: "0x3890243a8fc091c626ed26c087a028b46bc9d66c",
        openPnlFeedAddress: "0xE607aC9FF58697c5978AfA1Fc1C5C437a6D1858c",
        tradesUpKeepAddress: "0x959Da1452238F71F17f7DA5dbA2e9c04FEf57324",
        privateTradesUpKeepAddress:
            "0x50B0457B69a4F85c98A044e0b9eB9C65B0D708f9",
        priceRouterAddress: "0x4B0C3c77D398912491f192d265b237C8d4441AD7",
        priceUpKeepAddress: "0x52B2a78E12b09B66C6c8ce291D653D40bAb77f0c",
        privatePriceUpKeepAddress: "0xB71ec9eBD8145daCaCF6724363143cb5667A3d36",
        linkUpKeepAddress: "0xcdBd6a8c40dD7E914aaBc7447A18cd90FFA93EAA",
        verifierAddress: "0xcCF233920e8cc9415ecF503b992881d69b6c47Ad",
        faucetAddress: "0x6830C550814105d8B27bDAEC0DB391cAa7B967c8",
        tokenAddress: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
    },
    arbitrumSepolia: {
        timelockManagerAddress: "0xbd80d6Eb7D21F6bd3BbBAdf8b7E15F85ffe3888B",
        timelockOwnerAddress: "0xbc7B65D3Aa1C38B39AC63f131D5245C51b83acbc",
        proxyAdminAddress: "0xaB5583ebf187b926e48DeB9e9bb13418255c665C",
        registryAddress: "0xf86cff7679BA3E99d21255d774088E25FE0ec34a",
        whitelistAddress: "0xe006fAb1ac752B4F0574746F02493B8aCFA3b537",
        vaultAddress: "0x2fbf52c8769c5da05afee7853b12775461cD04d2",
        tradingStorageAddress: "0x0b9F5243B29938668c9Cfbd7557A389EC7Ef88b8",
        tradingAddress: "0x2A9B9c988393f46a2537B0ff11E98c2C15a95afe",
        tradingCallbacksAddress: "0x83DC7c5dDeAD58f47230b70e6EF6bc44064BD814",
        pairsStorageAddress: "0x81e252CCF6BB99202220FDc0c5788bBd9e2473D0",
        pairInfosAddress: "0xEF5D3fC8A4651B32D2DAB967E1D91a67eCfa953E",
        openPnlFeedAddress: "0x27db8B73eC5cbaa17B4e7D3D3F07EBDb2eE3e154",
        tradesUpKeepAddress: "0x689135437c7363c4dD563Bc571E6a92Ddb868BCD",
        privateTradesUpKeepAddress:
            "0x50B0457B69a4F85c98A044e0b9eB9C65B0D708f9",
        priceRouterAddress: "0x77004c1097A3b6F25dcF55F900E3ee7B354cc5e7",
        priceUpKeepAddress: "0xc920f28FAcEf97fdA3a1F83ed8Ac2a0977844b4C",
        privatePriceUpKeepAddress: "0x5d3Af2Ab23a5F38c548151F507F6dded9979B328",
        lockedDepositNftAddress: "0xfFAd1f402030000C93152D38E384C202DD233445",
        linkUpKeepAddress: "0xcdBd6a8c40dD7E914aaBc7447A18cd90FFA93EAA",
        verifierAddress: "0x52C8c22BF47657C172e5D7a7FB2C1156916BAc46",
        faucetAddress: "0x6830C550814105d8B27bDAEC0DB391cAa7B967c8",
        tokenAddress: "0xe73B11Fb1e3eeEe8AF2a23079A4410Fe1B370548",
    },
};

export const AVAILABLE_CHAINS: {
    arbitrum: CurrentChain;
    arbitrumSepolia: CurrentChain;
} = {
    // Mainnet
    arbitrum: {
        id: arbitrum.id,
        type: "mainnet",
        name: "arbitrum",
        url: process.env.NEXT_PUBLIC_MAINNET_SUBGRAPH_URL ?? "",
        explorer: `${arbitrum.blockExplorers.default.url}/`,
        symbol: "USDC",
        logo: "arbitrum",
        contracts: {
            trading: CONTRACT_ADDRESS.arbitrum.tradingAddress,
            token: CONTRACT_ADDRESS.arbitrum.tokenAddress,
            storage: CONTRACT_ADDRESS.arbitrum.tradingStorageAddress,
            whitelist: CONTRACT_ADDRESS.arbitrum.whitelistAddress,
            vault: CONTRACT_ADDRESS.arbitrum.vaultAddress,
            faucet: CONTRACT_ADDRESS.arbitrum.faucetAddress,
            tradingCallbacks: CONTRACT_ADDRESS.arbitrum.tradingCallbacksAddress,
            lockedDepositNft: CONTRACT_ADDRESS.arbitrum.lockedDepositNftAddress,
        },
    },
    // Testnet addresses
    arbitrumSepolia: {
        id: arbitrumSepolia.id,
        type: "testnet",
        name: "sepolia",
        url: process.env.NEXT_PUBLIC_TESTNET_SUBGRAPH_URL ?? "",
        explorer: `${arbitrumSepolia.blockExplorers.default.url}/`,
        symbol: "USDC",
        logo: "arbitrum",
        contracts: {
            trading: CONTRACT_ADDRESS.arbitrumSepolia.tradingAddress,
            token: CONTRACT_ADDRESS.arbitrumSepolia.tokenAddress,
            storage: CONTRACT_ADDRESS.arbitrumSepolia.tradingStorageAddress,
            whitelist: CONTRACT_ADDRESS.arbitrumSepolia.whitelistAddress,
            vault: CONTRACT_ADDRESS.arbitrumSepolia.vaultAddress,
            faucet: CONTRACT_ADDRESS.arbitrumSepolia.faucetAddress,
            tradingCallbacks:
                CONTRACT_ADDRESS.arbitrumSepolia.tradingCallbacksAddress,
            lockedDepositNft:
                CONTRACT_ADDRESS.arbitrumSepolia.lockedDepositNftAddress,
        },
    },
};

// This is the default chain for the app used to do the initial load for PAIRS and USERS
export const DEFAULT_CHAIN =
    process.env.NODE_ENV === "development"
        ? AVAILABLE_CHAINS.arbitrumSepolia
        : AVAILABLE_CHAINS.arbitrum;

const useWeb3 = () => {
    // Memoize provider and signer to prevent recreating on every render
    const provider = useEthersProvider();
    const signer = useEthersSigner();

    const publicClient = useClient();
    const walletClient = useWalletClient();

    const { chainId } = useAccount();
    const { address } = useOstiumAccount();
    const { switchChainAsync: switchChainWagmi } = useSwitchChain();

    const currentChain = useMemo(() => {
        console.warn(chainId);
        switch (chainId) {
            case AVAILABLE_CHAINS.arbitrum.id:
                return AVAILABLE_CHAINS.arbitrum;
            case AVAILABLE_CHAINS.arbitrumSepolia.id:
                return AVAILABLE_CHAINS.arbitrumSepolia;
            default:
                return DEFAULT_CHAIN;
        }
    }, [chainId]);

    const switchChain = useCallback(
        async (chainId?: number) => {
            if (!chainId) {
                await switchChainWagmi({ chainId: DEFAULT_CHAIN.id });
                return;
            }

            await switchChainWagmi?.({ chainId });
        },
        [switchChainWagmi]
    );

    const isOtherNetwork = useMemo(() => {
        return chainId
            ? ![
                  AVAILABLE_CHAINS.arbitrumSepolia.id,
                  AVAILABLE_CHAINS.arbitrum.id,
              ].includes(chainId)
            : false;
    }, [chainId]);

    const contracts = useMemo(() => {
        if (!signer || !currentChain?.contracts) {
            return {
                tradingContract: null,
                storageContract: null,
                vaultContract: null,
                faucetContract: null,
            };
        }

        const tradingContract = OstiumTrading__factory?.connect(
            currentChain.contracts.trading,
            signer
        );
        const storageContract = OstiumTradingStorage__factory?.connect(
            currentChain.contracts.storage,
            signer
        );
        const vaultContract = OstiumVault__factory?.connect(
            currentChain.contracts.vault,
            signer
        );
        const faucetContract = OstiumFaucet__factory?.connect(
            currentChain.contracts.faucet,
            signer
        );

        return {
            tradingContract,
            storageContract,
            vaultContract,
            faucetContract,
        };
    }, [currentChain?.contracts, signer]);

    return {
        address,
        chainId,
        provider,
        signer,
        currentChain,
        isOtherNetwork,
        ...contracts,
        // publicClient,
        // walletClient,
        switchChain,
    };
};

export interface IWeb3Provider {
    children: JSX.Element | JSX.Element[];
}
const isDevEnv =
    process.env.NODE_ENV === "development" &&
    process.env.NEXT_PUBLIC_BACKEND_URL?.includes("localhost");

const Web3Provider = ({ children }: IWeb3Provider) => {
    const value = useWeb3();

    const SUBGRAPH_URL = useMemo(() => {
        switch (value.chainId) {
            case AVAILABLE_CHAINS.arbitrum.id:
                return AVAILABLE_CHAINS.arbitrum.url;
            case AVAILABLE_CHAINS.arbitrumSepolia.id:
                return AVAILABLE_CHAINS.arbitrumSepolia.url;
            default:
                return AVAILABLE_CHAINS.arbitrum.url;
        }
    }, [value.chainId]);

    const client = new ApolloClient({
        link: ApolloLink.from([
            new MultiAPILink({
                endpoints: {
                    main: `${isDevEnv ? `http://` : `https://`}${
                        process.env.NEXT_PUBLIC_BACKEND_URL
                    }/graphql`,
                    subgraph: SUBGRAPH_URL,
                    mainnet: process.env.NEXT_PUBLIC_MAINNET_SUBGRAPH_URL ?? "",
                    testnet: process.env.NEXT_PUBLIC_TESTNET_SUBGRAPH_URL ?? "",
                },
                defaultEndpoint: "main",
                httpSuffix: "",
                wsSuffix: "",
                createHttpLink,
                createWsLink: () =>
                    new GraphQLWsLink(
                        createClient({
                            url: `${
                                process.env.NODE_ENV === "development"
                                    ? `ws://`
                                    : `wss://`
                            }${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
                            retryAttempts: 5,
                            // more or less copied directly from https://github.com/enisdenjo/graphql-ws#retry-strategy
                            retryWait: async () => {
                                // after the the server becomes ready, wait for a second + random 100 - 200 ms timeout
                                // (avoid DDoSing) and try connecting again, but only in production
                                const delay = IS_PRODUCTION
                                    ? 100 + Math.random() * 100
                                    : 10;
                                await new Promise((resolve) =>
                                    setTimeout(resolve, delay)
                                );
                            },
                        })
                    ),
            }),
        ]),
        cache: new InMemoryCache(),
    });

    return (
        // @ts-ignore
        <Web3Context.Provider value={value}>
            <ApolloProvider client={client}>{children}</ApolloProvider>
        </Web3Context.Provider>
    );
};

const useWeb3Context = () => useContext(Web3Context);

export { Web3Context, Web3Provider, useWeb3Context };

export function publicClientToProvider(publicClient: PublicClient) {
    const { chain, transport } = publicClient;
    const network = {
        chainId: chain?.id,
        name: chain?.name,
        ensAddress: chain?.contracts?.ensRegistry?.address,
    };
    if (transport.type === "fallback") {
        return new providers.FallbackProvider(
            (transport.transports as ReturnType<Transport>[]).map(
                ({ value }) =>
                    //@ts-ignore
                    new providers.JsonRpcProvider(value?.url, network)
            )
        );
    }
    //@ts-ignore
    return new providers.JsonRpcProvider(transport.url, network);
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
    const publicClient = usePublicClient({ chainId });

    return useMemo(() => {
        // @ts-ignore
        return publicClientToProvider(publicClient);
    }, [publicClient]);
}

export function walletClientToSigner(walletClient: WalletClient) {
    const { account, chain, transport } = walletClient;
    const network = {
        chainId: chain?.id,
        name: chain?.name,
        ensAddress: chain?.contracts?.ensRegistry?.address,
    };
    //@ts-ignore
    const provider = new providers.Web3Provider(transport, network);
    const signer = provider.getSigner(account?.address as string);
    return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
    const { data: walletClient } = useWalletClient({ chainId });
    return useMemo(
        () => (walletClient ? walletClientToSigner(walletClient) : undefined),
        [walletClient]
    );
}

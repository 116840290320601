export const openPopup = (url: string) => {
    const width = 500;
    const height = 500;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
        url,
        "_blank",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
};

export const openTelegram = (referralUrl?: string) => {
    const encodedUrl = encodeURIComponent(referralUrl as string);
    openPopup(`https://t.me/share/url?url=${encodedUrl}`);
};

export const openTwitter = (referralUrl?: string) => {
    const encodedUrl = encodeURIComponent(referralUrl as string);
    openPopup(`https://x.com/intent/tweet?text=${encodedUrl}`);
};
import { darkTheme, FunkitConfig } from '@funkit/connect';
import Image from 'next/image';

export const FUNKIT_CONFIG: FunkitConfig = {
  appName: "Ostium",
  apiKey: process.env.NEXT_PUBLIC_FUNKIT_API_KEY || "",
  uiCustomizations: {
    confirmationScreen: {
      showTokenAmount: false,
      destinationConfig: {
        icon: (
          <Image
            src="https://sdk-cdn.fun.xyz/images/ostium.svg"
            alt="Ostium Icon"
            width={18}
            height={18}
          />
        ),
        text: 'Ostium Account',
        url: 'https://ostium.app/portfolio',
      }
    },
  },
  loginConfig: {
    web2: true,
    web3: true,
  },
};

const OSTIUM_ORANGE = 'var(--primary)'
const PRIMARY_TEXT = 'var(--white)'
const SECONDARY_TEXT = '#9CA4A3'
const OFF_BACKGROUND = '#252626'

export const FUNKIT_THEME = darkTheme({
  customFontFamily: 'inherit',
  borderRadius: 'small',
  accentColor: OSTIUM_ORANGE,
  customColors: {
    buttonBackground:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(208, 219, 218, 0.90)',
    buttonBackgroundHover:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), #D0DBDA',
    buttonIconBackgroundHover: OFF_BACKGROUND,
    focusedOptionBorder: OSTIUM_ORANGE,

    buttonPrimary: SECONDARY_TEXT,
    primaryText: PRIMARY_TEXT,
    secondaryText: SECONDARY_TEXT,
    lightStroke: '#383A3A',
    mediumStroke: '#383A3A',
    heavyStroke: '#636565',
    offBackground: OFF_BACKGROUND,
    offBackgroundComplimentary: OFF_BACKGROUND,
    modalBackground: '#181818',
    errorBackground: '#663A33',

    inputAmountQuickOptionBaseBackground: OFF_BACKGROUND,
    inputAmountQuickOptionHoverBackground: '#353535',
    inputAmountQuickOptionDisabledBackground: OFF_BACKGROUND,
    inputAmountQuickOptionFocusedBorder: OFF_BACKGROUND,

    youPayYouReceiveBackground: OFF_BACKGROUND,
    youPayYouReceiveBorder: OFF_BACKGROUND,
    youPayYouReceivePrimaryText: PRIMARY_TEXT,
    youPayYouReceiveSecondaryText: SECONDARY_TEXT,
  },
  customBorderRadiuses: {
    modalActionButton: '6px',
    modalActionButtonMobile: '6px',
    actionButton: '6px',
    modal: '12px',
    connectButton: '6px',
  },
});

import {
    CurrentTotalProfitP,
    CurrentTotalProfitRaw,
    CurrentTradeProfitRaw,
    GetCollateralInputFromNotional,
    GetCurrentRolloverFee,
    GetFundingRate,
    GetOpeningFee,
    GetPriceImpact,
    GetTradeFundingFee,
    GetTradeLiquidationPrice,
    GetTradeRolloverFee,
    PRECISION_12,
    PRECISION_18,
    PRECISION_2,
} from "@ostium/formulae";
import { BigNumber } from "@utils";
import { Pair, Trade, UserGroupStat } from "gql/graphql";
import { formatUnits, parseUnits } from "viem";
import { AssetClass, PositionTypes } from "./constants";
import { OpeningFeeTooltipContent } from "./OpeningFeeTooltipContent";

type FeeType = {
    label: string;
    value: number;
};

export type Fees = {
    value: number;
    label: string;
    description: string;
    breakdown: {
        label: string;
        value: number;
        description?: string;
        valueFormatted?: string;
        breakdown?: FeeType[];
        render?: JSX.Element;
    }[];
};

/**
 * Get the collateral value and size for a trade
 * @param sizeUnits - The size of the trade in the asset already formatted aka 1 if 1 BTC
 * @param isBuy - Whether the trade is a buy or sell aka long or short
 * @param leverage - The leverage for the trade as is not formatted
 * @param pair - The pair to get the collateral value and size for
 * @param marketPrice - The market price to get the collateral value and size for
 * @returns The collateral value and size for the trade
 */
export const getCollateralInputFromNotional = (
    sizeUnits: number, // amount in the asset already formatted aka 1 if 1 BTC
    isBuy: boolean,
    leverage: number,
    pair: Pair,
    marketPrice: {
        mid: number;
        bid: number;
        ask: number;
        isMarketOpen: boolean;
    }
): { collateralValue: number; size: number } => {
    if (!sizeUnits || !pair || !marketPrice) {
        return {
            collateralValue: 0,
            size: 0,
        };
    }
    const isOpen = true;
    const price = isBuy ? marketPrice.ask : marketPrice.bid;
    const { long, short } = getOI(pair, marketPrice.mid);

    const priceParsed = parseUnits(price.toString(), 18).toString();
    const notional = parseUnits(sizeUnits.toString(), 18).toString();
    const leverageParsed = parseUnits(leverage.toString(), 2).toString();
    const toleranceP = parseUnits("0.001", 18).toString();
    const oiDelta = long.current - short.current;
    const oiDeltaParsed = parseUnits(oiDelta.toString(), 6).toString();
    const maxIterations = 10;

    const [collateralValue, size] = GetCollateralInputFromNotional(
        notional,
        isOpen,
        isBuy,
        leverageParsed,
        priceParsed,
        pair.makerMaxLeverage,
        pair.makerFeeP,
        pair.takerFeeP,
        oiDeltaParsed,
        toleranceP,
        maxIterations
    );
    return {
        collateralValue: Number(formatUnits(BigInt(collateralValue), 6)),
        size: Number(formatUnits(BigInt(size), 18)),
    };
};

/**
 * Get the fees for a trade
 * @param collateral - The collateral for the trade
 * @param leverage - The leverage for the trade
 * @param pair - The pair to get the fees for
 * @param price - The price to get the fees for
 * @param type - The type of position to get the fees for
 * @returns The fees for the trade
 */
export const getFees = ({
    collateral,
    leverage,
    pair,
    price,
    type,
}: {
    collateral: number;
    leverage: number;
    price: number;
    type: PositionTypes;
    pair?: Pair;
}): Fees => {
    if (!pair)
        return {
            label: "Total Fees",
            description: `Total fees at open, including protocol opening fees and oracle price retrieval fee`,
            value: 0,
            breakdown: [
                {
                    label: "Opening Fee",
                    render: (
                        <OpeningFeeTooltipContent
                            takerFee={0}
                            makerFee={0}
                            makerFeePercent={0}
                            takerFeePercent={0}
                            openFeePercent={0}
                        />
                    ),
                    value: 0,
                    breakdown: [],
                },
            ],
        };

    const positionSizeNotional = BigNumber.from(
        parseUnits(collateral?.toString(), 6)
    )
        .mul(parseUnits(leverage?.toString(), 2))
        .div(PRECISION_2)
        .toString();

    // const oi = getOI(pair, price);

    // const oiLong = parseUnits(oi.long.current.toString(), 6).toString();
    // const oiShort = parseUnits(oi.short.current.toString(), 6).toString();

    const oiLong = BigNumber.from(pair.longOI)
        .mul(BigNumber.from(parseUnits(price.toFixed(18), 18)))
        .div(BigNumber.from(PRECISION_18))
        .div(PRECISION_12)
        .toString();

    const oiShort = BigNumber.from(pair.shortOI)
        .mul(BigNumber.from(parseUnits(price.toFixed(18), 18)))
        .div(BigNumber.from(PRECISION_18))
        .div(PRECISION_12)
        .toString();

    const openingFee = getOpeningFee(
        oiLong,
        oiShort,
        pair,
        type,
        positionSizeNotional,
        leverage
    );

    const takerFeePercent = pair.takerFeeP
        ? Number(formatUnits(pair.takerFeeP, 6))
        : 0;
    const makerFeePercent = pair.makerFeeP
        ? Number(formatUnits(pair.makerFeeP, 6))
        : 0;

    const takerFee =
        (Number(formatUnits(BigInt(openingFee?.takerAmount), 6)) *
            takerFeePercent) /
        100;

    const makerFee =
        (Number(formatUnits(BigInt(openingFee.makerAmount), 6)) *
            makerFeePercent) /
        100;

    const openFee = Number(formatUnits(BigInt(openingFee?.baseFee), 6));

    const openFeePercent =
        (takerFee ? takerFeePercent : 0) + (makerFee ? makerFeePercent : 0);

    let openingFeesBreakdown: FeeType[] = [];

    if (makerFee) {
        openingFeesBreakdown = [
            {
                label: "Maker Fee",
                value: makerFee,
            },
            ...openingFeesBreakdown,
        ];
    }

    if (takerFee) {
        openingFeesBreakdown = [
            {
                label: "Taker Fee",
                value: takerFee,
            },
            ...openingFeesBreakdown,
        ];
    }

    const totalFees = openFee;
    return {
        value: openFee,
        label: "Total Fees",
        description: `Total fees at open, including protocol opening fees and oracle price retrieval fee`,
        breakdown: [
            {
                label: "Fees",
                render: (
                    <OpeningFeeTooltipContent
                        takerFee={takerFee}
                        makerFee={makerFee}
                        makerFeePercent={makerFeePercent}
                        takerFeePercent={takerFeePercent}
                        openFeePercent={openFeePercent}
                    />
                ),
                value: totalFees,
                valueFormatted: " ", // do not display total here
            },
            {
                label: "Open / Close",
                value: openFee,
                valueFormatted: `${openFeePercent.toFixed(2)}% / 0.00%`,
            },
        ],
    };
};

export function getOpeningFee(
    oiLong: string,
    oiShort: string,
    pair: Pair,
    type: PositionTypes,
    positionSizeNotional: string,
    leverage: number
) {
    return GetOpeningFee(
        oiLong,
        oiShort,
        pair.makerFeeP,
        pair.takerFeeP,
        pair.makerMaxLeverage,
        type === PositionTypes.Long
            ? positionSizeNotional
            : BigNumber.from(positionSizeNotional).mul(-1)?.toString(),
        parseUnits(leverage?.toString(), 2)?.toString()
    );
}

/**
 * Get the open interest for a pair
 * @param pair - The pair to get the open interest for
 * @param marketPrice (optional) - The market price to use for the open interest calculation // 18 decimals unformatted
 * @returns The open interest for the pair
 */
export function getOI(pair: Pair, marketPrice?: number) {
    if (!pair) {
        return {
            short: {
                max: 0,
                current: 0,
            },
            long: {
                max: 0,
                current: 0,
            },
        };
    }

    let price;
    if (marketPrice) {
        price = marketPrice;
    } else if (pair.lastTradePrice) {
        price = Number(formatUnits(pair.lastTradePrice, 18));
    }

    if (pair && price) {
        const maxOI = Number(formatUnits(pair.maxOI, 6));

        const oiLong = Number(formatUnits(pair.longOI, 18)) * price;
        const oiShort = Number(formatUnits(pair.shortOI, 18)) * price;

        const maxOpenInterest = oiShort > oiLong ? oiShort : oiLong;
        const max = maxOpenInterest > maxOI ? maxOpenInterest : maxOI;

        return {
            short: {
                max: max,
                current: oiShort <= 0 ? 0 : oiShort,
            },
            long: {
                max: max,
                current: oiLong <= 0 ? 0 : oiLong,
            },
        };
    }
    return {
        short: {
            max: 0,
            current: 0,
        },
        long: {
            max: 0,
            current: 0,
        },
    };
}

/**
 * Get the funding rate for a pair
 * @param pair - The pair to get the funding rate for
 * @param blockNumber - The block number to get the funding rate for
 * @returns The funding rate for the pair
 */
export function getFundingRateFormatted(pair: Pair, blockNumber: bigint) {
    const result = getFundingRate(pair, blockNumber);

    return {
        long: Number(formatUnits(BigInt(result.accFundingLong), 18)),
        short: Number(formatUnits(BigInt(result.accFundingShort), 18)),
        latest: Number(formatUnits(BigInt(result.latestFundingRate), 18)),
        target: Number(formatUnits(BigInt(result.targetFr), 18)),
    };
}

/**
 * Get the funding rate for a pair
 * @param pair - The pair to get the funding rate for
 * @param blockNumber - The block number to get the funding rate for
 * @returns The funding rate for the pair
 */
export function getFundingRate(pair?: Pair, blockNumber?: bigint) {
    const EMPTY_RESULT = {
        accFundingLong: "0",
        accFundingShort: "0",
        latestFundingRate: "0",
        targetFr: "0",
    };
    if (!pair || !blockNumber) return EMPTY_RESULT;

    const {
        accFundingLong,
        accFundingShort,
        lastFundingRate,
        maxFundingFeePerBlock,
        lastFundingBlock,
        maxOI,
        hillInflectionPoint,
        hillPosScale,
        hillNegScale,
        springFactor,
        sFactorUpScaleP,
        sFactorDownScaleP,
    } = pair;

    const { long, short } = getOI(pair);

    const result = GetFundingRate(
        accFundingLong,
        accFundingShort,
        lastFundingRate,
        maxFundingFeePerBlock,
        lastFundingBlock,
        blockNumber.toString(),
        parseUnits(long.current.toString(), 6).toString(),
        parseUnits(short.current.toString(), 6).toString(),
        maxOI.toString(),
        hillInflectionPoint,
        hillPosScale,
        hillNegScale,
        springFactor,
        sFactorUpScaleP,
        sFactorDownScaleP
    );
    return result;
}

/**
 * Get the price impact for a pair
 * @param marketPrice - The market price to get the price impact for
 * @param isBuy - Whether the trade is a buy or sell
 * @param isOpen - Whether the trade is open or closed. Default is false
 * @returns The price impact for the pair
 */
export function getPriceImpactParsed(
    marketPrice: { mid: number; bid: number; ask: number },
    isBuy: boolean,
    isOpen: boolean = false
) {
    if (!marketPrice.mid) {
        return {
            priceImpactP: "0",
            priceAfterImpact: "0",
        };
    }
    return GetPriceImpact(
        parseUnits(marketPrice.mid.toString(), 18).toString(),
        parseUnits(marketPrice.bid.toString(), 18).toString(),
        parseUnits(marketPrice.ask.toString(), 18).toString(),
        isOpen,
        isBuy
    );
}

/**
 * Get the PNL for a trade
 * @param trade - The trade to get the PNL for
 * @param marketPrice - The market price to get the PNL for
 * @param blockNumber - The block number to get the PNL for
 * @returns The PNL for the trade
 */
export function getTradePNL(
    trade: Trade,
    marketPrice?: {
        mid: number;
        bid: number;
        ask: number;
        isMarketOpen?: boolean;
    },
    blockNumber?: bigint
) {
    if (!trade || !marketPrice || !blockNumber || !trade.pair)
        return {
            pnl: 0,
            pnlRaw: "0",
            pnlPercent: 0,
            rollover: 0,
            funding: 0,
            totalProfit: 0,
            netPNL: 0,
            netValue: 0,
            liquidationPrice: 0,
        };

    // Raw values
    const currentRolloverRaw = GetCurrentRolloverFee(
        trade.pair.accRollover,
        trade.pair.lastRolloverBlock,
        trade.pair.rolloverFeePerBlock,
        blockNumber.toString()
    );

    const rolloverRaw = BigInt(
        GetTradeRolloverFee(
            trade.rollover,
            currentRolloverRaw,
            trade.collateral,
            trade.leverage
        )
    );

    const fundingRateRaw = getFundingRate(trade.pair, blockNumber);

    const fundingRaw = BigInt(
        GetTradeFundingFee(
            trade.funding,
            trade.isBuy
                ? fundingRateRaw.accFundingLong
                : fundingRateRaw.accFundingShort,
            trade.collateral,
            trade.leverage
        )
    );

    const liquidationPrice = Number(
        formatUnits(
            BigInt(
                GetTradeLiquidationPrice(
                    trade.openPrice,
                    trade.isBuy,
                    trade.collateral,
                    trade.leverage,
                    rolloverRaw.toString(),
                    fundingRaw.toString()
                )
            ),
            18
        )
    );

    const priceImpactRaw = getPriceImpactParsed(
        marketPrice,
        trade.isBuy,
        false
    ).priceAfterImpact;

    const pnlRaw = BigInt(
        CurrentTradeProfitRaw(
            trade.openPrice,
            priceImpactRaw,
            trade.isBuy,
            trade.leverage,
            trade.highestLeverage,
            trade.collateral
        )
    );

    const totalProfitRaw = BigInt(
        CurrentTotalProfitRaw(
            trade.openPrice,
            priceImpactRaw,
            trade.isBuy,
            trade.leverage,
            trade.highestLeverage,
            trade.collateral,
            rolloverRaw.toString(),
            fundingRaw.toString()
        )
    );

    const pnlPercentRaw = BigInt(
        CurrentTotalProfitP(totalProfitRaw.toString(), trade.collateral)
    );

    // Parsed values
    const pnl = Number(formatUnits(pnlRaw, 6));
    const pnlPercent = Number(formatUnits(pnlPercentRaw, 6));
    const netPNL = Number(formatUnits(totalProfitRaw, 6));
    const netValue =
        Number(formatUnits(totalProfitRaw, 6)) +
        Number(formatUnits(trade.collateral, 6));
    const totalProfit = Number(formatUnits(totalProfitRaw, 6));
    const funding = Number(formatUnits(fundingRaw, 6));
    const rollover = Number(formatUnits(rolloverRaw, 6));

    return {
        pnl,
        pnlRaw,
        pnlPercent,
        rollover,
        funding,
        fundingRaw,
        rolloverRaw,
        totalProfit,
        netPNL,
        netValue,
        liquidationPrice,
        priceImpact: Number(formatUnits(BigInt(priceImpactRaw), 18)),
        priceImpactRaw,
    };
}

/**
 * Get the user collateral metadata for a list of trades and user group stats
 * @param trades - The list of trades to get the user collateral metadata for
 * @param userGroupStats - The list of user group stats to get the user collateral metadata for
 * @param type - The type of collateral metadata to get
 * @returns The user collateral metadata for the list of trades and user group stats
 */
export function getUserCollateralMetadata(
    trades: Trade[],
    userGroupStats: UserGroupStat[],
    type: string
) {
    let results = new Map(
        [
            {
                id: "commodities",
                color: "#D69A00",
                label: AssetClass.Commodity,
                total: 0,
            },
            {
                id: "crypto",
                color: "#005BDB",
                label: AssetClass.Crypto,
                total: 0,
            },
            {
                id: "forex",
                color: "#5CBAB4",
                label: AssetClass.Forex,
                total: 0,
            },
            {
                id: "indices",
                color: "#FF0000",
                label: AssetClass.Indices,
                total: 0,
            },
            // {
            //     id: "stocks",
            //     color: "#C4008F",
            //     label: AssetClass.Stocks,
            //     total: 0,
            // },
        ].map((item) => [item.id, item])
    );

    switch (type) {
        case "open":
            trades?.map((trade: Trade) => {
                const foundResult = results.get(trade.pair.group.name);
                if (foundResult?.id === trade.pair.group.name) {
                    results.set(trade.pair.group.name, {
                        ...foundResult,
                        total:
                            foundResult?.total +
                            Number(formatUnits(trade.collateral, 6)),
                    });
                }
            });
            results.forEach((value, key) => {
                if (!results?.get(key)?.total) {
                    results.delete(key);
                }
            });
            break;
        case "closed":
            userGroupStats.map((item: UserGroupStat) => {
                const foundResult = results.get(item.group.name);

                if (foundResult) {
                    results.set(item.group.name, {
                        ...foundResult,
                        total: Number(
                            formatUnits(
                                item.totalClosedCollateral?.toString(),
                                6
                            )
                        ),
                    });
                }
            });
            break;
        default: {
            trades?.map((trade: Trade) => {
                const foundResult = results.get(trade.pair.group.name);
                if (foundResult?.id === trade.pair.group.name) {
                    results.set(trade.pair.group.name, {
                        ...foundResult,
                        total:
                            foundResult?.total +
                            Number(formatUnits(trade.collateral, 6)),
                    });
                }
            });

            userGroupStats?.map((item: UserGroupStat) => {
                const foundResult = results.get(item.group.name);

                if (foundResult) {
                    results.set(item.group.name, {
                        ...foundResult,
                        total:
                            foundResult.total +
                            Number(
                                formatUnits(
                                    item.totalClosedCollateral?.toString(),
                                    6
                                )
                            ),
                    });
                }
            });

            break;
        }
    }

    return [...results.values()];
}

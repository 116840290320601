import { CurrentChain, useWeb3Context } from "@contexts/web3";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatUnits } from "viem";
import { useBalance as useBalanceWagmi, useBlockNumber } from "wagmi";
import { usePrevious } from "./usePrevious";

export function useTokenBalance({
    tokenAddress,
    address,
    watch,
}: {
    tokenAddress?: `0x${string}`;
    address?: `0x${string}`;
    watch?: boolean;
}) {
    const { currentChain } = useWeb3Context();
    const { data: blockNumber } = useBlockNumber({
        watch,
    });
    const { refetch, isLoading } = useBalanceWagmi({
        address: address,
        token: tokenAddress,
    });

    const [balance, setBalance] = useState({
        value: BigInt(0),
        decimals: 0,
        symbol: "",
        formatted: "0",
    });

    const init = useCallback(async () => {
        try {
            const result = await refetch();
            setBalance(
                result.data || {
                    value: BigInt(0),
                    decimals: 0,
                    symbol: "",
                    formatted: "0",
                }
            );
        } catch (err) {
            console.warn(err);
            setBalance({
                value: BigInt(0),
                decimals: 0,
                symbol: "",
                formatted: "0",
            });
        }
    }, [refetch]);

    useEffect(() => {
        if (!address) return;
        init();
    }, [address, blockNumber, currentChain.id, init, refetch]);

    const previousFormatted = usePrevious(
        balance?.value
            ? Number(formatUnits(balance?.value, balance?.decimals))
            : 0
    );

    const formatted = useMemo(() => {
        if (balance?.value) {
            return Number(formatUnits(balance?.value, balance?.decimals));
        }
        return previousFormatted;
    }, [balance?.decimals, balance?.value, previousFormatted]);

    return {
        blockNumber,
        isLoading,
        balance: {
            ...balance,
            formatted,
        },
        refetch,
    };
}

import { Box } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { useWeb3Context } from "@contexts/web3";
import { useMedia } from "@hooks/useMedia";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { useRouter } from "next/router";

export const TradeLayout = ({ children }: { children: JSX.Element }) => {
    const { isCTAActive, showedLaunchBanners } = useAppContext();
    const { isOtherNetwork } = useWeb3Context();
    const { isMobile } = useMedia();
    const router = useRouter();
    const isRestricted = router?.query?.restricted;

    return (
        <Box
            style={{
                flex: 1,
            }}
        >
            <Box
                style={[
                    {
                        width: "100%",
                        alignItems: "center",
                        flex: 1,
                        paddingTop: isMobile
                            ? HEADER_HEIGHT +
                              (isCTAActive
                                  ? !showedLaunchBanners &&
                                    (isOtherNetwork || isRestricted)
                                      ? CTA_HEIGHT + 90
                                      : CTA_HEIGHT + 60
                                  : 0)
                            : HEADER_HEIGHT +
                              (isCTAActive
                                  ? !showedLaunchBanners &&
                                    (isOtherNetwork || isRestricted)
                                      ? CTA_HEIGHT + 30
                                      : CTA_HEIGHT
                                  : 0),
                    },
                ]}
            >
                <Box
                    style={{
                        width: "100%",
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

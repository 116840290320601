import { CurrencyInputRef } from "@molecules/Input";
import { IMark } from "@molecules/Slider";
import { GetStopLossPrice, GetTakeProfitPrice } from "@ostium/formulae/src";
import { STOP_LOSS_MARKS, TAKE_PROFIT_MARKS } from "@screens/Trade/utils";
import { formatPrice } from "@utils";
import { Pair } from "gql/graphql";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatData } from "utils";
import { formatUnits, parseUnits } from "viem";

export function useTakeProfitStopLoss({
    currentStopLoss,
    currentTakeProfit,
    price,
    openPrice,
    isBuy,
    leverage,
    highestLeverage,
    pair,
}: {
    pair?: Pair;
    currentStopLoss?: number;
    currentTakeProfit?: number;
    price: string;
    isBuy: boolean;
    leverage: number;
    highestLeverage: number;
    openPrice: string;
}) {
    const [stopLoss, setStopLoss] = useState(currentStopLoss || 0);
    const [takeProfit, setTakeProfit] = useState(currentTakeProfit || 0);

    const stopLossInputRef = useRef<CurrencyInputRef>(null);
    const takeProfitInputRef = useRef<CurrencyInputRef>(null);

    const formattedPrice = Number(formatUnits(BigInt(price), 18).toString());

    const stopLossPercent = useMemo(() => {
        if (!leverage) return 0;
        // if (stopLoss == null) return 0;

        const { getProfitPercent } = formatData({
            getProfitPercent: {
                openPrice,
                currentPrice: parseUnits(stopLoss?.toString(), 18).toString(),
                isBuy: isBuy,
                leverage: parseUnits(leverage.toString(), 2).toString(),
                highestLeverage: parseUnits(
                    highestLeverage.toString(),
                    2
                ).toString(),
            },
        });

        return getProfitPercent || 0;
    }, [stopLoss, openPrice, isBuy, leverage, highestLeverage]);

    const takeProfitPercent = useMemo(() => {
        if (!leverage) return 0;
        // if (takeProfit == null) return 0;

        const { getProfitPercent } = formatData({
            getProfitPercent: {
                openPrice,
                currentPrice: parseUnits(takeProfit?.toString(), 18).toString(),
                isBuy: isBuy,
                leverage: parseUnits(leverage.toString(), 2).toString(),
                highestLeverage: parseUnits(
                    highestLeverage.toString(),
                    2
                ).toString(),
            },
        });

        return getProfitPercent || 0;
    }, [takeProfit, openPrice, isBuy, leverage, highestLeverage]);

    const initialSelectedStopLoss = useMemo(() => {
        const foundMark = STOP_LOSS_MARKS.find((item) => {
            return (
                Math.abs(item.value) === Math.abs(Math.trunc(stopLossPercent))
            );
        });

        return foundMark ?? null;
    }, [stopLossPercent]);

    const [selectedStopLoss, setSelectedStopLoss] = useState<IMark | null>(
        initialSelectedStopLoss
    );

    const initialSelectedTakeProfit = useMemo(() => {
        const foundMark = TAKE_PROFIT_MARKS.find((item) => {
            return (
                Math.abs(item.value) === Math.abs(Math.trunc(takeProfitPercent))
            );
        });

        return foundMark ?? null;
    }, [takeProfitPercent]);

    const [selectedTakeProfit, setSelectedTakeProfit] = useState<IMark | null>(
        initialSelectedTakeProfit
    );

    const maxTakeProfitPercent = useMemo(() => {
        let tmpCap = TAKE_PROFIT_MARKS[TAKE_PROFIT_MARKS.length - 1].value;
        const leverageToUse =
            leverage > highestLeverage ? leverage : highestLeverage;
        if (!isBuy) {
            if (leverageToUse < 9) tmpCap = leverageToUse * 100;
        }

        return (tmpCap * leverage) / leverageToUse;
    }, [isBuy, leverage, highestLeverage]);

    const takeProfitMarks: IMark[] = useMemo(() => {
        if (!leverage) return TAKE_PROFIT_MARKS;
        if (!isBuy) {
            return TAKE_PROFIT_MARKS.map((mark) => {
                if (mark.value > maxTakeProfitPercent) {
                    return { ...mark, disabled: true };
                }
                return mark;
            });
        }
        return TAKE_PROFIT_MARKS;
    }, [leverage, isBuy, maxTakeProfitPercent]);

    const stopLossMarks: IMark[] = useMemo(() => {
        return STOP_LOSS_MARKS;
    }, []);

    const minTakeProfit = useMemo(() => {
        if (!leverage) return 0;
        if (isBuy) return formattedPrice;

        const leverageToUse =
            leverage > highestLeverage ? leverage : highestLeverage;

        return (
            Number(
                formatUnits(
                    BigInt(
                        GetTakeProfitPrice(
                            openPrice,
                            parseUnits(
                                TAKE_PROFIT_MARKS[
                                    TAKE_PROFIT_MARKS.length - 1
                                ].value.toString(),
                                6
                            ).toString(),
                            parseUnits(leverageToUse.toString(), 2).toString(),
                            isBuy
                        )
                    ),
                    18
                )
            ) || 0
        );
    }, [isBuy, leverage, openPrice, formattedPrice, highestLeverage]);

    const minStopLoss = useMemo(() => {
        if (!leverage) return 0;
        if (isBuy)
            return (
                Number(
                    formatUnits(
                        BigInt(
                            GetStopLossPrice(
                                openPrice,
                                parseUnits(
                                    Math.abs(
                                        STOP_LOSS_MARKS[
                                            STOP_LOSS_MARKS.length - 1
                                        ].value
                                    ).toString(),
                                    6
                                ).toString(),
                                parseUnits(leverage.toString(), 2).toString(),
                                isBuy
                            )
                        ),
                        18
                    )
                ) || 0
            );

        return formattedPrice;
    }, [isBuy, leverage, openPrice, formattedPrice]);

    const maxStopLoss = useMemo(() => {
        if (!leverage) return 0;
        if (isBuy) return formattedPrice;
        return (
            Number(
                formatUnits(
                    BigInt(
                        GetStopLossPrice(
                            openPrice,
                            parseUnits(
                                Math.abs(
                                    STOP_LOSS_MARKS[STOP_LOSS_MARKS.length - 1]
                                        .value
                                ).toString(),
                                6
                            ).toString(),
                            parseUnits(leverage.toString(), 2).toString(),
                            isBuy
                        )
                    ),
                    18
                )
            ) || 0
        );
    }, [leverage, isBuy, openPrice, formattedPrice]);

    const maxTakeProfit = useMemo(() => {
        if (!leverage) return 0;
        const leverageToUse =
            leverage > highestLeverage ? leverage : highestLeverage;
        if (isBuy)
            return (
                Number(
                    formatUnits(
                        BigInt(
                            GetTakeProfitPrice(
                                openPrice,
                                parseUnits(
                                    TAKE_PROFIT_MARKS[
                                        TAKE_PROFIT_MARKS.length - 1
                                    ].value.toString(),
                                    6
                                ).toString(),
                                parseUnits(
                                    leverageToUse.toString(),
                                    2
                                ).toString(),
                                isBuy
                            )
                        ),
                        18
                    )
                ) || 0
            );

        return formattedPrice;
    }, [leverage, isBuy, openPrice, formattedPrice, highestLeverage]);

    const isTakeProfitValid = useMemo(() => {
        if (takeProfit > maxTakeProfit)
            return {
                valid: false,
                label: "Too big",
            };
        if (takeProfit < minTakeProfit)
            return {
                valid: false,
                label: "Too small",
            };
        return {
            valid: true,
        };
    }, [takeProfit, maxTakeProfit, minTakeProfit]);

    const isStopLossValid = useMemo(() => {
        if (stopLoss > maxStopLoss)
            return {
                valid: false,
                label: "Too big",
            };
        if (stopLoss < minStopLoss)
            return {
                valid: false,
                label: "Too small",
            };
        return {
            valid: true,
        };
    }, [stopLoss, maxStopLoss, minStopLoss]);

    const takeProfitLabel = useMemo(() => {
        if (selectedTakeProfit) return `${selectedTakeProfit.value}%`;
        if (!takeProfit) return "";
        if (isBuy) {
            if (takeProfit > maxTakeProfit) return "Too big";
            if (takeProfit < minTakeProfit) return "Too small";
        } else {
            if (takeProfit > maxTakeProfit) return "Too big";
            if (takeProfit < minTakeProfit) return "Too small";
        }

        return `${formatPrice(takeProfitPercent, { decimals: 0 })}%`;
    }, [
        isBuy,
        takeProfit,
        maxTakeProfit,
        minTakeProfit,
        takeProfitPercent,
        selectedTakeProfit,
    ]);

    const stopLossLabel = useMemo(() => {
        if (selectedStopLoss) return `${selectedStopLoss.value}%`;
        if (!stopLoss) return "";
        if (stopLoss > maxStopLoss) return "Too big";
        if (stopLoss < minStopLoss) return "Too small";
        return `${formatPrice(stopLossPercent, {
            decimals: 0,
        })}%`;
    }, [selectedStopLoss, stopLoss, stopLossPercent, minStopLoss, maxStopLoss]);

    useEffect(() => {
        if (stopLoss === 0) {
            setSelectedStopLoss(null);
        }
    }, [stopLoss]);

    const onChangeTakeProfitMark = useCallback(
        (mark: IMark) => {
            const newTakeProfit = Number(
                formatUnits(
                    BigInt(
                        GetTakeProfitPrice(
                            openPrice,
                            parseUnits(mark.value.toString(), 6).toString(),
                            parseUnits(leverage.toString(), 2).toString(),
                            isBuy
                        )
                    ),
                    18
                )
            );

            if (!isBuy) {
                if (Number(leverage) * 100 === mark?.value) {
                    if (takeProfit === 0) {
                        const foundMark = TAKE_PROFIT_MARKS.find(
                            (mark) => mark.value === 100
                        );

                        if (foundMark) {
                            takeProfitInputRef?.current?.reset();
                            setTakeProfit(0);
                            setSelectedTakeProfit(foundMark);
                            return;
                        }
                    }
                }
            }

            setTakeProfit(newTakeProfit);
            setSelectedTakeProfit(mark);
        },
        [isBuy, leverage, openPrice, takeProfit]
    );

    const onChangeStopLossMark = useCallback(
        (mark: IMark) => {
            const newStopLoss = Number(
                formatUnits(
                    BigInt(
                        GetStopLossPrice(
                            openPrice,
                            parseUnits(
                                Math.abs(mark.value).toString(),
                                6
                            ).toString(),
                            parseUnits(leverage.toString(), 2).toString(),
                            isBuy
                        )
                    ),
                    18
                )
            );

            if (mark.value === 0 || newStopLoss < 0) {
                stopLossInputRef?.current?.reset();
                setSelectedStopLoss(null);
                setStopLoss(0);
                return;
            }

            setStopLoss(newStopLoss);
            setSelectedStopLoss(mark);
        },
        [isBuy, leverage, openPrice]
    );

    useEffect(() => {
        if (selectedStopLoss) onChangeStopLossMark(selectedStopLoss);
    }, [
        isBuy,
        selectedStopLoss,
        pair,
        openPrice,
        leverage,
        onChangeStopLossMark,
    ]);

    useEffect(() => {
        if (selectedTakeProfit) {
            onChangeTakeProfitMark(selectedTakeProfit);
        }
    }, [
        pair,
        price,
        isBuy,
        leverage,
        selectedTakeProfit,
        takeProfit,
        maxTakeProfitPercent,
        onChangeTakeProfitMark,
    ]);

    const onChangeStopLoss = (value: number) => {
        if (selectedStopLoss) setSelectedStopLoss(null);
        setStopLoss(value);
    };

    const onChangeTakeProfit = (value: number) => {
        if (selectedTakeProfit) setSelectedTakeProfit(null);

        setTakeProfit(value);
    };

    const onBlurTakeProfit = (value: number) => {
        if (isBuy) {
            if (value > maxTakeProfit)
                setSelectedTakeProfit(
                    takeProfitMarks[takeProfitMarks.length - 1]
                );
            if (value < minTakeProfit)
                setSelectedTakeProfit({
                    text: "0",
                    value: 0,
                    disabled: false,
                });
        } else {
            if (value > maxTakeProfit)
                setSelectedTakeProfit({
                    text: "0",
                    value: 0,
                    disabled: false,
                });
            if (value < minTakeProfit)
                setSelectedTakeProfit(
                    takeProfitMarks[takeProfitMarks.length - 1]
                );
        }
    };

    const onBlurStopLoss = (value: number) => {
        if (isBuy) {
            if (value > maxStopLoss) setSelectedStopLoss(stopLossMarks[0]);
            if (value < minStopLoss)
                setSelectedStopLoss(stopLossMarks[stopLossMarks.length - 1]);
        } else {
            if (value > maxStopLoss)
                setSelectedStopLoss(stopLossMarks[stopLossMarks.length - 1]);
            if (value < minStopLoss) setSelectedStopLoss(stopLossMarks[0]);
        }
    };

    return {
        takeProfit,
        takeProfitLabel,
        takeProfitPercent,
        takeProfitInputRef,
        takeProfitMarks,
        selectedTakeProfit,
        maxTakeProfit,
        minTakeProfit,
        maxTakeProfitPercent,
        setSelectedTakeProfit,
        onChangeTakeProfit,
        onChangeTakeProfitMark,
        onBlurTakeProfit,
        isStopLossValid,
        isTakeProfitValid,

        stopLoss,
        stopLossLabel,
        stopLossPercent,
        selectedStopLoss,
        stopLossInputRef,
        minStopLoss,
        maxStopLoss,
        setSelectedStopLoss,
        onChangeStopLoss,
        onChangeStopLossMark,
        onBlurStopLoss,
    };
}

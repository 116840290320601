import { Avatar } from "@atoms/Avatar";
import { BoxContainer } from "@atoms/BoxContainer";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import {
    getPairKey,
    getPrice,
    PriceProvider,
    usePriceContext,
} from "@contexts/price";
import { Image } from "@atoms/Image";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useWeb3Context } from "@contexts/web3";
import { gql, useQuery } from "@hooks/useApollo";
import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import { Statistic } from "@molecules/Statistic";
import { Tooltip } from "@molecules/Tooltip";
import { GET_USER } from "@screens/Portfolio/Positions/PortfolioStatistics";
import { getTradePNL } from "@screens/Trade/utils";
import { HEADER_HEIGHT } from "@screens/components/Header";
import {
    formatAddress,
    formatLargeNumber,
    formatPrice,
    getBaseS3URL,
} from "@utils";
import { User } from "gql/graphql";
import Link from "next/link";
import { memo, useCallback, useMemo, useState } from "react";
import { Linking, Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";
import { useNotificationContext } from "@contexts/notification";

export const Overview = memo(({ address }: { address?: `0x${string}` }) => {
    const theme = useTheme();
    const { settings, userFE } = useAppContext();
    // const today = moment().format("YYYY-MM-DD");
    // const currentDayFormatted = useMemo(() => {
    //     return currentDay && moment().diff(currentDay?.dateString, "days") > 0
    //         ? moment(currentDay?.dateString).format("d MMM yyyy")
    //         : "Today";
    // }, [currentDay]);
    const [currentDay, setCurrentDay] = useState();
    const [markedDates, setMarkedDates] = useState({});

    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const onDayPress = (day: any) => {
        setCurrentDay(day);
        setMarkedDates({
            [day.dateString]: {
                selected: true,
                color: theme.color.primary,
            },
        });
        toggleActive();
    };

    return (
        <Flex gap={settings.theme.boxed ? theme.spacing.smaller : 0}>
            <BoxContainer
                style={{
                    flex: 1,
                    borderWidth: 0,
                }}
            >
                <Box
                    style={
                        {
                            // borderBottomWidth: 1,
                            // borderColor: theme.color.rgba(theme.color.white, 0.05),
                        }
                    }
                >
                    <PriceProvider>
                        <UserStatistics address={address} />
                    </PriceProvider>
                </Box>
                {/* <Box
                    style={{
                        height: 300,
                        padding: theme.spacing.bigger,
                        position: "relative",
                    }}
                >
                    <PNLGraph />
                </Box> */}
            </BoxContainer>
        </Flex>
    );
});

const UserStatistics = memo(({ address }: { address?: `0x${string}` }) => {
    const { currentChain } = useWeb3Context();
    const theme = useTheme();
    const media = useMedia();
    const { address: myAddress } = useOstiumAccount();
    const { initial, toggleConnectModal, userFE, setShowUsernameModal } =
        useAppContext();
    const { prices } = usePriceContext();

    const { data: blockNumber } = useBlockNumber({
        watch: true,
    });
    const { notify } = useNotificationContext();
    const [user, setUser] = useState<User>(initial?.user as User);
    const isMine =
        myAddress != null && address != null && myAddress === address;

    useQuery(GET_USER, {
        variables: {
            id: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
        onCompleted: (data) => {
            setUser(data?.user);
        },
    });

    const { data: tradesData } = useQuery(GET_USER_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
    });
    const formattedAddress = useMemo(() => {
        return formatAddress(address);
    }, [address]);

    const pnl = useMemo(
        () =>
            tradesData?.trades?.reduce(
                (acc: number, trade: any) => {
                    const marketPrice = getPrice(trade.pair, prices);
                    const data = getTradePNL(trade, marketPrice, blockNumber);

                    return acc + data.netPNL;
                },
                user?.totalPnL ? Number(formatUnits(user?.totalPnL, 6)) : 0
            ),
        [blockNumber, prices, tradesData?.trades, user?.totalPnL]
    );

    const onExplorer = () => {
        Linking.openURL(`${currentChain?.explorer}address/${address}`);
    };

    const renderStatistics = useMemo(() => {
        return (
            <Box
                style={{
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: media.isMobile ? theme.spacing.bigger : 0,
                }}
            >
                <Statistic
                    label="Profit & Loss"
                    red={pnl < 0}
                    green={pnl > 0}
                    loading={!pnl}
                    value={`${formatPrice(pnl, {
                        decimals: 2,
                        currency: true,
                    })}`}
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        borderRightWidth: 1,
                        borderLeftWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.05),
                        alignItems: "flex-end",
                    }}
                />

                <Statistic
                    label="Total Volume"
                    loading={!user?.totalVolume}
                    value={
                        user?.totalVolume
                            ? `${formatLargeNumber(
                                  Number(formatUnits(user?.totalVolume, 6))
                              )}`
                            : 0
                    }
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        borderRightWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.05),
                        alignItems: "flex-end",
                    }}
                />

                <Statistic
                    label="Gain vs Loss"
                    loading={!user?.totalProfitTrades}
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        alignItems: "flex-end",
                    }}
                >
                    <Flex align="flex-end">
                        <Text mono bold color={theme.color.green}>
                            {user?.totalProfitTrades}
                        </Text>
                        <Text
                            mono
                            bold
                            color={theme.color.rgba(theme.color.white, 0.4)}
                        >
                            {" - "}
                        </Text>
                        <Text mono bold color={theme.color.red}>
                            {user?.totalLossTrades}
                        </Text>
                    </Flex>
                </Statistic>
            </Box>
        );
    }, [
        media.isMobile,
        pnl,
        theme.color,
        theme.spacing.bigger,
        user?.totalLossTrades,
        user?.totalProfitTrades,
        user?.totalVolume,
    ]);

    const onCopyAddress = useCallback(() => {
        if (address) {
            notify({
                title: "Address copied to clipboard",
            });
            navigator.clipboard.writeText(address);
        }
    }, [notify, address]);

    return (
        <Flex
            style={
                media.isMobile
                    ? {
                          flexDirection: "column",
                      }
                    : {
                          height: HEADER_HEIGHT + theme.spacing.biggest,
                          flexDirection: "row",
                          justifyContent: "space-between",
                      }
            }
        >
            <Flex align="center">
                {address ? (
                    isMine ? (
                        <Flex style={{ gap: 10 }} align="center">
                            <Avatar size={36} string={address} />
                            <Flex
                                gap={theme.spacing.smallest}
                                flex={1}
                                direction={"column"}
                            >
                                {userFE && userFE?.username ? (
                                    <Pressable
                                        onPress={() => {
                                            setShowUsernameModal(true);
                                        }}
                                    >
                                        <Flex style={{ gap: 8 }} align="center">
                                            <Text
                                                sml
                                                style={{
                                                    color: theme.color.greyNew,
                                                }}
                                            >
                                                {userFE?.username}
                                            </Text>
                                            <Image
                                                source={getBaseS3URL(
                                                    "edit_btn_2.png"
                                                )}
                                                width={14}
                                                height={14}
                                                alt={`edit_btn"`}
                                            />
                                        </Flex>
                                    </Pressable>
                                ) : (
                                    <Pressable
                                        onPress={() => {
                                            setShowUsernameModal(true);
                                        }}
                                    >
                                        <Flex style={{ gap: 8 }} align="center">
                                            <Text
                                                sml
                                                style={{
                                                    color: theme.color.rgba(
                                                        theme.color.greyNew,
                                                        0.4
                                                    ),
                                                }}
                                            >
                                                Create Username
                                            </Text>
                                            <Image
                                                source={getBaseS3URL(
                                                    "create_btn.png"
                                                )}
                                                width={14}
                                                height={14}
                                                alt={`edit_btn"`}
                                            />
                                        </Flex>
                                    </Pressable>
                                )}

                                <Pressable onPress={onCopyAddress}>
                                    <Flex style={{ gap: 8 }} align="center">
                                        <Text
                                            smaller
                                            style={{
                                                color: theme.color.greyNew,
                                            }}
                                        >
                                            {formattedAddress}
                                        </Text>
                                        <Image
                                            lazy
                                            source={`${getBaseS3URL(
                                                "copy_btn.png"
                                            )}`}
                                            height={16}
                                            width={16}
                                            fill={true}
                                            alt={`/copy`}
                                        />
                                    </Flex>
                                </Pressable>
                            </Flex>
                        </Flex>
                    ) : (
                        <Pressable
                            onPress={toggleConnectModal}
                            style={{
                                flexDirection: "row",
                                display: "flex",
                                gap: theme.spacing.big,
                                padding: theme.spacing.bigger,
                            }}
                        >
                            <Avatar size={36} string={address} />
                            <Statistic
                                label="Account"
                                value={formattedAddress}
                                valueProps={{
                                    primary: true,
                                }}
                            />
                        </Pressable>
                    )
                ) : (
                    <Pressable
                        onPress={toggleConnectModal}
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            gap: theme.spacing.big,
                            padding: theme.spacing.bigger,
                        }}
                    >
                        <Avatar size={36} string={null} />
                        <Statistic
                            label="Account"
                            value={"Not Connected"}
                            valueProps={{
                                primary: true,
                            }}
                        />
                    </Pressable>
                )}

                {media.isMobile && address ? (
                    <Box
                        align="center"
                        justify="center"
                        style={{
                            paddingRight: theme.spacing.bigger,
                        }}
                    >
                        <Button
                            primary
                            small
                            iconRight
                            stroke
                            iconProps={{ name: "external" }}
                            text={`View on ${
                                currentChain.name === "sepolia"
                                    ? "Arbiscan"
                                    : "Arbiscan"
                            }`}
                            onPress={onExplorer}
                        />
                    </Box>
                ) : (
                    renderStatistics
                )}
            </Flex>

            {/* <LongShortGraph /> */}
            {media.isMobile ? (
                renderStatistics
            ) : address ? (
                <Box
                    align="center"
                    justify="center"
                    style={{
                        paddingRight: theme.spacing.bigger,
                    }}
                >
                    <Button
                        primary
                        small
                        iconRight
                        stroke
                        iconProps={{ name: "external" }}
                        text={`View on ${
                            currentChain.name === "sepolia"
                                ? "Arbiscan"
                                : "Arbiscan"
                        }`}
                        onPress={onExplorer}
                    />
                </Box>
            ) : null}
        </Flex>
    );
});

export const GET_USER_TRADES = gql`
    query getUserTrades($trader: Bytes!) @api(name: subgraph) {
        trades(where: { isOpen: true, trader: $trader }) {
            tradeNotional
            tradeType
            openPrice
            isBuy
            notional
            collateral
            leverage
            highestLeverage
            funding
            rollover
            timestamp
            pair {
                id
                from
                to
                feed
                accRollover
                accFundingLong
                accFundingShort
                curFundingLong
                curFundingShort
                makerMaxLeverage
                lastFundingRate
                maxFundingFeePerBlock
                lastFundingBlock
                lastFundingTime
                lastRolloverBlock
                rolloverFeePerBlock
                longOI
                shortOI
                maxOI
                group {
                    name
                }
                hillInflectionPoint
                hillPosScale
                hillNegScale
                lastOiDelta
                springFactor
                sFactorUpScaleP
                sFactorDownScaleP
                lastTradePrice
            }
        }
    }
`;

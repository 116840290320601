import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { formatPrice } from "@utils";
import { useTheme } from "styled-components/native";

export const OpeningFeeTooltipContent = ({
    makerFee,
    takerFee,
    takerFeePercent,
    makerFeePercent,
    openFeePercent,
}: {
    takerFeePercent: number;
    makerFeePercent: number;
    takerFee: number;
    makerFee: number;
    openFeePercent: number;
}) => {
    const theme = useTheme();

    const list = [
        {
            label: "Opening Fee",
            divider: false,
            percent: openFeePercent,
            hide: !makerFee,
        },
        {
            label: "Maker Fee",
            value: makerFee,
            percent: makerFeePercent,
            description: "Applies to orders that decrease skew.",
            hide: !makerFee,
        },
        {
            label: makerFee ? "Taker Fee" : "Opening Fee",
            value: takerFee,
            percent: takerFeePercent,
            description: makerFee
                ? "Applies to orders that increase skew."
                : "The cost of opening the selected leveraged position.",
            hide: !takerFee,
        },
        {
            label: "Close Fee",
            divider: false,
            value: 0,
            percent: 0,
            description: "Ostium never charges a close fee.",
        },
    ];

    return (
        <Box gap={theme.spacing.bigger}>
            <Box gap={theme.spacing.bigger}>
                {list.map((item) => {
                    if (item.hide) return null;
                    return (
                        <Box
                            gap={theme.spacing.tiny}
                            key={`fees-list-${item.label}`}
                            style={{
                                borderTopWidth: item.divider ? 2 : 0,
                                borderColor: theme.color.rgba(
                                    theme.color.white,
                                    0.1
                                ),
                                paddingTop: item.divider
                                    ? theme.spacing.small
                                    : 0,
                            }}
                        >
                            <Flex justify="space-between" align="flex-end">
                                <Text
                                    smallest
                                    uppercase
                                    semiBold={!item.divider}
                                >
                                    {item.label}{" "}
                                    {item.percent !== undefined &&
                                        `(${`${item.percent.toFixed(2)}%`})`}
                                </Text>
                                <Text
                                    smallest
                                    mono
                                    bold
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        item.value ? 1 : 0.6
                                    )}
                                >
                                    {item.value
                                        ? `${formatPrice(item.value, {
                                              currency: true,
                                          })}`
                                        : ""}
                                </Text>
                            </Flex>

                            <Text
                                smaller
                                color={theme.color.rgba(theme.color.white, 0.6)}
                                lineHeight={theme.text.medium}
                            >
                                {item.description}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

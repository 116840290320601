import { Avatar } from "@atoms/Avatar";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { List } from "@organisms/List";
import { LIST_ITEM_HEIGHT } from "@screens/Trade/components/List/utils";
import { formatAddress, formatPrice } from "@utils";
import { UserFe, type User } from "gql/graphql";
import { useMemo } from "react";
import { ListRenderItem } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";

type ReferralUser = User & { userFE: UserFe };

interface ReferralsListProps {
    users: ReferralUser[];
    isLoading: boolean;
}

export const ReferralsList = ({
    users = [],
    isLoading,
}: ReferralsListProps) => {
    const theme = useTheme();

    const renderReferral: ListRenderItem<ReferralUser> = useMemo(
        () =>
            ({ item, index }) => {
                const isLast = index === users.length - 1;

                const username = item?.userFE?.username
                    ? item?.userFE?.username
                    : formatAddress(item.id);

                return (
                    <Flex
                        gap={theme.spacing.big}
                        justify="space-between"
                        align="center"
                        style={{
                            paddingHorizontal: theme.spacing.bigger,
                            height: LIST_ITEM_HEIGHT,
                            borderRadius: theme.radius,
                            borderBottomWidth: isLast ? 0 : 1,
                            borderBottomColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                        }}
                    >
                        <Flex align="center" gap={theme.spacing.big}>
                            <Avatar string={item.id} />
                            <Box gap={theme.spacing.tiny}>
                                <Text bold smaller color={theme.color.white}>
                                    {username}
                                </Text>
                            </Box>
                        </Flex>
                        <Box>
                            <Text small bold color={theme.color.white}>
                                {`${formatPrice(
                                    Number(formatUnits(item.totalVolume, 6)),
                                    { currency: false, decimals: 0 }
                                )} USDC`}
                            </Text>
                        </Box>
                    </Flex>
                );
            },
        [
            users?.length,
            theme.color,
            theme.radius,
            theme.spacing.big,
            theme.spacing.bigger,
            theme.spacing.tiny,
        ]
    );

    const renderEmpty = useMemo(() => {
        return (
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    borderRadius: theme.radius,
                }}
            >
                <Text
                    smaller
                    color={theme.color.white}
                    lineHeight={theme.spacing.bigger}
                >
                    {/* No referred users */}
                </Text>
            </Box>
        );
    }, [theme.color, theme.radius, theme.spacing.bigger]);

    return (
        <List
            data={users}
            renderItem={renderReferral}
            renderEmpty={renderEmpty}
            isLoading={isLoading}
            style={{
                minHeight: 100,
            }}
        />
    );
};

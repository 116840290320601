import { BlurView } from "@atoms/BlurView";
import { Box } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { useDimensions } from "@hooks/useDimensions";
import { useMedia } from "@hooks/useMedia";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { ReactNode, forwardRef, memo } from "react";
import { ScrollView } from "react-native";
import { useTheme } from "styled-components/native";

export const Sidebar = memo(
    forwardRef(
        (
            {
                top,
                children,
                isActive,
                renderFooter,
                renderHeader,
            }: {
                top?: number;
                isActive: boolean;
                children: ReactNode;
                renderFooter?: ReactNode;
                renderHeader?: ReactNode;
            },
            ref
        ) => {
            const theme = useTheme();
            const media = useMedia();
            const dimensions = useDimensions();
            const { isCTAActive } = useAppContext();

            const width = media?.isMobile ? dimensions.width : 300;

            if (!isActive) return null;

            return (
                <BlurView
                    intensity={1000}
                    style={{
                        // @ts-ignore
                        position: "fixed",
                        backgroundColor: theme.color.background,
                        left: media?.isMobile ? 0 : undefined,
                        right: 0,
                        bottom: 0,
                        zIndex: 10,
                        width,
                        top:
                            top ??
                            HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                        transition: "transform 0.3s ease-in-out",
                        willChange: "transform",
                        borderLeftWidth: 1,
                        borderTopWidth: 1,
                        borderTopLeftRadius: media.isMobile
                            ? 0
                            : theme.radius.big,
                        borderBottomLeftRadius: media.isMobile
                            ? 0
                            : theme.radius.big,
                        borderColor: theme.color.rgba(theme.color.white, 0.1),
                        transform: [
                            {
                                translateX: isActive ? 0 : width,
                            },
                        ],
                    }}
                >
                    <Box>{renderHeader}</Box>
                    <Box flex={1} ref={ref}>
                        <ScrollView>{children}</ScrollView>
                        <Box>{renderFooter}</Box>
                    </Box>
                </BlurView>
            );
        }
    )
);

import React from "react";
import { View, StyleSheet } from "react-native";
import Svg, { Defs, RadialGradient, Rect, Stop } from "react-native-svg";

const RadialGradientBackground = ({ style }: { style: any }) => {
    return (
        <View style={[styles.container, style]}>
            <Svg height="100%" width="100%">
                <Defs>
                    <RadialGradient
                        id="grad"
                        cx="50.14%" // Center X
                        cy="-0.13%" // Center Y
                        rx="39.13%" // Horizontal radius
                        ry="40.48%" // Vertical radius
                        fx="50.14%" // Focal point X
                        fy="-0.13%" // Focal point Y
                    >
                        <Stop
                            offset="0%"
                            stopColor="rgba(255, 90, 25, 0.20)"
                            stopOpacity="1"
                        />
                        <Stop
                            offset="100%"
                            stopColor="rgba(255, 90, 25, 0.00)"
                            stopOpacity="0"
                        />
                    </RadialGradient>
                </Defs>
                <Rect width="100%" height="100%" fill="url(#grad)" />
            </Svg>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default RadialGradientBackground;

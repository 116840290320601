import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import Modal from "@organisms/Modal";
import { Pressable } from "react-native-gesture-handler";
import { useTheme } from "styled-components/native";
import { Text } from "@atoms/Text";
import { backgrounds, border, borderRadius, padding, position } from "polished";
import dynamic from "next/dynamic";
import { useAppContext } from "@contexts/app";
import { useTradeContext } from "@screens/Trade/context";
import { ClosePrice, PairItem, PositionPNL } from "@organisms/List/Item";
import {
    TradeListProvider,
    useTradeListContext,
} from "@screens/Trade/components/List/context";
import { Limit, LpNft, Order, Pair, Trade } from "gql/graphql";
import { formatData, parseWeb3Error, Price } from "utils";
import { formatPrice, getBaseS3URL } from "@utils";
import {
    getPriceImpactParsed,
    getTradePNL,
    PositionOrderTypes,
} from "@screens/Trade/utils";
import { getPairKey, usePriceContext } from "@contexts/price";
import { useBlockNumber } from "wagmi";
import { Button } from "@molecules/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNotificationContext } from "@contexts/notification";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { useWeb3Context } from "@contexts/web3";
import { encodeFunctionData, formatUnits } from "viem";
import { OstiumTrading__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumTrading__factory";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useOffBoardModalContext } from "./context";
import { TransferModal } from "./TransferModal";
import { useAllowance } from "@contexts/hooks/useAllowance";
import { useMedia } from "@hooks/useMedia";
import RadialGradientBackground from "./RadialGradientBackground";
import { Skeleton } from "@molecules/Skeleton";

const TradeList = dynamic(() => import("../Trade/components/List"), {
    ssr: true,
});

const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#1e1e1e",
    color: "white",
    borderRadius: "8px",
    overflow: "hidden",
};

const headerStyle = {
    backgroundColor: "#2a2a2a",
    padding: "10px",
    textAlign: "left",
    color: "rgba(208, 219, 218, 0.40)",
    fontSize: 11,
};

const cellStyle = {
    padding: "10px",
    borderBottom: "1px solid #333",
    fontSize: 12,
    color: "#D0DBDA",
};

const closeButtonStyle = {
    backgroundColor: "#1B1B1B",
    color: "white",
    border: "none",
    padding: "4px 6px",
    cursor: "pointer",
    borderRadius: "4px",
    fontSize: 12,
};

const OffBoardModal = () => {
    const theme = useTheme();

    const { smartAccountAddress, wagmiAddress } = useOstiumAccount();
    const { showOffboardingModal, setShowOffboardingModal } =
        useOffBoardModalContext();

    return (
        <Modal
            isVisible={showOffboardingModal}
            strict={true}
            onClose={() => {
                setShowOffboardingModal(false);
            }}
            hasCustomHeader={true}
            maxWidth={700}
            maxHeight={650}
            renderCustomHeader={
                <Flex
                    direction="column"
                    style={{
                        paddingLeft: theme.spacing.bigger,
                        borderBottomWidth: true ? 1 : 0,
                        borderBottomColor: theme.color.rgba(
                            theme.color.white,
                            0.1
                        ),
                        paddingBottom: 12,
                    }}
                >
                    <Flex justify="space-between">
                        <Box
                            justify="center"
                            gap={theme.spacing.smallest}
                            flex={1}
                            style={{
                                minHeight: 48,
                            }}
                        >
                            <Flex
                                style={{
                                    backgroundColor: "rgba(255, 90, 25, 0.20)",
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    borderRadius: 100,
                                    maxWidth: 186,
                                }}
                                align="center"
                                justify="center"
                            >
                                <Text smaller primary>
                                    Announcing Unified Accounts
                                </Text>
                            </Flex>
                        </Box>

                        <Pressable
                            onPress={() => setShowOffboardingModal(false)}
                            style={{
                                width: 48,
                                height: 48,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Icon name="close" size={12} />
                            </Box>
                        </Pressable>
                    </Flex>
                    <Text big semiBold>
                        Migrate Funds to Your Primary Wallet
                    </Text>
                </Flex>
            }
        >
            <ModalContent
                smartAccountAddress={smartAccountAddress}
                wagmiAddress={wagmiAddress}
            />
        </Modal>
    );
};

const ModalContent = ({
    smartAccountAddress,
    wagmiAddress,
}: {
    smartAccountAddress: string | undefined;
    wagmiAddress: `0x${string}` | undefined;
}) => {
    const theme = useTheme();
    const { isMobile } = useMedia();

    const {
        totalTrades,
        showAllPairs,
        tradesData,
        isLoadingTrades,
        toggleShowAllPairs,
        balance,
        showTransferModal,
        setShowTransferModal,
        olpBalance,
        lockedDeposits,
        setShowOffboardingModal,
        refetchTrades,
        ordersData,
    } = useOffBoardModalContext();
    const {
        setShowEnableCTModal,
        toggleSmartAccount,
        isOneClickTradingEnabled,
    } = useAppContext();
    const { currentChain, tradingContract, provider } = useWeb3Context();
    const { data: blockNumber } = useBlockNumber({
        watch: true,
    });
    const { checkAllowance, getAllowance, updateAllowance } = useAllowance(
        currentChain.contracts.storage
    );
    const [loading, setLoading] = useState(false);
    const [allDone, setAllDone] = useState(false);
    const { prices } = usePriceContext();

    const handleEnableSmartAccount = useCallback(async () => {
        try {
            setLoading(true);
            setShowEnableCTModal({
                visible: true,
                delegationStep: 1,
                allowanceStep: 0,
            });
            const delegation = await tradingContract.delegations(wagmiAddress);
            console.log("delegation", delegation);
            if (delegation !== smartAccountAddress) {
                const tx = await tradingContract?.setDelegate(
                    smartAccountAddress
                );
                //@ts-ignore
                await provider.waitForTransaction(tx.hash, 1);
            }
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 0,
            });
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 1,
            });
            const currentAllowance = await getAllowance();

            console.log("currentAllowance", currentAllowance);
            if (!currentAllowance || currentAllowance < 100000) {
                await checkAllowance(10000000);

                updateAllowance();
            }
            setShowEnableCTModal({
                visible: true,
                delegationStep: 2,
                allowanceStep: 2,
            });
            toggleSmartAccount();
            setLoading(false);
        } catch (e) {
            console.log("error", e);
            setShowEnableCTModal({
                visible: false,
                delegationStep: -1,
                allowanceStep: -1,
            });
            setLoading(false);
        }
    }, [
        smartAccountAddress,
        tradingContract,
        wagmiAddress,
        checkAllowance,
        getAllowance,
        toggleSmartAccount,
        updateAllowance,
        provider,

        setShowEnableCTModal,
    ]);

    const hasOpenFunds =
        (olpBalance && olpBalance.formatted && olpBalance.formatted > 0) ||
        (lockedDeposits && lockedDeposits.length > 0) ||
        (balance && balance.formatted && Number(balance.formatted) > 0);

    const hasOpenTrades = tradesData && tradesData.length > 0;
    const hasOpenOrders = ordersData && ordersData.length > 0;
    const hasOpenVaultBalance =
        (olpBalance && olpBalance.formatted && olpBalance.formatted > 0) ||
        (lockedDeposits && lockedDeposits.length > 0);

    return (
        <Flex direction="column" align="center" style={{ paddingBottom: 80 }}>
            {allDone ? (
                <Box style={{ paddingTop: 24, paddingBottom: 24 }}>
                    <Flex style={{ gap: 16 }} justify="center" align="center">
                        <Image
                            alt="Ostium Logo"
                            source={getBaseS3URL("tickIcon.png")}
                            width={32}
                            height={32}
                            priority
                            selectable={false}
                        />
                        <Text semiBold biggest>
                            You’re All Done!
                        </Text>
                    </Flex>
                    <Box style={{ paddingTop: 24 }}>
                        <Text
                            textAlign="center"
                            big
                            color="rgba(208, 219, 218, 0.70)"
                            lineHeight="normal"
                        >
                            {
                                "You’ve closed all your trades and migrated your vault funds\nand USDC to your primary wallet."
                            }
                        </Text>
                    </Box>
                    <Flex
                        style={{ paddingTop: 40 }}
                        direction="column"
                        gap={16}
                        align="center"
                    >
                        <RadialGradientBackground
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                            }}
                        />
                        <Flex
                            direction="column"
                            gap={theme.spacing.tiny}
                            style={{
                                paddingTop: theme.spacing.bigger,
                            }}
                        >
                            <Text
                                semiBold
                                smaller
                                textAlign="center"
                                color="rgba(208, 219, 218, 0.70)"
                                style={{ textTransform: "uppercase" }}
                            >
                                1-Click Trading
                            </Text>
                            <Text
                                textAlign="center"
                                big
                                color="#D0DBDA"
                                lineHeight={26}
                            >
                                {
                                    "Make the most of Ostium. Enable gasless\ntransactions and 1-click trading."
                                }
                            </Text>
                        </Flex>

                        <Button
                            onPress={() => {
                                setShowOffboardingModal(false);
                                if (isOneClickTradingEnabled) return;
                                handleEnableSmartAccount();
                            }}
                            style={{
                                borderWidth: 0.5,
                                borderColor: "#252424",
                                backgroundColor: "rgba(208, 219, 218, 0.10))",
                            }}
                            text={
                                isOneClickTradingEnabled
                                    ? "Already Enabled"
                                    : "Enable Now"
                            }
                        />
                    </Flex>
                </Box>
            ) : (
                <>
                    <Box style={{ padding: 16, width: "100%" }}>
                        <Flex justify="space-between" align="center">
                            <Text
                                style={{
                                    fontSize: 18,
                                    color: theme.color.white,
                                }}
                            >
                                1. Close Trades/Orders
                            </Text>
                            <Flex
                                style={{
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    backgroundColor:
                                        !hasOpenTrades && !hasOpenOrders
                                            ? "rgba(26, 150, 93, 0.10)"
                                            : "rgba(208, 219, 218, 0.10)",
                                    borderRadius: 6,
                                }}
                                justify="space-around"
                            >
                                <Text
                                    smaller
                                    style={{
                                        color:
                                            !hasOpenTrades && !hasOpenOrders
                                                ? "#1A965D"
                                                : theme.color.greyish,
                                    }}
                                >
                                    {!hasOpenTrades && !hasOpenOrders
                                        ? "Complete"
                                        : "In Progress"}
                                </Text>
                                {/* <Box
                            style={{
                                width: 1,
                                backgroundColor: "rgba(208, 219, 218, 0.10)",
                                marginLeft: 12,
                                marginRight: 12,
                            }}
                        ></Box>
                        <Text
                            smaller
                            style={{ color: "rgba(208, 219, 218, 0.70)" }}
                        >
                            {tradesData.length === totalTrades
                                ? 0
                                : tradesData.length}
                            /{totalTrades}
                        </Text> */}
                            </Flex>
                        </Flex>
                        <Text
                            style={{ paddingTop: 12 }}
                            smaller
                            semiBold
                            color="rgba(208, 219, 218, 0.40)"
                        >
                            TRADES
                        </Text>
                        <Text
                            style={{ paddingTop: 6 }}
                            big
                            color="rgba(208, 219, 218, 0.70)"
                        >
                            Close your current trades and open new positions
                            from your primary wallet.
                        </Text>

                        <Box style={{ paddingTop: 14 }}>
                            {/* @ts-ignore */}
                            {isMobile ? (
                                tradesData && tradesData.length > 0 ? (
                                    <Flex
                                        direction="column"
                                        style={{ gap: 16 }}
                                    >
                                        {tradesData?.map((row, index) => {
                                            return (
                                                <TradeCard
                                                    blockNumber={blockNumber}
                                                    key={index}
                                                    row={row}
                                                    prices={prices}
                                                    refetchTrades={
                                                        refetchTrades
                                                    }
                                                    orderIds={
                                                        ordersData &&
                                                        ordersData.length
                                                            ? ordersData.map(
                                                                  (or) =>
                                                                      //@ts-ignore
                                                                      or.tradeID
                                                              )
                                                            : []
                                                    }
                                                />
                                            );
                                        })}
                                    </Flex>
                                ) : (
                                    <Flex
                                        justify="center"
                                        align="center"
                                        style={{
                                            padding: 16,
                                            gap: 12,
                                            backgroundColor: "#252525",
                                            border: " 0.5px solid rgba(208, 219, 218, 0.10)",
                                            borderRadius: 6,
                                        }}
                                    >
                                        <Text smaller>
                                            No open trades found
                                        </Text>
                                    </Flex>
                                )
                            ) : (
                                /* @ts-ignore */
                                <table style={tableStyle}>
                                    <thead>
                                        <tr>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>
                                                Market & Side
                                            </th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Size</th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>
                                                Net Value
                                            </th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>
                                                Entry Price
                                            </th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>
                                                Market Price
                                            </th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>
                                                Unrealized PNL
                                            </th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tradesData && tradesData.length > 0 ? (
                                            tradesData?.map((row, index) => {
                                                return (
                                                    <TradeRowItem
                                                        blockNumber={
                                                            blockNumber
                                                        }
                                                        key={index}
                                                        row={row}
                                                        prices={prices}
                                                        refetchTrades={
                                                            refetchTrades
                                                        }
                                                        orderIds={
                                                            ordersData &&
                                                            ordersData.length
                                                                ? ordersData.map(
                                                                      (or) =>
                                                                          //@ts-ignore
                                                                          or.tradeID
                                                                  )
                                                                : []
                                                        }
                                                    />
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    style={{
                                                        textAlign: "center",
                                                        padding: 15,
                                                    }}
                                                >
                                                    <Text smaller>
                                                        No open trades found
                                                    </Text>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </Box>

                        <Box style={{ paddingTop: 12 }}>
                            <Text
                                style={{ paddingTop: 12 }}
                                smaller
                                semiBold
                                color="rgba(208, 219, 218, 0.40)"
                            >
                                ORDERS
                            </Text>
                            <Text
                                style={{ paddingTop: 6, lineHeight: "normal" }}
                                big
                                color="rgba(208, 219, 218, 0.70)"
                            >
                                {`Cancel your open orders & open new ones from primary account`}
                            </Text>
                            <Box style={{ paddingTop: 14 }}>
                                {/* @ts-ignore */}
                                {isMobile ? (
                                    ordersData && ordersData.length > 0 ? (
                                        <Flex
                                            direction="column"
                                            style={{ gap: 16 }}
                                        >
                                            {ordersData?.map((row, index) => {
                                                return (
                                                    <OrderCard
                                                        blockNumber={
                                                            blockNumber
                                                        }
                                                        key={index}
                                                        row={
                                                            //@ts-ignore
                                                            row?.orderType ===
                                                            PositionOrderTypes.Market
                                                                ? (row as Limit)
                                                                : (row as Order)
                                                        }
                                                        prices={prices}
                                                        refetchTrades={
                                                            refetchTrades
                                                        }
                                                    />
                                                );
                                            })}
                                        </Flex>
                                    ) : (
                                        <Flex
                                            justify="center"
                                            align="center"
                                            style={{
                                                padding: 16,
                                                gap: 12,
                                                backgroundColor: "#252525",
                                                border: " 0.5px solid rgba(208, 219, 218, 0.10)",
                                                borderRadius: 6,
                                            }}
                                        >
                                            <Text smaller>
                                                No open orders found
                                            </Text>
                                        </Flex>
                                    )
                                ) : (
                                    /* @ts-ignore */
                                    <table style={tableStyle}>
                                        <thead>
                                            <tr>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Market & Side
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Size
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Collateral
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Entry Price
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Market Price
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Close Price
                                                </th>
                                                {/* @ts-ignore */}
                                                <th style={headerStyle}>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordersData &&
                                            ordersData.length > 0 ? (
                                                ordersData?.map(
                                                    (row, index) => {
                                                        return (
                                                            <OrderRowItem
                                                                blockNumber={
                                                                    blockNumber
                                                                }
                                                                key={index}
                                                                row={
                                                                    //@ts-ignore
                                                                    row?.orderType ===
                                                                    PositionOrderTypes.Market
                                                                        ? (row as Limit)
                                                                        : (row as Order)
                                                                }
                                                                prices={prices}
                                                                refetchTrades={
                                                                    refetchTrades
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan={7}
                                                        style={{
                                                            textAlign: "center",
                                                            padding: 15,
                                                        }}
                                                    >
                                                        <Text smaller>
                                                            No open orders found
                                                        </Text>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        style={{
                            paddingTop: 24,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                        }}
                    >
                        <Box>
                            <Flex justify="space-between" align="center">
                                <Text
                                    style={{
                                        fontSize: 18,
                                        color: theme.color.white,
                                    }}
                                >
                                    2. Migrate Funds
                                </Text>
                                <Flex
                                    style={{
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        backgroundColor: !hasOpenFunds
                                            ? "rgba(26, 150, 93, 0.10)"
                                            : "rgba(208, 219, 218, 0.10)",
                                        borderRadius: 6,
                                    }}
                                    justify="space-around"
                                >
                                    <Text
                                        smaller
                                        style={{
                                            color: !hasOpenFunds
                                                ? "#1A965D"
                                                : theme.color.greyish,
                                        }}
                                    >
                                        {!hasOpenFunds
                                            ? "Complete"
                                            : "In Progress"}
                                    </Text>
                                    {/* <Box
                                    style={{
                                        width: 1,
                                        backgroundColor:
                                            "rgba(208, 219, 218, 0.10)",
                                        marginLeft: 12,
                                        marginRight: 12,
                                    }}
                                ></Box>
                                <Text
                                    smaller
                                    style={{
                                        color: "rgba(208, 219, 218, 0.70)",
                                    }}
                                >
                                    {tradesData.length === totalTrades
                                        ? 0
                                        : tradesData.length}
                                    {totalTrades}
                                </Text> */}
                                </Flex>
                            </Flex>
                            <Text
                                style={{ paddingTop: 12 }}
                                smaller
                                semiBold
                                color="rgba(208, 219, 218, 0.40)"
                            >
                                VAULT
                            </Text>
                            <Text
                                style={{ paddingTop: 12, lineHeight: "normal" }}
                                big
                                color="rgba(208, 219, 218, 0.70)"
                            >
                                Migrate your vault funds to your primary wallet
                                (
                                {`${wagmiAddress?.substring(
                                    0,
                                    4
                                )}...${wagmiAddress?.substring(
                                    wagmiAddress?.length - 4,
                                    wagmiAddress?.length
                                )}`}
                                ). You’ll maintain your stake in the liquidity
                                pool.
                            </Text>

                            <Box style={{ paddingTop: 24 }}>
                                {/* @ts-ignore */}
                                <table style={tableStyle}>
                                    <thead>
                                        <tr>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Asset</th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Amount</th>

                                            {isMobile ? null : (
                                                /* @ts-ignore */
                                                <th style={headerStyle}>
                                                    Status
                                                </th>
                                            )}
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(olpBalance &&
                                            olpBalance.formatted &&
                                            olpBalance.formatted > 0) ||
                                        (lockedDeposits &&
                                            lockedDeposits.length > 0) ? (
                                            <>
                                                {olpBalance &&
                                                olpBalance.formatted &&
                                                olpBalance.formatted > 0 ? (
                                                    <OLPBalance
                                                        balance={olpBalance}
                                                        onButtonClick={() =>
                                                            setShowTransferModal(
                                                                {
                                                                    isVisible:
                                                                        true,
                                                                    data: {
                                                                        address:
                                                                            currentChain
                                                                                .contracts
                                                                                .vault,
                                                                        symbol: "OLP",
                                                                        isNFT: false,
                                                                        isOLP: true,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                    />
                                                ) : null}
                                                {lockedDeposits &&
                                                    lockedDeposits.length > 0 &&
                                                    lockedDeposits.map(
                                                        (lp, index) => {
                                                            const shares =
                                                                Number(
                                                                    formatUnits(
                                                                        lp.shares,
                                                                        6
                                                                    )
                                                                );
                                                            const assets =
                                                                Number(
                                                                    formatUnits(
                                                                        lp.assetsDeposited,
                                                                        6
                                                                    )
                                                                );
                                                            return (
                                                                <NFTBalance
                                                                    index={
                                                                        index +
                                                                        1
                                                                    }
                                                                    key={lp.id}
                                                                    data={lp}
                                                                    shares={
                                                                        shares
                                                                    }
                                                                    assets={
                                                                        assets
                                                                    }
                                                                    onButtonClick={() =>
                                                                        setShowTransferModal(
                                                                            {
                                                                                isVisible:
                                                                                    true,
                                                                                data: {
                                                                                    address:
                                                                                        currentChain
                                                                                            .contracts
                                                                                            .lockedDepositNft,
                                                                                    symbol: "OLP",
                                                                                    isNFT: true,
                                                                                    nftId: Number(
                                                                                        lp.id
                                                                                    ),
                                                                                    nftName: `Locked LP NFT #1`,
                                                                                    shares: shares,
                                                                                    assets: assets,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </>
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={4}
                                                    style={{
                                                        textAlign: "center",
                                                        padding: 15,
                                                    }}
                                                >
                                                    <Text smaller>
                                                        No balance found in
                                                        vault
                                                    </Text>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>

                        <Box style={{ paddingTop: 12 }}>
                            <Text
                                style={{ paddingTop: 12 }}
                                smaller
                                semiBold
                                color="rgba(208, 219, 218, 0.40)"
                            >
                                BALANCE
                            </Text>
                            <Text
                                style={{ paddingTop: 12, lineHeight: "normal" }}
                                big
                                color="rgba(208, 219, 218, 0.70)"
                            >
                                {`Migrating your USDC will allow you to trade ${
                                    hasOpenTrades ||
                                    hasOpenVaultBalance ||
                                    hasOpenOrders
                                        ? "\n(Disabled: migrate vault funds & close all positions to enable)"
                                        : ""
                                }`}
                            </Text>

                            <Box style={{ paddingTop: 24 }}>
                                {/* @ts-ignore */}
                                <table style={tableStyle}>
                                    <thead>
                                        <tr>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Asset</th>
                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Amount</th>

                                            {/* @ts-ignore */}
                                            <th style={headerStyle}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {balance &&
                                        balance.formatted &&
                                        Number(balance.formatted) > 0 ? (
                                            <WalletFundTransfer
                                                onButtonClick={() =>
                                                    setShowTransferModal({
                                                        isVisible: true,
                                                        data: {
                                                            address:
                                                                currentChain
                                                                    .contracts
                                                                    .token as string,
                                                            symbol: "USDC",
                                                            isNFT: false,
                                                            isOLP: false,
                                                        },
                                                    })
                                                }
                                                balance={balance}
                                                isDisabled={
                                                    hasOpenTrades ||
                                                    hasOpenOrders ||
                                                    hasOpenVaultBalance
                                                }
                                            />
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    style={{
                                                        textAlign: "center",
                                                        padding: 15,
                                                    }}
                                                >
                                                    <Text smaller>
                                                        No funds found to
                                                        transfer
                                                    </Text>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </Box>

                    {smartAccountAddress && showTransferModal.data ? (
                        <TransferModal
                            address={smartAccountAddress}
                            isVisible={true}
                            data={showTransferModal.data}
                            onClose={() =>
                                setShowTransferModal({
                                    isVisible: false,
                                    data: undefined,
                                })
                            }
                        />
                    ) : null}
                </>
            )}

            <Flex
                style={{
                    borderTop: "0.5px solid rgba(208, 219, 218, 0.10)",
                    padding: 16,
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    backgroundColor: theme.color.background,
                }}
                align="center"
                justify="space-between"
            >
                {allDone ? (
                    <Pressable>
                        <Text big color="rgba(208, 219, 218, 0.70)"></Text>
                    </Pressable>
                ) : (
                    <Pressable onPress={() => setShowOffboardingModal(false)}>
                        <Text big color="rgba(208, 219, 218, 0.70)">
                            Finish Later
                        </Text>
                    </Pressable>
                )}
                {allDone ? (
                    <Button
                        text={"Finish"}
                        onPress={() => {
                            setShowOffboardingModal(false);
                        }}
                        newPrimary
                    />
                ) : (
                    <Button
                        disabled={
                            hasOpenFunds || hasOpenTrades || hasOpenOrders
                        }
                        text={"Complete"}
                        onPress={() => setAllDone(true)}
                        stroke
                        noBorder
                    />
                )}
            </Flex>
        </Flex>
    );
};

const NFTBalance = ({
    index,
    data,
    onButtonClick,
    shares,
    assets,
}: {
    index: number;
    data: LpNft;
    onButtonClick: () => void;
    shares: number;
    assets: number;
}) => {
    const { isMobile } = useMedia();
    return (
        <tr>
            <td style={cellStyle}>
                <Flex style={{ gap: 8 }} align="center">
                    <Image
                        source={"assets/pairs/olp.svg"}
                        width={24}
                        height={24}
                        alt={"USDC"}
                    />
                    <Text smaller color="#D0DBDA">
                        Locked LP NFT #{index}
                    </Text>
                </Flex>
            </td>
            <td style={cellStyle}>
                <Flex direction="column">
                    <Text smaller color="#D0DBDA">
                        {formatPrice(assets, {
                            currency: false,
                        })}{" "}
                        USDC
                    </Text>
                    <Text
                        style={{ paddingTop: 2 }}
                        tiny
                        color="rgba(208, 219, 218, 0.70)"
                    >
                        {formatPrice(shares, {
                            currency: false,
                        })}{" "}
                        OLP
                    </Text>
                </Flex>
            </td>
            {isMobile ? null : <td style={cellStyle}>Locked USDC & OLP</td>}
            <td style={cellStyle}>
                <Button
                    text={"Migrate"}
                    onPress={onButtonClick}
                    stroke
                    tiny
                    noBorder
                />
            </td>
        </tr>
    );
};

const OLPBalance = ({
    balance,
    onButtonClick,
}: {
    balance: {
        formatted: number | undefined;
        value: bigint;
        decimals: number;
        symbol: string;
    };
    onButtonClick: () => void;
}) => {
    const { isMobile } = useMedia();

    const formatBalance = useMemo(() => {
        if (balance.formatted)
            return `${formatPrice(Number(balance?.formatted) || 0, {
                currency: false,
                decimals: 2,
            })}`;
        return "";
    }, [balance?.formatted]);

    return (
        <tr>
            <td style={cellStyle}>
                <Flex style={{ gap: 8 }} align="center">
                    <Image
                        source={"assets/pairs/olp.svg"}
                        width={24}
                        height={24}
                        alt={"USDC"}
                    />
                    <Text smaller color="#D0DBDA">
                        OLP
                    </Text>
                </Flex>
            </td>
            <td style={cellStyle}> {formatBalance}</td>
            {isMobile ? null : <td style={cellStyle}> Unlocked</td>}
            <td style={cellStyle}>
                <Button
                    text={"Migrate"}
                    onPress={onButtonClick}
                    stroke
                    tiny
                    noBorder
                />
            </td>
        </tr>
    );
};

const WalletFundTransfer = ({
    balance,
    onButtonClick,
    isDisabled = false,
}: {
    balance: {
        formatted: number | undefined;
        value: bigint;
        decimals: number;
        symbol: string;
    };
    isDisabled: boolean;
    onButtonClick: () => void;
}) => {
    const formatBalance = useMemo(() => {
        if (balance.formatted)
            return `${formatPrice(Number(balance?.formatted) || 0, {
                currency: false,
                decimals: 2,
            })}`;
        return "";
    }, [balance?.formatted]);

    return (
        <tr>
            <td style={cellStyle}>
                <Flex style={{ gap: 8 }} align="center">
                    <Image
                        source={"assets/pairs/usdc.svg"}
                        width={24}
                        height={24}
                        alt={"USDC"}
                    />
                    <Text smaller color="#D0DBDA">
                        USDC
                    </Text>
                </Flex>
            </td>
            <td style={cellStyle}> {formatBalance}</td>
            <td style={cellStyle}>
                <Button
                    text={isDisabled ? "Disabled" : "Migrate"}
                    onPress={onButtonClick}
                    stroke
                    tiny
                    noBorder
                    disabled={isDisabled}
                />
            </td>
        </tr>
    );
};

const TradeRowItem = ({
    row,
    blockNumber,
    prices,
    refetchTrades,
    orderIds,
}: {
    row: Trade;
    blockNumber: bigint | undefined;
    prices: Map<`${string}-${string}`, Price> | undefined;
    refetchTrades: () => void;
    orderIds: string[];
}) => {
    const { isMobile } = useMedia();
    const [isMarketClosedModalVisible, setIsMarketClosedModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const marketPrice = prices?.get(getPairKey(row.pair));
    const { sendSmartWalletOrderV1 } = usePrivySmartWalletContext();
    const {
        stopLossPrice,
        takeProfitPrice,
        openPrice,
        collateral,
        tradeNotional,
    } = formatData({
        stopLossPrice: row?.stopLossPrice,
        takeProfitPrice: row?.takeProfitPrice,
        openPrice: row?.openPrice,
        collateral: row?.collateral,
        tradeNotional: row?.tradeNotional,
    });

    const {
        pnl,
        netPNL,
        netValue,
        pnlPercent,
        funding,
        rollover,
        liquidationPrice,
        priceImpact,
    } = getTradePNL(row, marketPrice, blockNumber);
    const { isTradingDisabled, changePair } = useTradeContext();
    const { notify } = useNotificationContext();
    const {
        settings: { language },
        isOneClickTradingEnabled,
    } = useAppContext();
    const { currentChain, tradingContract } = useWeb3Context();
    const [closed, setClosed] = useState(false);

    const onCloseTrade = useCallback(async () => {
        if (marketPrice?.isMarketOpen == false) {
            //setIsMarketClosedModalVisible(true);
            return;
        }
        try {
            if (isTradingDisabled) {
                // notify({
                //     title: language.screens.trade.disabled.title,
                //     description: language?.screens.trade.disabled.description,
                // });
                return;
            }
            setIsLoading(true);

            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "closeTradeMarket",
                        args: [
                            //@ts-ignore
                            row.pair.id,
                            //@ts-ignore
                            row.index,
                            //@ts-ignore
                            100 * 100,
                        ],
                    }),
                },
            ]);

            if (result && result?.isError) {
                notify({
                    title: result.title,
                    description: result.description,
                });

                setIsLoading(false);
                return;
            }
            if (refetchTrades) {
                refetchTrades();
            }

            setClosed(true);
            setIsLoading(false);

            notify({
                title: "Closing Trade",
                description: "Your trade is being closed.",
                type: "Trade",
                data: {
                    trade: {
                        ...row,
                        stopLossPrice: undefined,
                        takeProfitPrice: undefined,
                    },
                },
                // @ts-ignore
                hash: result?.hash,
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
            });
        } catch (err) {
            console.warn(err);
            setIsLoading(false);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
        }
    }, [
        marketPrice,
        isTradingDisabled,
        setClosed,
        row,
        notify,
        currentChain?.explorer,
        refetchTrades,
        sendSmartWalletOrderV1,
        currentChain.contracts.trading,
    ]);

    return (
        <tr>
            <td style={cellStyle}>
                <PairItem
                    pair={row?.pair}
                    leverage={row?.leverage}
                    isBuy={row?.isBuy}
                />
            </td>
            <td style={cellStyle}>
                {`${formatPrice(tradeNotional, {
                    significant: 6,
                })}`}
            </td>
            <td style={cellStyle}>
                {formatPrice(netValue, {
                    decimals: 2,
                    currency: true,
                })}
            </td>
            <td style={cellStyle}>
                {formatPrice(openPrice, {
                    significant: 6,
                    //   currency: true,
                })}
            </td>
            <td style={cellStyle}>
                {formatPrice(priceImpact, {
                    significant: 6,
                    //   currency: true,
                }) || "-"}
            </td>
            <td style={cellStyle}>
                <span style={{ color: "red" }}>
                    <PositionPNL
                        isBuy={row?.isBuy}
                        pnl={pnl}
                        netPNL={netPNL}
                        percent={pnlPercent}
                        collateral={collateral || 0}
                        funding={funding}
                        rollover={rollover}
                        isLoading={
                            !marketPrice?.mid ||
                            !marketPrice?.bid ||
                            !marketPrice?.ask
                        }
                        style={{
                            paddingRight: 0,
                        }}
                    />
                </span>
            </td>
            <td style={cellStyle}>
                {orderIds.includes(row.id) ? null : (
                    <Button
                        text={
                            marketPrice?.isMarketOpen == false
                                ? "Market Closed"
                                : isLoading
                                ? "Closing"
                                : closed
                                ? "Closed"
                                : "Close"
                        }
                        loading={isLoading}
                        disabled={
                            closed || isLoading || !marketPrice?.isMarketOpen
                        }
                        onPress={onCloseTrade}
                        stroke
                        tiny
                        noBorder
                    />
                )}
            </td>
        </tr>
    );
};

const OrderRowItem = ({
    row,
    blockNumber,
    prices,
    refetchTrades,
}: {
    row: Limit | Order;
    blockNumber: bigint | undefined;
    prices: Map<`${string}-${string}`, Price> | undefined;
    refetchTrades: () => void;
}) => {
    const { isMobile } = useMedia();
    const [isMarketClosedModalVisible, setIsMarketClosedModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const marketPrice = prices?.get(getPairKey(row.pair));
    const { sendSmartWalletOrderV1 } = usePrivySmartWalletContext();

    const {
        stopLossPrice,
        takeProfitPrice,
        collateral,
        notional,
        openPrice,
        leverage,
    } = formatData({
        //@ts-ignore
        stopLossPrice: row.stopLossPrice,
        //@ts-ignore
        takeProfitPrice: row.takeProfitPrice,
        //@ts-ignore
        openPrice: row.openPrice,
        notional: row.notional,
        collateral: row.collateral,
        leverage: row.leverage,
        from: row?.pair?.from,
        to: row?.pair?.to,
    });

    const { isTradingDisabled, changePair } = useTradeContext();
    const { notify } = useNotificationContext();
    const {
        settings: { language },
        isOneClickTradingEnabled,
    } = useAppContext();
    const { currentChain, tradingContract } = useWeb3Context();
    const [closed, setClosed] = useState(false);

    const stopLoss = stopLossPrice || 0;
    const takeProfit = takeProfitPrice || 0;
    const size =
        notional && openPrice
            ? row.pair.from === "USD"
                ? notional * openPrice
                : notional / openPrice
            : 0;
    const priceImpactRaw = marketPrice
        ? getPriceImpactParsed(marketPrice, row.isBuy, true).priceAfterImpact
        : "0";

    const onCloseMarket = async () => {
        try {
            // if (isTradingDisabled) {
            //     notify({
            //         title: language.screens.trade.disabled.title,
            //         description: language?.screens.trade.disabled.description,
            //     });
            //     return;
            // }
            setIsLoading(true);
            const orderId = row?.id.split("_")[0];
            //@ts-ignore
            if (row?.orderAction === "Open") {
                const result = await sendSmartWalletOrderV1([
                    {
                        to: currentChain.contracts.trading,
                        data: encodeFunctionData({
                            abi: OstiumTrading__factory.abi,
                            functionName: "openTradeMarketTimeout",
                            args: [
                                //@ts-ignore
                                orderId,
                            ],
                        }),
                    },
                ]);

                notify({
                    title: "Closing Order",
                    description: "Your order is being closed.",
                    data: {
                        order: row as Order,
                    },
                    // @ts-ignore
                    hash: result?.hash,
                    // @ts-ignore
                    url: `${currentChain?.explorer}tx/${result?.hash}`,
                });
                return;
            }
            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "closeTradeMarketTimeout",
                        args: [
                            //@ts-ignore
                            orderId,
                            false,
                        ],
                    }),
                },
            ]);

            notify({
                title: "Closing Order",
                description: "Your order is being closed.",
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
                // @ts-ignore
                hash: result?.hash,
                data: {
                    order: row as Order,
                },
            });
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
            setIsLoading(false);
        }
    };

    const onCloseOrder = useCallback(async () => {
        try {
            // if (isTradingDisabled) {
            //     notify({
            //         title: language.screens.trade.disabled.title,
            //         description: language?.screens.trade.disabled.description,
            //     });
            //     return;
            // }
            setIsLoading(true);

            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "cancelOpenLimitOrder",
                        args: [
                            //@ts-ignore
                            row.pair.id,
                            //@ts-ignore
                            row.id.split("_")[2],
                        ],
                    }),
                },
            ]);

            if (result && result?.isError) {
                notify({
                    //@ts-ignore
                    title: result?.title,
                    //@ts-ignore
                    description: result?.description,
                });

                setIsLoading(false);
                return;
            }
            setClosed(true);
            console.warn(row);
            notify({
                title: "Cancelled Limit Order",
                data: {
                    //@ts-ignore
                    limit: {
                        ...row,
                        stopLossPrice: undefined,
                        takeProfitPrice: undefined,
                    },
                },
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
                // @ts-ignore
                hash: result?.hash,
            });
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            setIsLoading(false);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error?.code}\n${error?.message}`,
            });
        }
    }, [
        row,
        notify,
        currentChain?.explorer,

        sendSmartWalletOrderV1,
        currentChain.contracts.trading,
        setClosed,
    ]);

    return (
        <tr>
            <td style={cellStyle}>
                <PairItem
                    pair={row?.pair}
                    leverage={row?.leverage}
                    isBuy={row?.isBuy}
                />
            </td>
            <td style={cellStyle}>
                <Skeleton isLoading={size === 0}>
                    {`${formatPrice(size, {
                        significant: 6,
                    })}`}
                </Skeleton>
            </td>
            <td style={cellStyle}>
                <Skeleton isLoading={collateral === 0}>
                    {formatPrice(collateral, {
                        decimals: 2,
                        currency: false,
                    })}{" "}
                    USDC
                </Skeleton>
            </td>
            <td style={cellStyle}>
                <Skeleton isLoading={openPrice === 0}>
                    {formatPrice(openPrice, {
                        significant: 6,
                        //   currency: true,
                    })}
                </Skeleton>
            </td>
            <td style={cellStyle}>
                <Skeleton isLoading={Number(priceImpactRaw) === 0}>
                    {Number(formatUnits(BigInt(priceImpactRaw), 18)) || "-"}
                </Skeleton>
            </td>
            <td style={cellStyle}>
                <span style={{ color: "red" }}>
                    <ClosePrice
                        key={`${row.id}-close-price`}
                        isFirst={false}
                        isLast={true}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        column={{
                            text: "Close Price",
                            value: "CLOSE_PRICE",
                            hasSort: false,
                            hasHide: true,
                            style: {
                                maxWidth: 120,
                            },
                        }}
                    />
                </span>
            </td>
            <td style={cellStyle}>
                <Button
                    text={
                        isLoading
                            ? "Cancelling"
                            : closed
                            ? "Cancelled"
                            : "Cancel"
                    }
                    loading={isLoading}
                    disabled={closed || isLoading}
                    onPress={
                        //@ts-ignore
                        row?.orderType === PositionOrderTypes.Market
                            ? onCloseMarket
                            : onCloseOrder
                    }
                    stroke
                    tiny
                    noBorder
                />
            </td>
        </tr>
    );
};

const TradeCard = ({
    row,
    blockNumber,
    prices,
    refetchTrades,
    orderIds,
}: {
    row: Trade;
    blockNumber: bigint | undefined;
    prices: Map<`${string}-${string}`, Price> | undefined;
    refetchTrades: () => void;
    orderIds: string[];
}) => {
    const { isMobile } = useMedia();
    const [isMarketClosedModalVisible, setIsMarketClosedModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const marketPrice = prices?.get(getPairKey(row.pair));
    const { sendSmartWalletOrderV1 } = usePrivySmartWalletContext();
    const {
        stopLossPrice,
        takeProfitPrice,
        openPrice,
        collateral,
        tradeNotional,
    } = formatData({
        stopLossPrice: row?.stopLossPrice,
        takeProfitPrice: row?.takeProfitPrice,
        openPrice: row?.openPrice,
        collateral: row?.collateral,
        tradeNotional: row?.tradeNotional,
    });

    const {
        pnl,
        netPNL,
        netValue,
        pnlPercent,
        funding,
        rollover,
        liquidationPrice,
        priceImpact,
    } = getTradePNL(row, marketPrice, blockNumber);
    const { isTradingDisabled, changePair } = useTradeContext();
    const { notify } = useNotificationContext();
    const {
        settings: { language },
        isOneClickTradingEnabled,
    } = useAppContext();
    const { currentChain, tradingContract } = useWeb3Context();
    const [closed, setClosed] = useState(false);

    const onCloseTrade = useCallback(async () => {
        if (marketPrice?.isMarketOpen == false) {
            //setIsMarketClosedModalVisible(true);
            return;
        }
        try {
            if (isTradingDisabled) {
                // notify({
                //     title: language.screens.trade.disabled.title,
                //     description: language?.screens.trade.disabled.description,
                // });
                return;
            }
            setIsLoading(true);

            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "closeTradeMarket",
                        args: [
                            //@ts-ignore
                            row.pair.id,
                            //@ts-ignore
                            row.index,
                            //@ts-ignore
                            100 * 100,
                        ],
                    }),
                },
            ]);

            if (result && result?.isError) {
                notify({
                    title: result.title,
                    description: result.description,
                });

                setIsLoading(false);
                return;
            }
            if (refetchTrades) {
                refetchTrades();
            }

            setClosed(true);
            setIsLoading(false);

            notify({
                title: "Closing Trade",
                description: "Your trade is being closed.",
                type: "Trade",
                data: {
                    trade: {
                        ...row,
                        stopLossPrice: undefined,
                        takeProfitPrice: undefined,
                    },
                },
                // @ts-ignore
                hash: result?.hash,
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
            });
        } catch (err) {
            console.warn(err);
            setIsLoading(false);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
        }
    }, [
        marketPrice,
        isTradingDisabled,
        setClosed,
        row,
        notify,
        currentChain?.explorer,
        refetchTrades,
        sendSmartWalletOrderV1,
        currentChain.contracts.trading,
    ]);

    return (
        <Flex
            direction="column"
            style={{
                padding: 16,
                gap: 12,
                backgroundColor: "#252525",
                border: " 0.5px solid rgba(208, 219, 218, 0.10)",
                borderRadius: 6,
            }}
        >
            <Flex justify="space-between">
                <PairItem
                    pair={row?.pair}
                    leverage={row?.leverage}
                    isBuy={row?.isBuy}
                />
                <Flex direction="column">
                    <Text smallest color="rgba(208, 219, 218, 0.40)">
                        Unrealized PNL
                    </Text>
                    <span style={{ color: "red", paddingTop: 4 }}>
                        <PositionPNL
                            isBuy={row?.isBuy}
                            pnl={pnl}
                            netPNL={netPNL}
                            percent={pnlPercent}
                            collateral={collateral || 0}
                            funding={funding}
                            rollover={rollover}
                            isLoading={
                                !marketPrice?.mid ||
                                !marketPrice?.bid ||
                                !marketPrice?.ask
                            }
                            style={{
                                paddingRight: 0,
                            }}
                        />
                    </span>
                </Flex>
            </Flex>
            <Flex direction="column">
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Size
                    </Text>
                    <Text sml color="#D0DBDA">
                        {`${formatPrice(tradeNotional, {
                            significant: 6,
                        })}`}
                    </Text>
                </Flex>
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Net Value
                    </Text>
                    <Text sml color="#D0DBDA">
                        {formatPrice(netValue, {
                            decimals: 2,
                            currency: true,
                        })}
                    </Text>
                </Flex>
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Entry Price
                    </Text>
                    <Text sml color="#D0DBDA">
                        {formatPrice(openPrice, {
                            significant: 6,
                            //   currency: true,
                        })}
                    </Text>
                </Flex>
            </Flex>
            <Flex
                style={
                    {
                        // backgroundColor: "#1B1B1B",
                        // borderRadius: 6,
                        // paddingTop: 12,
                        // paddingBottom: 12,
                        // paddingLeft: 16,
                        // paddingRight: 16,
                    }
                }
            >
                {orderIds.includes(row.id) ? null : (
                    <Button
                        big
                        text={
                            marketPrice?.isMarketOpen == false
                                ? "Market Closed"
                                : isLoading
                                ? "Closing"
                                : closed
                                ? "Closed"
                                : "Close"
                        }
                        loading={isLoading}
                        disabled={
                            closed || isLoading || !marketPrice?.isMarketOpen
                        }
                        onPress={onCloseTrade}
                        stroke
                        tiny
                        noBorder
                        style={{ width: "100%" }}
                    />
                )}
            </Flex>
        </Flex>
    );
};

const OrderCard = ({
    row,
    blockNumber,
    prices,
    refetchTrades,
}: {
    row: Limit | Order;
    blockNumber: bigint | undefined;
    prices: Map<`${string}-${string}`, Price> | undefined;
    refetchTrades: () => void;
}) => {
    const { isMobile } = useMedia();
    const [isMarketClosedModalVisible, setIsMarketClosedModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const marketPrice = prices?.get(getPairKey(row.pair));
    const { sendSmartWalletOrderV1 } = usePrivySmartWalletContext();
    const {
        stopLossPrice,
        takeProfitPrice,
        collateral,
        notional,
        openPrice,
        leverage,
    } = formatData({
        //@ts-ignore
        stopLossPrice: row.stopLossPrice,
        //@ts-ignore
        takeProfitPrice: row.takeProfitPrice,
        //@ts-ignore
        openPrice: row.openPrice,
        notional: row.notional,
        collateral: row.collateral,
        leverage: row.leverage,
        from: row?.pair?.from,
        to: row?.pair?.to,
    });
    const { isTradingDisabled, changePair } = useTradeContext();
    const { notify } = useNotificationContext();
    const {
        settings: { language },
        isOneClickTradingEnabled,
    } = useAppContext();
    const { currentChain, tradingContract } = useWeb3Context();
    const [closed, setClosed] = useState(false);

    const stopLoss = stopLossPrice || 0;
    const takeProfit = takeProfitPrice || 0;
    const size =
        notional && openPrice
            ? row.pair.from === "USD"
                ? notional * openPrice
                : notional / openPrice
            : 0;
    const priceImpactRaw = marketPrice
        ? getPriceImpactParsed(marketPrice, row.isBuy, true).priceAfterImpact
        : "0";

    const onCloseOrder = useCallback(async () => {
        try {
            // if (isTradingDisabled) {
            //     notify({
            //         title: language.screens.trade.disabled.title,
            //         description: language?.screens.trade.disabled.description,
            //     });
            //     return;
            // }
            setIsLoading(true);

            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "cancelOpenLimitOrder",
                        args: [
                            //@ts-ignore
                            row.pair.id,
                            //@ts-ignore
                            row.id.split("_")[2],
                        ],
                    }),
                },
            ]);

            if (result && result?.isError) {
                notify({
                    //@ts-ignore
                    title: result?.title,
                    //@ts-ignore
                    description: result?.description,
                });

                setIsLoading(false);
                return;
            }
            setClosed(true);
            console.warn(row);
            notify({
                title: "Cancelled Limit Order",
                data: {
                    //@ts-ignore
                    limit: {
                        ...row,
                        stopLossPrice: undefined,
                        takeProfitPrice: undefined,
                    },
                },
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
                // @ts-ignore
                hash: result?.hash,
            });
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            setIsLoading(false);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error?.code}\n${error?.message}`,
            });
        }
    }, [
        row,
        notify,
        currentChain?.explorer,

        sendSmartWalletOrderV1,
        currentChain.contracts.trading,
        setClosed,
    ]);

    const onCloseMarket = async () => {
        try {
            // if (isTradingDisabled) {
            //     notify({
            //         title: language.screens.trade.disabled.title,
            //         description: language?.screens.trade.disabled.description,
            //     });
            //     return;
            // }
            setIsLoading(true);
            const orderId = row?.id.split("_")[0];
            //@ts-ignore
            if (row?.orderAction === "Open") {
                const result = await sendSmartWalletOrderV1([
                    {
                        to: currentChain.contracts.trading,
                        data: encodeFunctionData({
                            abi: OstiumTrading__factory.abi,
                            functionName: "openTradeMarketTimeout",
                            args: [
                                //@ts-ignore
                                orderId,
                            ],
                        }),
                    },
                ]);

                notify({
                    title: "Closing Order",
                    description: "Your order is being closed.",
                    data: {
                        // @ts-ignore
                        order: row as Order,
                    },
                    // @ts-ignore
                    hash: result?.hash,
                    // @ts-ignore
                    url: `${currentChain?.explorer}tx/${result?.hash}`,
                });
                return;
            }
            const result = await sendSmartWalletOrderV1([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "closeTradeMarketTimeout",
                        args: [
                            //@ts-ignore
                            orderId,
                            false,
                        ],
                    }),
                },
            ]);

            notify({
                title: "Closing Order",
                description: "Your order is being closed.",
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
                // @ts-ignore
                hash: result?.hash,
                data: {
                    // @ts-ignore
                    order: row as Order,
                },
            });
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
            setIsLoading(false);
        }
    };

    return (
        <Flex
            direction="column"
            style={{
                padding: 16,
                gap: 12,
                backgroundColor: "#252525",
                border: " 0.5px solid rgba(208, 219, 218, 0.10)",
                borderRadius: 6,
            }}
        >
            <Flex justify="space-between">
                <PairItem
                    pair={row?.pair}
                    leverage={row?.leverage}
                    isBuy={row?.isBuy}
                />
                <Flex direction="column">
                    <Text smallest color="rgba(208, 219, 218, 0.40)">
                        Close Price
                    </Text>
                    <span style={{ color: "red", paddingTop: 4 }}>
                        <ClosePrice
                            key={`${row.id}-close-price`}
                            isFirst={false}
                            isLast={true}
                            stopLoss={stopLoss}
                            takeProfit={takeProfit}
                            column={{
                                text: "Close Price",
                                value: "CLOSE_PRICE",
                                hasSort: false,
                                hasHide: true,
                                style: {
                                    maxWidth: 120,
                                },
                            }}
                        />
                    </span>
                </Flex>
            </Flex>
            <Flex direction="column">
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Size
                    </Text>
                    <Text sml color="#D0DBDA">
                        {`${formatPrice(notional, {
                            significant: 6,
                        })}`}
                    </Text>
                </Flex>
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Collateral
                    </Text>
                    <Text sml color="#D0DBDA">
                        {formatPrice(collateral, {
                            decimals: 2,
                            currency: true,
                        })}
                    </Text>
                </Flex>
                <Flex
                    style={{
                        borderBottom: "0.5px solid rgba(208, 219, 218, 0.10)",
                        paddingBottom: 12,
                        paddingTop: 12,
                    }}
                    justify="space-between"
                >
                    <Text sml color="rgba(208, 219, 218, 0.70)">
                        Entry Price
                    </Text>
                    <Text sml color="#D0DBDA">
                        {formatPrice(openPrice, {
                            significant: 6,
                            //   currency: true,
                        })}
                    </Text>
                </Flex>
            </Flex>
            <Flex
                style={
                    {
                        // backgroundColor: "#1B1B1B",
                        // borderRadius: 6,
                        // paddingTop: 12,
                        // paddingBottom: 12,
                        // paddingLeft: 16,
                        // paddingRight: 16,
                    }
                }
            >
                <Button
                    big
                    text={
                        isLoading
                            ? "Cancelling"
                            : closed
                            ? "Cancelled"
                            : "Cancel"
                    }
                    loading={isLoading}
                    disabled={closed || isLoading}
                    onPress={
                        //@ts-ignore
                        row?.orderType === PositionOrderTypes.Market
                            ? onCloseMarket
                            : onCloseOrder
                    }
                    stroke
                    tiny
                    noBorder
                    style={{ width: "100%" }}
                />
            </Flex>
        </Flex>
    );
};

export default OffBoardModal;

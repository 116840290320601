import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { wagmiConfig } from "../wagmiConfig";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { watchAccount } from "@wagmi/core";
import { UserFe } from "gql/graphql";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";

export const REFERRAL_CODE = "REFERRAL_CODE_V2";

export function useUser({ address }: { address?: `0x${string}` }) {
    const { getKey, saveKey, deleteKey } = useLocalStorage();
    const [userFE, setUserFE] = useState<UserFe | null>(null);
    const [userFELoading, setUserFELoading] = useState<boolean>(true);
    const [showGetStarted, setShowGetStarted] = useState(false);
    const [registerUser] = useMutation(REGISTER_USER);
    const { refetch } = useQuery(GET_USER_FE, {
        skip: true,
    });

    const { query } = useRouter();
    const { ref } = query;
    const { logoutSmartAccount } = usePrivySmartWalletContext();
    const { user } = usePrivy();

    const addReferral = useCallback(
        async (address: any, accessCode: string | undefined) => {
            try {
                const storedReferralCode = accessCode
                    ? accessCode
                    : await getKey(REFERRAL_CODE);
                if (storedReferralCode) {
                    const params = new URLSearchParams({
                        address: address.toLowerCase(),
                        referralCode: storedReferralCode,
                    });

                    const result = await fetch(`/api/user/add-referral`, {
                        method: "POST",
                        body: params,
                    }).then((res) => res.json());

                    if (result.error.response.errors) {
                        console.warn(
                            "Referral Error",
                            result.error.response.errors[0].message
                        );
                        return;
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        },
        [getKey]
    );

    const validateAccessCode = useCallback(
        async (address: any, accessCode: string) => {
            try {
                const params = new URLSearchParams({
                    accessCode,
                });
                const result = await fetch(`/api/user/validate-access-code`, {
                    method: "POST",
                    body: params,
                }).then((res) => res.json());

                if (result.error?.response.errors) {
                    console.warn(
                        "Validate Acccess Code Error",
                        result.error.response.errors[0].message
                    );
                    return result;
                }
                if (result?.isAccessCodeValid === true) {
                    await addReferral(address, accessCode);
                    saveKey(REFERRAL_CODE, accessCode.toUpperCase());
                    refetch();
                }
                return result;
            } catch (err) {
                console.warn(err);
            }
        },
        [saveKey, addReferral, refetch]
    );

    useEffect(() => {
        if (ref && address) {
            console.warn("Setting referral code locally", ref);
            validateAccessCode(address, ref as string);
        }
    }, [ref, address, validateAccessCode]);

    const refetchUser = useCallback(async () => {
        try {
            const { data } = await refetch({
                address: address?.toLowerCase(),
            });

            setUserFE(data.userFE);
            setUserFELoading(false);
            if (!data.userFE?.ftueDone) setShowGetStarted(true);
        } catch (err) {
            console.warn(err);
        }
    }, [address, refetch]);

    const toggleGetStarted = useCallback(() => {
        setShowGetStarted((prev) => !prev);
    }, []);

    const register = useCallback(
        async (address: `0x${string}`, accessCode: string | undefined) => {
            try {
                const { data } = await registerUser({
                    variables: {
                        address: address?.toLowerCase(),
                    },
                });

                setUserFE(data?.registerUserFE);
                setUserFELoading(false);
                if (!data?.registerUserFE?.ftueDone) setShowGetStarted(true);

                console.warn(userFE);
                // const result = await addReferral(address, accessCode);
                // console.warn(result);
                // console.warn("Referral code added");
                // await deleteKey(REFERRAL_CODE);
                // console.warn("Referral code deleted");
                return;
            } catch (err) {
                console.warn(err);
            }
        },
        [registerUser, userFE]
    );

    const previousAddress = useRef(address);

    useEffect(() => {
        const unwatch = watchAccount(wagmiConfig, {
            async onChange(account) {
                console.log("Account changed!", account);
                try {
                    if (account?.isConnected) {
                        if (account?.address) {
                            if (
                                user &&
                                (user.google?.email || user?.email?.address)
                            ) {
                                await register(
                                    user?.smartWallet?.address as `0x${string}`,
                                    undefined
                                );
                                return;
                            }

                            if (
                                previousAddress.current &&
                                previousAddress.current !== account?.address &&
                                userFE?.id
                            )
                                setUserFE(null);

                            previousAddress.current = account?.address;

                            await register(account?.address, undefined);
                        }
                    }

                    if (
                        account?.isDisconnected &&
                        account?.status === "disconnected"
                    ) {
                        if (
                            user &&
                            (user.google?.email || user?.email?.address)
                        ) {
                            return;
                        }

                        window?.localStorage?.setItem(
                            "isOneClickTradingEnabled",
                            "false"
                        );
                        setUserFE(null);
                        if (user) {
                            logoutSmartAccount();
                        }
                    }
                } catch (err) {
                    console.warn(err);
                }
            },
        });

        return () => {
            unwatch();
        };
    }, [logoutSmartAccount, register, user, userFE?.id]);

    return {
        showGetStarted,
        refetchUser,
        registerUser,
        toggleGetStarted,
        setUserFE,
        validateAccessCode,
        userFE,
        userFELoading,
        addReferral,
        register,
    };
}

const REGISTER_USER = gql`
    mutation registerUserFE($address: String!) {
        registerUserFE(address: $address) {
            id
            address
            ftueDone
            username
            referralCode
            signedMessageTimestamp
            accessCode
        }
    }
`;

const GET_USER_FE = gql`
    query getUserFE($address: String!) {
        userFE(where: { address: $address }) {
            id
            address
            username
            ftueDone
            referralCode
            signedMessageTimestamp
            accessCode
        }
    }
`;

const IS_ACCESS_CODE_VALID = gql`
    query isAccessCodeValid($accessCode: String!) {
        isAccessCodeValid(accessCode: $accessCode)
    }
`;

export const RawColors = {
    white: "#D0DBDA",
    black: "#070606",
    primary: "#FF5A19",
    background: "#111111",
    red: "#BC244A",
    yellow: "#FFC107",
    green: "#1A965D",
    vividGreen: "#00EE80",
    // greyOld: "#d0dbda",
    grey: {
        50: "#E7E4E4",
        100: "#DCD6D6",
        200: "#C7BDBD",
        300: "#AFA2A2",
        400: "#978686",
        500: "#7E6C6C",
        600: "#665757",
        700: "#4A4040",
        800: "#2F2828",
        900: "#1A1616",
        950: "#070606",
    },
    orange: {
        50: "#FFF4F0",
        100: "#FFE9E0",
        200: "#FFD3C2",
        300: "#FFB699",
        400: "#FF9166",
        500: "#FF5A19",
        600: "#F04400",
        700: "#D13B00",
        800: "#AD3100",
        900: "#802400",
        950: "#5C1A00",
    },
    sand: "#493a34",
    darkSand: "#372A27",
    darkBrown: "#20110D",
    greyish: "#D0DBDAB2",
    greyNew: "#D0DBDA",
    darkGrey: "#333333",
};


export function normalizeColors(colors: Record<string, any>) {
    const result: Record<string, any> = {};

    for (const key of Object.keys(colors)) {
        const value = colors[key];

        // If it's a normal string, just copy
        if (typeof value === "string") {
            result[key] = value;
        }
        // If it's an object with a "500" property => add .toString() that returns [500]
        else if (value && typeof value === "object" && value["500"]) {
            // Make a shallow copy
            const variants = { ...value };

            // Define the default string conversion
            Object.defineProperty(variants, "toString", {
                enumerable: false,
                value: function () {
                    return variants["500"];
                },
            });

            // Optionally handle Symbol.toPrimitive
            Object.defineProperty(variants, Symbol.toPrimitive, {
                enumerable: false,
                value: function () {
                    return variants["500"];
                },
            });

            result[key] = variants;
        }
    }

    return result;
}

export const Colors = normalizeColors(RawColors);

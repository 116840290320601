import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";
import { LpShare, Referral, type User } from "gql/graphql";
import { useMemo } from "react";
import { formatUnits } from "viem";

export function useReferrals(
    address?: `0x${string}`,
    smartAccountAddress?: `0x${string}`
) {
    const { data, loading: isLoading } = useQuery(USER_REFERRALS, {
        variables: {
            addresses: [
                address?.toLowerCase(),
                smartAccountAddress?.toLowerCase(),
            ],
        },
        fetchPolicy: "network-only",
        skip: !address,
    });

    const referralAddresses = useMemo(() => {
        return (
            data?.referrals?.map((item: Referral) =>
                item.referral.toLowerCase()
            ) ?? []
        );
    }, [data?.referrals]);

    const { data: referredUsers, loading: isLoadingReferredUsers } = useQuery(
        REFERRED_USERS,
        {
            variables: {
                addresses: referralAddresses,
            },
            fetchPolicy: "network-only",
            // Ensuring that REFERRED_USERS query runs only when referralAddresses is not empty
            skip: referralAddresses.length === 0,
            pollInterval: 5000,
        }
    );

    const { data: userSharesData } = useQuery(GET_USER_LP_SHARES, {
        variables: {
            addresses: referralAddresses,
        },
        pollInterval: 5000,
        fetchPolicy: "network-only",
        // Ensuring that REFERRED_USERS query runs only when referralAddresses is not empty
        skip: referralAddresses.length === 0,
    });

    const lpShare: LpShare | undefined = useMemo(
        () => userSharesData?.users?.[0] || undefined,
        [userSharesData?.users]
    );

    const totalReferrals = useMemo(() => {
        return data?.aggregateReferral?._count?.address ?? 0;
    }, [data?.aggregateReferral?._count?.address]);

    const totalReferralDeposits = useMemo(() => {
        if (userSharesData?.lpShares) {
            return userSharesData.lpShares.reduce(
                (acc: number, item: LpShare) =>
                    acc +
                    (item.lockedAssets
                        ? Number(formatUnits(item.lockedAssets, 6))
                        : 0),
                0
            );
        }
        return 0;
    }, [userSharesData?.lpShares]);

    const totalReferralVolume = useMemo(() => {
        if (referredUsers?.users) {
            return referredUsers.users.reduce(
                (acc: number, user: User) =>
                    acc +
                    (user.totalVolume
                        ? Number(formatUnits(user.totalVolume, 6))
                        : 0),
                0
            );
        }
        return 0;
    }, [referredUsers]);

    return {
        lpShare,
        totalReferrals,
        totalReferralDeposits,
        totalReferralVolume,
        referredUsers,
        isLoading,
        isLoadingReferredUsers,
    };
}

const USER_REFERRALS = gql`
    query UserReferrals($addresses: [String!]) {
        referrals(where: { address: { in: $addresses } }) {
            id
            address
            referral
            createdAt
        }
        aggregateReferral(where: { address: { in: $addresses } }) {
            _count {
                address
            }
        }
    }
`;

const REFERRED_USERS = gql`
    query ReferredUsers($addresses: [Bytes!]) @api(name: subgraph) {
        users(where: { id_in: $addresses }) {
            totalVolume
            id
        }
    }
`;

const GET_USER_LP_SHARES = gql`
    query GetUserLpShares($addresses: [Bytes!]) @api(name: subgraph) {
        lpShares(where: { id_in: $addresses }) {
            user
            shares
            assets
            lockedAssets
            lockedShares
        }
    }
`;

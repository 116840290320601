import { useState, useEffect } from "react";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import {
    usePointsBySeasonId,
    useCurrentSeason,
    usePointsByUserId,
    useCurrentTradingStandings,
    useCurrentLPStandings,
} from "./usePoints";
import { StandingsService } from "../../../services/PointsService";
import type { UserPointsRanking } from "../types/points";
import type {
    UserTradingStandingsResponse,
    UserLPStandingsResponse,
} from "../types/standings";

/**
 * Custom hook to get the user's trading data directly from API
 * @returns User trading data, loading state, and error state
 */
export const useUserTradingReferralData = () => {
    // State for new API data
    const [userTradingData, setUserTradingData] =
        useState<UserTradingStandingsResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { address } = useOstiumAccount();

    // For backward compatibility
    const { standings: tradingStandings, loading: oldLoading } =
        useCurrentTradingStandings();

    useEffect(() => {
        let isMounted = true;

        const fetchUserTradingData = async () => {
            if (!address) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const data =
                    await StandingsService.getUserTradingStandings<UserTradingStandingsResponse>(
                        address.toLowerCase()
                    );

                if (isMounted) {
                    setUserTradingData(data);
                }
            } catch (err) {
                console.error("Error fetching user trading data:", err);
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchUserTradingData();

        return () => {
            isMounted = false;
        };
    }, [address]);

    const rankNumber = userTradingData?.score?.rank || 0;

    return {
        userTradingData: {
            score: userTradingData?.score?.value,
            ...userTradingData,
        },
        userTradingRank: rankNumber,
        rankFormatted: rankNumber > 0 ? `#${rankNumber}` : "",
        loading: loading || oldLoading,
        error,
        totalTradingVolume:
            tradingStandings?.metadata?.totalVolumeWithReferrals || "0",
    };
};

/**
 * Custom hook to get the user's LP data directly from API
 * @returns User LP data, loading state, and error state
 */
export const useUserLpData = () => {
    // State for new API data
    const [userLpData, setUserLpData] =
        useState<UserLPStandingsResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { address } = useOstiumAccount();

    // For backward compatibility
    const { standings: lpStandings, loading: oldLoading } =
        useCurrentLPStandings();

    useEffect(() => {
        let isMounted = true;

        const fetchUserLpData = async () => {
            if (!address) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const data =
                    await StandingsService.getUserLPStandings<UserLPStandingsResponse>(
                        address.toLowerCase()
                    );

                if (isMounted) {
                    setUserLpData(data);
                }
            } catch (err) {
                console.error("Error fetching user LP data:", err);
                if (isMounted) {
                    setError(
                        err instanceof Error ? err : new Error(String(err))
                    );
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchUserLpData();

        return () => {
            isMounted = false;
        };
    }, [address]);

    const rankNumber = userLpData?.score?.rank || 0;

    return {
        userLpData: userLpData
            ? {
                  user: userLpData.userAddress,
                  shares: userLpData.details?.currentShares,
                  actionsCount: userLpData.details?.actionsCount,
                  ...userLpData,
                  score: userLpData.score?.value,
              }
            : null,
        userLpRank: rankNumber,
        loading: loading || oldLoading,
        error,
        totalLpScore:
            Number(lpStandings?.metadata?.totalScore) *
            Number(lpStandings?.metadata?.paidShare),
    };
};

/**
 * Custom hook to get the user's points data from the current season's rankings
 * @returns User points data, loading state, and error state
 */
export const useUserPointsData = () => {
    const [userPointsData, setUserPointsData] =
        useState<UserPointsRanking | null>(null);
    const [userPointsRank, setUserPointsRank] = useState(-1);
    const { address } = useOstiumAccount();
    const { season, loading: seasonLoading } = useCurrentSeason();
    const {
        points: pointsRankings,
        loading: pointsLoading,
        error,
    } = usePointsBySeasonId(season?._id || "");

    const { points: totalPointsUser, loading: isTotalPointsLoading } =
        usePointsByUserId(address?.toLowerCase() || "");

    const loading = seasonLoading || pointsLoading;

    useEffect(() => {
        if (!loading && pointsRankings?.rankings && address) {
            const lowerCaseAddress = address.toLowerCase();

            // Find user's points data
            const userData = pointsRankings.rankings.find(
                (ranking) => ranking.userId === lowerCaseAddress
            );
            setUserPointsData(userData || null);

            // Set user's rank if found
            if (userData) {
                setUserPointsRank(userData.rank - 1);
            }
        }
    }, [pointsRankings, address, loading]);

    return {
        userPointsData,
        userPointsRank,
        loading,
        error,
        totalParticipants: pointsRankings?.metadata?.totalParticipants,
        seasonId: pointsRankings?.seasonId,
        lastUpdated: pointsRankings?.metadata?.lastUpdated,
        totalPointsUser,
    };
};

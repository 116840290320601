import {
    CurrentTradeProfitP,
    GetStopLossPrice,
    GetTakeProfitPrice,
} from "@ostium/formulae";
import { formatUnits, parseUnits } from "viem";

export const parseWeb3Error = (error: {
    code: string | number;
    message: string;
    data: { code: string; message: string };
    info: { error: { message: string } };
}) => {
    try {
        if (error?.code === 4902) {
            return {
                code: "Window open",
                message: "Wallet provider is already open in another window. ",
            };
        }
        let code = error.toString().split("code=")[1];
        code = code.split(",")[0];
        return prettyError(code, error);
    } catch (e) {
        console.warn(e);
        return {
            code: "UNKNOWN_ERROR",
            message: "An unknown error occurred",
        };
    }
};

export function prettyError(code: string, error: any) {
    switch (code) {
        case "ACTION_REJECTED": {
            return {
                code: "ACTION_REJECTED",
                message: "Transaction rejected",
            };
        }
        case "REJECTED_TRANSACTION": {
            return {
                code: "REJECTED_TRANSACTION",
                message: "Transaction rejected",
            };
        }
        case "CALL_EXCEPTION": {
            return {
                code: "CALL_EXCEPTION",
                message: "Call exception",
            };
        }
        case "4902": {
            return {
                code: "USER_DENIED_TRANSACTION",
                message: "User denied transaction",
            };
        }

        default:
            return {
                code: "UNKNOWN_ERROR",
                message: error,
            };
    }
}

export type Price = {
    id: string;
    from: string;
    to: string;
    mid: number;
    bid: number;
    ask: number;
    isMarketOpen: boolean;
    timestampSeconds: number;
};

export function formatData({
    leverage,
    tradeNotional,
    notional,
    collateral,
    stopLossPrice,
    takeProfitPrice,
    from,
    openPrice,

    // Formulas
    getProfitPercent,
    getTakeProfitPrice,
    getStopLossPrice,
}: {
    collateral?: string;
    leverage?: string;
    notional?: string;
    tradeNotional?: string;
    stopLossPrice?: string;
    takeProfitPrice?: string;
    from?: string;
    to?: string;
    openPrice?: string;

    // Formulas
    getProfitPercent?: {
        openPrice: string;
        currentPrice: string;
        isBuy: boolean;
        leverage: string;
        highestLeverage: string;
    };
    getStopLossPrice?: {
        openPrice: string;
        profitP: string;
        leverage: string;
        isBuy: boolean;
    };
    getTakeProfitPrice?: {
        openPrice: string;
        profitP: string;
        leverage: string;
        isBuy: boolean;
    };
}) {
    let data: {
        leverage?: number;
        stopLossPrice?: number;
        takeProfitPrice?: number;
        tradeNotional?: number;
        openPrice?: number;
        collateral?: number;
        notional?: number;

        getTakeProfitPrice?: number;
        getStopLossPrice?: number;
        getProfitPercent?: number;
    } = {};

    if (collateral)
        data.collateral = Number(formatUnits(BigInt(collateral), 6).toString());
    if (leverage)
        data.leverage = Number(formatUnits(BigInt(leverage), 2).toString());
    if (notional)
        data.notional = Number(formatUnits(BigInt(notional), 6).toString());
    if (tradeNotional)
            data.tradeNotional = Number(
                formatUnits(BigInt(tradeNotional), 18).toString()
            );
    if (stopLossPrice)
        data.stopLossPrice = Number(
            formatUnits(BigInt(stopLossPrice), 18).toString()
        );
    if (takeProfitPrice)
        data.takeProfitPrice = Number(
            formatUnits(BigInt(takeProfitPrice), 18).toString()
        );
    if (openPrice)
        data.openPrice = Number(formatUnits(BigInt(openPrice), 18).toString());

    // Formulas
    if (getProfitPercent)
        data.getProfitPercent = Number(
            formatUnits(
                BigInt(
                    CurrentTradeProfitP(
                        getProfitPercent.openPrice,
                        getProfitPercent.currentPrice,
                        getProfitPercent.isBuy,
                        getProfitPercent.leverage,
                        getProfitPercent.highestLeverage
                    )
                ),
                6
            )
        );

    if (getTakeProfitPrice)
        data.getTakeProfitPrice = Number(
            parseUnits(
                GetTakeProfitPrice(
                    getTakeProfitPrice.openPrice,
                    getTakeProfitPrice.profitP,
                    getTakeProfitPrice.leverage,
                    getTakeProfitPrice.isBuy
                ),
                6
            )
        );

    if (getStopLossPrice)
        data.getStopLossPrice = Number(
            parseUnits(
                GetStopLossPrice(
                    getStopLossPrice.openPrice,
                    getStopLossPrice.profitP,
                    getStopLossPrice.leverage,
                    getStopLossPrice.isBuy
                ),
                6
            )
        );

    return data;
}
